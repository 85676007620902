define("adt-wss-common/classes/state-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.thError = _exports.stateIsNotInTransitionsList = _exports.currentStateErrorMessage = _exports.transitionsErrorMessage = void 0;
  const transitionsErrorMessage = 'transitions is non an object';
  _exports.transitionsErrorMessage = transitionsErrorMessage;
  const currentStateErrorMessage = 'currentState is not a string';
  _exports.currentStateErrorMessage = currentStateErrorMessage;
  const stateIsNotInTransitionsList = state => "".concat(state, " state is not defined in transitions list");
  _exports.stateIsNotInTransitionsList = stateIsNotInTransitionsList;
  const thError = message => {
    throw message;
  };

  /**
   * Finite State Machine for Components and Routes
   * -------
   *
   * <a target="_blank" href="https://www.smashingmagazine.com/2018/01/rise-state-machines">Finite State Machine description</a>
   * @class
   * @name Classes/StateMachine
   * @param {Object} args - parameters of FSM
   * @param {Object} args.transitions Each key must be a valid state. Each value is an object in which the key is an available action, and the value the new state.
   * @param {Object} args.transitions.{stateName} - List of transitions
   * @param {String} args.transitions.{stateName}.{transitionName} - Key - Availible action (function) of current  `context` (scope); Value - Next state.
   * @param {Object} args.context Component scope, i.e. `this`
   *
   * @prop {Boolean} scrollToTop Whether the page should scroll on state change; default = false
   * @example
   * export default Component.extend({
   * 	currentState: 'Idle',
   * 	init() {
   * 		const fsm = new StateMachine({
   * 			transitions: {
   * 				idle: {
   * 					fetchUsers: 'fetchingUsers'
   * 				},
   * 				fetchingUsers: {
   * 					resolve: 'usersDone',
   * 					reject: 'idle'
   * 				},
   * 				usersDone: null
   * 			},
   * 			context: this
   * 		});
   * 		this.set('fsm', fsm);
   * 		fsm.dispath('fetchUsers', { catId: 12 });
   * 	},
   * 	fetchUsers(){
   * 		return this.get('usersService').getUsers()
   * 			.then((data) => this.get('fsm').dispatch('resolve', data))
   * 			.catch((results) => this.get('fsm').dispatch('reject', results);
   * 	},
   * 	resolve() {
   * 		this.set('statusMessages', [new StatusMessage('Success!', 'success')]);
   * 	},
   * 	reject() {
   * 		this.set('statusMessages', [new StatusMessage('Error!', 'error')]);
   * 	}
   * });
   */
  _exports.thError = thError;
  class StateMachine extends Ember.Object {
    constructor(...args) {
      super(...args);
      this.scrollToTop = false;
      this.context = null;
      this.transitions = null;
      this.callStack = [];
    }
    init() {
      super.init(...arguments);
      if (Ember.getOwner && typeof Ember.getOwner.lookup === 'function') {
        Ember.set(this, 'appLogger', Ember.getOwner(this.context).lookup('service:app-logger'));
      }
      const {
        transitions
      } = this;
      if (!transitions || typeof transitions !== 'object') {
        console.error(transitionsErrorMessage, this.context._namespace);
        return;
      }
      const state = this.context.currentState;
      if (!state || typeof state !== 'string') {
        console.error(currentStateErrorMessage, this.context._namespace);
      }
      this.callStack.push({
        state,
        action: '_INIT_'
      });
    }

    /**
     * Action dispatcher.
     * Will only allow actions that are explicitly permitted in current state,
     * then transitions to new state before emitting action.
     * @memberof Classes/StateMachine
     * @param {String} action - Name of transtion
     * @param {*} [data] - passed data to action
     * @param {Boolean} [scroll] - should scroll to top of screen (mainly for mobile)
     */
    dispatch(action, data, scroll) {
      try {
        if (!this) {
          throw new Error('You are lost context when called "dispatch"');
        }
        const {
          context,
          transitions,
          appLogger,
          callStack
        } = this;
        if (context.isDestroyed) {
          console.warn(["Element \"".concat(context._namespace, "\" was destroyed for some reason."), 'Probably some previous action changed a property in a service which started a rerender of', "\"".concat(context._namespace, "\" component where current action \"").concat(action, "\" was called berore rerender'")].join(' '));
          return Ember.RSVP.Promise.resolve();
        }
        const state = context.get('currentState');
        const initiator = context.get('_namespace');
        const scrollToTop = typeof scroll === 'undefined' ? this.scrollToTop : scroll;
        if (appLogger && typeof appLogger.logFSM === 'function') {
          appLogger.logFSM({
            action,
            state,
            transitions,
            data,
            initiator,
            callStack
          });
        }
        if (scroll && scroll !== true) {
          /* eslint-disable-next-line max-len */
          console.error('If passing more than one value to the action, values should be in a data object, not as arguments');
          console.error(...arguments);
          /* eslint-disable-next-line max-len */
          thError('If you didn\'t pass multiple values as arguments, then you passed `scroll` as something other than a boolean.');
        }
        if (!transitions || typeof transitions !== 'object') {
          thError(transitionsErrorMessage);
        }
        if (!state || typeof state !== 'string') {
          thError(currentStateErrorMessage);
        }
        const trasnsitionList = transitions[state];
        if (!trasnsitionList) {
          thError(stateIsNotInTransitionsList(state));
        }
        const prevAction = callStack[callStack.length - 1].action;
        callStack.push({
          state,
          action,
          data,
          initiator
        });
        const transtionState = trasnsitionList[action];
        if (transtionState) {
          context.set('currentState', transtionState);
        } else {
          console.error("\nInitiator: ".concat(initiator, "\nFSM: Cannot set \"currentState\" and if you see this message then your FSM model probably is wrong:\n\tOption 1: Your \"").concat(context._namespace, "/constants.js\" file does not have needed STATE (now it is { [").concat(action, "]: UKNOWN_STATE })\n\tOption 2: Your previous action transit you to wrong state (now it is { [").concat(prevAction, "]: ").concat(state, " })\n\tOption 3: Your current state ").concat(state, " does not have ").concat(action), '\n\nFSM Model', transitions, "\n\n[".concat(state, "]:"), trasnsitionList);
          if (appLogger && appLogger.drawFSMRoute) appLogger.drawFSMRoute(callStack, true, true);
        }
        if (scrollToTop) {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }
        if (typeof context[action] === 'function') {
          if (!transtionState) {
            console.error("Action \"".concat(action, "\" cannot be called from \"").concat(state, "\" state"));
            return Ember.RSVP.Promise.resolve();
          }
          return context[action](data);
        }
        return Ember.RSVP.Promise.resolve({
          msg: 'action not implemented'
        });
      } catch (error) {
        return console.error(error);
      }
    }
  }
  var _default = StateMachine;
  _exports.default = _default;
});