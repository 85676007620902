define("adt-wss-common/helpers/format-phone", ["exports", "adt-wss-common/utils/myadt-helpers"], function (_exports, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatPhone = formatPhone;
  _exports.default = void 0;
  /**
   * Formats phone number
   * @name formatPhone
   * @memberof TemplateHelpers
   * @param {String[]} params first item: phone number, second item: separator (def: '-')
   * @return {String}
   * @example {{format-phone '9171579465' '-'}} -> '917-157-9465'
   */
  function formatPhone(params) {
    if (!params || !params.length) return false;
    const number = params[0];
    const sep = params[1] || '-';
    return _myadtHelpers.default.formatPhone(number, sep);
  }
  var _default = Ember.Helper.helper(formatPhone);
  _exports.default = _default;
});