define("adt-wss-common/components/widget/input-wrapper", ["exports", "adt-wss-common/templates/components/widget/input-wrapper"], function (_exports, _inputWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * HTML wrapper for field inputs
   * @module Widget/InputWrapper
   * @prop {String} label
   * @prop {String} inputVal mutuable property from parent; will be altered directly
   * @prop {String} inputId value for `id` attribute; sets `label[for]` also
   * @prop {String} [inputAutocomplete] sets `autocomplete` attribute on input. Default: 'on'\
   * @prop {String} [inputType] Default: 'text'
   * @prop {String} [inputShowPassword] sets CSS for 'Show/Hide' link in password field
   * @prop {String} [inputClass]
   * @prop {Boolean} [inputDisabled]
   * @prop {Boolean} [required]
   * @prop {String} [selenium] sets `selenium-id` attribute, defaults to `inputClass` if present
   * @prop {Function} [onEnter] action to run on enter press
   * @prop {Function} [onFocusOut] action to run on blur/focusOut
   * @prop {Function} [onFocusIn] action to run on focus/focusIn
   * @prop {Boolean} [validatePassword] adds password-validator
   * @prop {Boolean} [validationErrorState] mutable value from parent, passed to `setErrorState` in password-validator
   * @prop {Boolean} [pwOnlyOnFocus] sets password-validator to `onlyOnFocus`
   * @prop {Boolean} [hideMobileLabel] hides internal label on mobile layout
   * @prop {Boolean} [labelFocus] sets 'focused' class on label at all times
   * @prop {Boolean} showPassword shows password/confirm password fields
   */
  var _default = Ember.Component.extend({
    layout: _inputWrapper.default,
    classNameBindings: ['hasLabel:has-label', 'error', 'inputShowPassword:show-password', 'blueBorderLeft:blue-border-left', 'inputDisabled:disabled'],
    hasLabel: Ember.computed('label', function () {
      return !!this.get('label');
    }),
    inputDisabled: false,
    inputAutocomplete: 'on',
    inputType: 'text',
    validatePassword: false,
    pwOnlyOnFocus: false,
    errorMessages: null,
    showPassword: false,
    keyUp(e) {
      if (e.keyCode === 13 && typeof this.onEnter === 'function') this.onEnter();
    },
    actions: {
      toggleShowPassword() {
        this.toggleProperty('showPassword');
      }
    },
    init() {
      this._super(...arguments);
      const classNames = ['input-wrapper'];
      const className = this.get('inputClass');
      if (this.get('inputType') === 'text') {
        this.set('showPassword', true);
      }
      if (className) {
        classNames.push("".concat(className, "-wrapper"));
        if (!this.get('selenium')) {
          this.set('selenium', className);
        }
      }
      this.classNames = classNames;
    }
  });
  _exports.default = _default;
});