define("adt-wss-common/utils/password-validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module Utils/PasswordValidator
   */
  const PasswordValidator = {
    /**
     * Validates passed password
     * @param {String} password
     * @memberof module:Utils/PasswordValidator
     * @return {Boolean}
     */
    validate(password) {
      const vals = [password.length >= 8,
      // min length
      password.length <= 20,
      // max length
      password.match(/[\d]/),
      // contains numbers
      password.replace(/[^A-Z]/gi, '').length >= 4,
      // contains at least 4 letters
      password.replace(/[A-Z0-9]/gi, '').length === 0 // alphanumeric
      ];
      for (let i = 0; i < vals.length; i++) {
        if (!vals[i]) return false;
      }
      return true;
    }
  };
  var _default = PasswordValidator;
  _exports.default = _default;
});