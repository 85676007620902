define('ember-cli-hot-loader/components/hot-replacement-component', ['exports', 'ember-cli-hot-loader/mixins/hot-component', 'ember-get-config', 'ember-cli-hot-loader/utils/clear-container-cache', 'ember-cli-hot-loader/utils/clear-requirejs'], function (exports, _hotComponent, _emberGetConfig, _clearContainerCache, _clearRequirejs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TemplateCacheCheckTimeout = exports.TemplatesCache = exports.TEMPLATE_CACHE_GC_TIMEOUT = exports.TEMPLATE_CACHE_MAX_SIZE = undefined;
  exports.hashString = hashString;
  exports.checkTemplatesCacheLimit = checkTemplatesCacheLimit;
  exports.matchesPodConvention = matchesPodConvention;
  exports.matchesClassicConvention = matchesClassicConvention;
  exports.matchingComponent = matchingComponent;


  function regexEscape(s) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // eslint-disable-line
  }

  const TEMPLATE_CACHE_MAX_SIZE = exports.TEMPLATE_CACHE_MAX_SIZE = 10000;
  const TEMPLATE_CACHE_GC_TIMEOUT = exports.TEMPLATE_CACHE_GC_TIMEOUT = 1000;

  var TemplatesCache = exports.TemplatesCache = {};
  var TemplateCacheCheckTimeout = exports.TemplateCacheCheckTimeout = null;
  // this is fast-hashing function for string, taken from
  // https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
  function hashString(str) {

    let hash = 0;
    if (str.length == 0) {
      return hash;
    }
    for (let i = 0; i < str.length; i++) {
      let char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return String(hash);
  }

  // Object keys may be so expensive on large objects (on 10k, ~ 6ms), so it's kinda debounce logic
  function checkTemplatesCacheLimit(timeout = TEMPLATE_CACHE_GC_TIMEOUT) {
    // allow only 10k component templates in cache
    clearTimeout(TemplateCacheCheckTimeout);
    exports.TemplateCacheCheckTimeout = TemplateCacheCheckTimeout = setTimeout(() => {
      if (Object.keys(TemplatesCache).length > TEMPLATE_CACHE_MAX_SIZE) {
        exports.TemplatesCache = TemplatesCache = {};
      }
    }, timeout);
  }

  function matchesPodConvention(componentName, modulePath) {
    var basePath = 'components/' + componentName;
    var componentRegexp = new RegExp(regexEscape(basePath + '/component.js') + '$');
    if (componentRegexp.test(modulePath)) {
      return true;
    }

    var templateRegex = new RegExp(regexEscape(basePath + '/template.hbs') + '$');
    if (templateRegex.test(modulePath)) {
      return true;
    }

    return false;
  }

  function matchesClassicConvention(componentName, modulePath) {
    var componentRegexp = new RegExp(regexEscape('components/' + componentName + '.js') + '$');
    if (componentRegexp.test(modulePath)) {
      return true;
    }

    var templateRegexp = new RegExp(regexEscape('templates/components/' + componentName + '.hbs') + '$');
    if (templateRegexp.test(modulePath)) {
      return true;
    }

    return false;
  }

  function matchingComponent(componentName, modulePath) {
    if (!componentName) {
      return false;
    }
    var standardModulePath = modulePath.split('\\').join('/');
    var javascriptPath = standardModulePath.replace(/\.ts$/, '.js');
    return matchesClassicConvention(componentName, javascriptPath) || matchesPodConvention(componentName, javascriptPath);
  }

  function getPositionalParamsArray(constructor) {
    const positionalParams = constructor.positionalParams;
    return typeof positionalParams === 'string' ? [positionalParams] : positionalParams;
  }

  const HotReplacementComponent = Ember.Component.extend(_hotComponent.default, {
    init() {
      const configuration = _emberGetConfig.default['ember-cli-hot-loader'];
      const tagless = configuration && configuration['tagless'];
      const tagName = tagless ? '' : 'div';
      this.set('tagName', tagName);
      return this._super();
    },
    parsedName: null,
    layout: Ember.computed(function () {
      let positionalParams = getPositionalParamsArray(this.constructor).join('');
      let attrs = this.attrs || {};
      const attributesMap = Object.keys(attrs).filter(key => positionalParams.indexOf(key) === -1).map(key => `${key}=${key}`).join(' ');
      const templateLayout = `
      {{#if hasBlock}}
        {{#if (hasBlock "inverse")}}
          {{#component wrappedComponentName ${positionalParams} ${attributesMap} target=target as |a b c d e f g h i j k|}}
            {{yield a b c d e f g h i j k}}
          {{else}}
            {{yield to="inverse"}}
          {{/component}}
        {{else}}
          {{#component wrappedComponentName ${positionalParams} ${attributesMap} target=target as |a b c d e f g h i j k|}}
            {{yield a b c d e f g h i j k}}
          {{/component}}
        {{/if}}
      {{else}}
        {{component wrappedComponentName ${positionalParams} ${attributesMap} target=target}}
      {{/if}}
    `;
      const templateHash = hashString(templateLayout);
      if (!TemplatesCache[templateHash]) {
        TemplatesCache[templateHash] = Ember.HTMLBars.compile(templateLayout);
      }
      checkTemplatesCacheLimit();
      return TemplatesCache[templateHash];
    }).volatile(),

    __willLiveReload(event) {
      const baseComponentName = this.get('baseComponentName');
      if (matchingComponent(baseComponentName, event.modulePath)) {
        event.cancel = true;
        (0, _clearRequirejs.default)(this, baseComponentName);
      }
    },
    __isAlive() {
      return !this.isDestroyed && !this.isDestroying;
    },
    __rerenderOnTemplateUpdate(modulePath) {
      const baseComponentName = this.get('baseComponentName');
      const wrappedComponentName = this.get('wrappedComponentName');
      if (matchingComponent(baseComponentName, modulePath)) {
        this._super(...arguments);
        (0, _clearContainerCache.default)(this, baseComponentName);
        (0, _clearContainerCache.default)(this, wrappedComponentName);
        this.setProperties({
          wrappedComponentName: undefined,
          baseComponentName: undefined
        });
        this.rerender();
        Ember.run.later(() => {
          if (!this.__isAlive()) {
            return;
          }
          this.setProperties({
            wrappedComponentName: wrappedComponentName,
            baseComponentName: baseComponentName
          });
        });
      }
    }
  });

  HotReplacementComponent.reopenClass({
    createClass(OriginalComponentClass, parsedName) {
      const NewComponentClass = HotReplacementComponent.extend({
        baseComponentName: parsedName.fullNameWithoutType,
        wrappedComponentName: parsedName.fullNameWithoutType + '-original'
      });
      NewComponentClass.reopenClass({
        positionalParams: OriginalComponentClass.positionalParams ? OriginalComponentClass.positionalParams.slice() : []
      });
      return NewComponentClass;
    }
  });
  exports.default = HotReplacementComponent;
});