define("adt-wss-common/classes/ajax-response", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Standard AJAX response object
   * @class
   * @name Classes/AjaxResponse
   * @param {String|Object} xhr
   * @param {Boolean} success
   *
   * @prop {Boolean} success
   * @prop {String|String[]} messages
   * @prop {String|String[]} errors
   * @prop {*} data
   */
  class AjaxResponse {
    complete(output, success) {
      if (output && output.hasOwnProperty('data') && output.hasOwnProperty('success')) {
        Object.keys(output).forEach(key => {
          this[key] = output[key];
        });
      } else if (output && (output.errors && output.errors.length || output.errorMessage && output.errorMessage.length)) {
        this.success = false;
        this.errors = output.errors || output.errorMessage;
        this.messages = output.messages || null;
        this.data = null;
      } else {
        this.success = success;
        this.errors = output && output.errors ? output.errors : output && output.errorMessage ? output.errorMessage : null;
        this.messages = output && output.messages ? output.messages : null;
        this.data = output;
      }
      return this;
    }
    constructor(xhr, status, success) {
      this.success = false;
      this.errors = null;
      this.messages = null;
      this.data = null;
      if (arguments.length > 3) {
        throw 'Too many arguments for AjaxResponse';
      }
      if (arguments.length < 3) {
        throw 'Not enough arguments for AjaxResponse';
      }
      if (!xhr) {
        this.messages = ['The response was empty.'];
        if (status === 204) {
          this.success = true;
          return this;
        }
        this.errors = ['Generic.error'];
        return this;
      }
      if (status === 404) {
        this.messages = ['The response was empty.'];
        this.errors = ['The resource was not found (404)'];
        return this;
      }
      if (typeof xhr === 'string') {
        try {
          xhr = JSON.parse(xhr);
        } catch (e) {
          console.error('JSON.parse error', e);
        }
      }
      return this.complete(xhr, success);
    }
  }
  var _default = AjaxResponse;
  _exports.default = _default;
});