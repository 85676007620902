define("ember-power-calendar/components/power-calendar/nav", ["exports", "@ember-decorators/component", "ember-power-calendar/templates/components/power-calendar/nav"], function (_exports, _component, _nav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class2, _temp;

  let _class = (_dec = (0, _component.layout)(_nav.default), _dec2 = (0, _component.tagName)(''), _dec(_class2 = _dec2(_class2 = (_temp = class _class2 extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.unit = 'month';
      this.format = 'MMMM YYYY';
    }

  }, _temp)) || _class2) || _class2);

  _exports.default = _class;
});