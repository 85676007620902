define("adt-wss-common/helpers/number-ordinal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.numberOrdinal = numberOrdinal;
  _exports.default = void 0;
  /**
   * Formats number text
   * @name numberOrdinal
   * @memberof TemplateHelpers
   * @param {Int[]} params
   * @return {String}
   * @example
   * {{number-ordinal '435'}} -> '435th'
   * {{number-ordinal '2'}} -> '2nd'
   */
  function numberOrdinal(params) {
    let number;
    try {
      number = parseInt(params[0], 10);
    } catch (e) {
      console.error(e);
      return params[0];
    }
    if (!Number.isNaN(number)) {
      const num = number.toString();
      let ord = 'th';
      if (num.slice(-1) === '1') ord = 'st';else if (num.slice(-1) === '2') ord = 'nd';else if (num.slice(-1) === '3') ord = 'rd';
      return num + ord;
    }
    return params[0];
  }
  var _default = Ember.Helper.helper(numberOrdinal);
  _exports.default = _default;
});