define("adt-wss-common/components/widget/radio-button", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/utils/ember-helpers", "adt-wss-common/templates/components/widget/radio-button"], function (_exports, _withFsm, _emberHelpers, _radioButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProperties = {
    onChange: () => 1
  };
  var _default = Ember.Component.extend(_withFsm.default, defaultProperties, {
    layout: _radioButton.default,
    selected: null,
    classNames: ['radio-button'],
    checked: (0, _emberHelpers.eq)('selected', 'value'),
    actions: {
      onChange(value) {
        this.get('onChange')(value);
        this.set('selected', value);
      }
    }
  });
  _exports.default = _default;
});