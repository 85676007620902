define("adt-wss-common/classes/validator", ["exports", "adt-wss-common/utils/myadt-helpers"], function (_exports, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
  * Validator for data
  * -------
  *
  * @class
  * @name Classes/Validator
  * @param {Object} config - Configuration object
  * @param {String} config.{fieldName}.label - Each field should have a label
  * @param {String[]} config.{fieldName}.rules - List of validation rules
  * @param {String[]} config.{fieldName}.staticInstruction - static instruction for property
  * @param {Object} options - Options
  * @param {Fuction} options.messageCustomizer - Method which handle message
  * @param {Object} options.types - Types of validation
  * @param {Function} options.types.validate - Validate handler for type
  * @param {Function|String} options.types.instruction - Instruction for field
  *
  * @example
  * const validator = new Validator({
  	*   firstName: { label: 'First name', rules: ['isNotEmpty'] },
  	*   secondName: { label: 'Second Name', rules: ['lengthOfNSymbols?4'] },
  	*   age: { label: 'Age', rules: ['isNumber'] },
  	* }, {
  		*   messageCustomizer: (value, label) => `${label}: ${value}`,
  		* });
  		*/

  class Validator {
    constructor(config = {}, options = {}) {
      const {
        messageCustomizer = v => v,
        types = {}
      } = options;
      this.onValidateHandler = v => v;
      this.messages = {};
      this.config = config;
      this.messageCustomizer = messageCustomizer;
      this.types = {
        ...Validator.types,
        ...types
      };
    }

    /**
    * Handle request of type validation
    * Split type params and type name
    * @memberof Classes/Validator
    *
    * @param {String} value - Name and parameters of request
    * @return {Object}
    */
    handleTypeRequest(value) {
      const [typeName, params = ''] = value.split('?');
      const typeParams = params.split(':');
      return {
        typeName,
        typeParams
      };
    }

    /**
    * Write messages
    * @memberof Classes/Validator
    *
    * @param {String} fieldKey - Name of field
    * @param {String} instruction
    * @param {String} label
    */
    writeError(fieldKey, instruction, label) {
      const message = this.messageCustomizer(instruction, label);
      if (this.messages.hasOwnProperty(fieldKey)) {
        this.messages[fieldKey].push(message);
      } else {
        this.messages[fieldKey] = [message];
      }
    }

    /**
    * Write error messages for array
    * @memberof Classes/Validator
    *
    * @param {String} fieldKey - Name of field
    * @param {String} instruction
    * @param {String} label
    * @param {Object} config
    * @param {Number} config.index - Index of element
    */
    writeArrayErrors(fieldKey, instruction, label, {
      index
    }) {
      const message = this.messageCustomizer(instruction, label);
      this.messages[fieldKey] = this.messages[fieldKey] || [];
      if (this.messages[fieldKey][index]) {
        this.messages[fieldKey][index].push(message);
      } else {
        this.messages[fieldKey][index] = [message];
      }
    }

    /**
    * Register onValidateHandler method
    * @memberof Classes/Validator
    *
    * @param {Function} onValidateHandler - method which called after validation
    */
    onValidate(callback) {
      if (typeof callback === 'function') {
        this.onValidateHandler = callback;
      }
    }

    /**
    * If property is a method invoke it with params
    * @memberof Classes/Validator
    *
    * @param {any} prop
    * @param {Array} params
    */
    convertFunctionTypeProp(prop, params = []) {
      if (typeof prop === 'function') {
        return prop(...params);
      }
      return prop;
    }

    /**
    * Detects existing errors
    * @memberof Classes/Validator
    *
    * @return {Boolean}
    */
    hasErrors() {
      return Object.keys(this.messages).length > 0;
    }

    /**
    * Data validator
    * Validate data according configuration
    * @memberof Classes/Validator
    *
    * @param {Object} data - Key - field name, Value - value of field name
    * @return {Boolean}
    */
    validate(data = {}) {
      const keys = Object.keys(data);
      this.messages = {};
      keys.forEach(key => {
        const config = this.config[key] || {};
        const {
          rules: types = [],
          label,
          staticInstruction,
          reference
        } = config;
        let {
          isArray = false
        } = config;
        types.forEach(tKey => {
          const {
            typeName,
            typeParams
          } = this.handleTypeRequest(tKey);
          const value = data[key];
          const referenceValue = typeof reference === 'string' ? data[reference] : null;
          const checker = this.types[typeName];
          isArray = isArray && Array.isArray(value);
          if (!checker) throw new Error("No handler to validate type ".concat(typeName));
          const instruction = staticInstruction || this.convertFunctionTypeProp(checker.instruction, [typeParams]);
          if (isArray) {
            return value.forEach((v, index) => {
              if (checker.validate(v, typeParams, referenceValue)) {
                return false;
              }
              this.writeArrayErrors(key, instruction, this.convertFunctionTypeProp(label, [index + 1]), {
                index
              });
            });
          }
          if (!checker.validate(value, typeParams, referenceValue)) {
            return this.writeError(key, instruction, label);
          }
        });
      });
      this.onValidateHandler({
        messages: this.messages,
        values: Object.values(this.messages).reduce((acc, current) => acc.concat(...(Array.isArray(current[0]) ? current : [current])), [])
      });
      return this.hasErrors();
    }
  }
  Validator.types = {
    isNotEmpty: {
      validate(value) {
        return value != null && value !== '';
      },
      instruction: 'The value cannot be empty'
    },
    isInteger: {
      validate(value) {
        return Number.isInteger(Number(value));
      },
      instruction: 'The value can only be a valid number, e.g. 1, 3.14 or 2010'
    },
    lengthOfNSymbols: {
      validate(value, [length]) {
        return String(value).length === parseInt(length, 10);
      },
      instruction: ([length]) => "The value should be ".concat(length, " symbols")
    },
    maxLength: {
      validate(value, [length]) {
        return String(value).length <= length;
      },
      instruction: ([length]) => "Field is to allow for ".concat(length, " characters")
    },
    isZipCode: {
      validate(value) {
        const regex = /^\d{5}(?:-?\d{4})?$/;
        return regex.test(value);
      },
      instruction: () => 'Zip code must be either 5 or 9 digits in length'
    },
    noSpecialChar: {
      validate(value) {
        const regex = /[^a-zA-Z0-9]/;
        return !regex.test(value);
      },
      instruction: () => 'The value should not contain spaces or special characters, e.g. #, & or *'
    },
    isEmail: {
      validate: value => String(value).isValidEmail(),
      instruction: () => 'Invalid email'
    },
    isPhoneNumber: {
      validate: value => (0, _myadtHelpers.validatePhoneNumber)(value),
      instruction: () => 'Oops, you entered an invalid number. Your number must have 10 digits, area code can\'t start with a 1 or 0 and can\'t be 800, 866, 877, 888, 900 or 911, prefix can\'t start with a 1 or 0 and can\'t be 555, 7 of the 10 digits can\'t be the same number, and can\'t be in sequential order (1234… etc).'
    },
    isNotEmptyArray: {
      validate: value => value.length > 0,
      instruction: () => 'Array should not be empty'
    },
    minLength: {
      validate(value, [length]) {
        return String(value).length >= length;
      },
      instruction: ([length]) => "Field should not contain less then ".concat(length, " characters")
    }
  };
  var _default = Validator;
  _exports.default = _default;
});