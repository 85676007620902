define("adt-wss-common/templates/components/widget/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8XN493A6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"label\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onChange\",[24,[\"value\"]]],null]],[11,\"for\",[29,[[22,\"idName\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"value\"]],[24,[\"selected\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"input\",true],[11,\"id\",[29,[[22,\"idName\"]]]],[11,\"name\",[29,[[22,\"name\"]]]],[10,\"checked\",\"\"],[11,\"value\",[29,[[22,\"value\"]]]],[10,\"type\",\"radio\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"input\",true],[11,\"id\",[29,[[22,\"idName\"]]]],[11,\"name\",[29,[[22,\"name\"]]]],[11,\"value\",[29,[[22,\"value\"]]]],[10,\"type\",\"radio\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"check\"],[8],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[1,[22,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss-common/templates/components/widget/radio-button.hbs"
    }
  });

  _exports.default = _default;
});