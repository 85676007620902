/* eslint-disable prefer-template */
/* eslint-disable no-extend-native */
// =============================================================
// Date object extensions for MyADT
// =============================================================
/* eslint-disable */
(function() {
/**
 * @class External/Date
 * @extends Date
 */

	/**
	 * `Short and full names of months`
	 * @memberof External/Date
	 * @example
	 * Date.monthMapping.attr[0] // Jan
	 * Date.monthMapping.full[2] // March
	 * @property {String[]} abbr - Short name
	 * @property {String[]} full - Full name
	 */
	Date.monthMapping = {
		abbr: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		full: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
	};

	/**
	 * `Short and full names of days`
	 * @memberof External/Date
	 * @example
	 * Date.dayMapping.attr[0] // Sun
	 * Date.dayMapping.full[2] // Tuesday
	 * @property {String[]} abbr - Short name
	 * @property {String[]} full - Full name
	 */
	Date.dayMapping = {
		abbr: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
		full: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
	};

	/**
	 * `Create a date object from MyADT common date string formats`
	 * @memberof External/Date
	 * @example
	 * Date.createDate('2012/12/21 22:22:00'); //Fri Dec 21 2012 22:22:00 GMT+0200 (FLE Standard Time)
	 * @param {String} dateStr
	 * @return {Date}
	 */

	Date.createDate = function(dateStr) {
		let ndObj;
		if (arguments.length <= 1) {
			const fd = Date.filterDate(dateStr || Date.now());
			ndObj = new Date(fd);
		} else {
			throw 'No overload for Date.createDate accepts more than one argument.';
		}

		return ndObj;
	};

	/**
	 * `Evaluate different date string formats prior to date creation`
	 * > Formats for dateStr:
	 * - timestamp: `1524218750`
	 * - `YYYY-MM-DD`
	 * - `YYYY/MM/DD HH:MM:SS` or `YYYY-MM-DD HH:MM:SS`
	 * - `MM-DD-YYYY` or `MM/DD/YYYY`
	 * - `mmm DD, YYYY` - Apr 12, 2018
	 * - `mmm-DD-YYYY` - Apr-12-2018
	 * @memberof External/Date
	 * @protected
	 * @param {Timestamp|String} dateStr - `Some date value in string format`
	 * @return {Date}
	 */
	Date.filterDate = function(dateStr) {
		let x = dateStr;
		if (typeof dateStr === 'string') {
			// convert UNIX timestamp (seconds) to JS (milliseconds)
			if (/^[\d]{10}$/.test(dateStr)) x = dateStr * 1000;
			// hypens indicate GMT, slashes local
			else if (/^[\d]{4}-[\d]{2}-[\d]{2}$/.test(dateStr)) x = dateStr.replace(/-/g, '/');
			// Consistency between FF and Chrome 'YYYY/MM/DD HH:MM:SS' makes local date obj
			else if (/^[\d]{4}[-/][\d]{2}[-/][\d]{2}[T\s][\d]{2}:[\d]{2}/.test(dateStr)) {
				if (dateStr.substr(-1) !== 'Z') {
					x = dateStr.replace(/T/, ' ').replace(/-/g, '/').substr(0, 19);
				}
			// converts MM-DD-YYYY to YYYY/MM/DD
			} else if (/^[\d]{1,2}[-/][\d]{1,2}[-/][\d]{4}$/.test(dateStr)) {
				dateStr.replace(/-/g, '/');
				const dateSpl = dateStr.split(/[/-]/);
				x = [dateSpl[2], dateSpl[0], dateSpl[1]].join('/');
			// converts mmm-DD-YYYY to YYYY/MM/DD
			} else if (/[A-Za-z]{3}[-/][\d]{1,2}[-/][\d]{4}/.test(dateStr)) {
				const dateSpl = dateStr.split(/[/-]/);
				const monthMap = Date.monthMapping.abbr.indexOf(dateSpl[0].decamelize().capitalize()) + 1;
				x = [dateSpl[2], monthMap, dateSpl[1]].join('/');
			} else if (/[A-Za-z]{3} [0-9]{1,2}, [0-9]{4}/.test(dateStr)) {
				const dateSpl = dateStr.replace(',', '').split(' ');
				const monthMap = Date.monthMapping.abbr.indexOf(dateSpl[0]) + 1;
				x = [dateSpl[2], monthMap, dateSpl[1]].join('/');
			}
			if (typeof x === 'string' && /^[\d]+$/.test(x)) x = parseInt(x, 10);
		} else if (/^[\d]{10}$/.test(dateStr)) {
			x = dateStr * 1000;
		}
		return x;
	};

	// =============================================================
	// Math 'n' Things
	// =============================================================

	/**
	 * @memberof External/Date
	 * @desc `Polyfill`
	 * @example
	 * Date.now(); //1524217792038
	 * @return {Int} timestamp
	 */
	if (!Date.now) {
		Date.now = function() {
			return new Date().getTime();
		};
	}

	/**
	 * @memberof External/Date
	 * @desc `Calculates the time span between two dates. Always >= 0`
	 * @param {Date} a could be a string
	 * @param {Date} [b]=Date.now() could be a string or `Date`; defaults to current date
	 * @return {object}
	 *
	 * @example
	 * Date.span('2015-04-15', '2017-04-15')
	 * // {
	 * // 	days:731
	 * // 	hours:17544
	 * // 	millis:63158400000
	 * // 	minutes:1052640
	 * // 	weeks:104
	 * // 	years:2.0027397260273974,
	 * // 	relative: {
	 * //			days:1
	 * // 		hours:0
	 * // 		millis:62380800000
	 * // 		minutes:0
	 * // 		weeks:51
	 * // 		years:1.9027397260273974
	 * //		}
	 * //	}
	 */
	Date.span = function(a, b) {
		const hour = 1000 * 60 * 60;
		const minute = 1000 * 60;
		const aMs = a instanceof Date ? a.getTime() : Date.createDate(a).getTime();
		const bMs = (b) ? (b instanceof Date ? b.getTime() : Date.createDate(b).getTime()) : Date.now();
		const diffMs = Math.abs(aMs - bMs);

		return {
			millis: diffMs,
			seconds: Math.floor(diffMs / 1000),
			minutes: Math.floor(diffMs / minute),
			hours: Math.floor(diffMs / hour),
			days: Math.floor(diffMs / (hour * 24)),
			weeks: Math.floor(diffMs / (hour * 24 * 7)),
			years: parseFloat(diffMs / (hour * 24 * 365)),
			relative: {
				seconds: Math.floor((diffMs % minute) / 1000),
				minutes: Math.floor((diffMs % hour) / minute),
				hours: Math.floor((diffMs % (hour * 24)) / hour),
				days: Math.floor((diffMs % (hour * 24 * 7)) / (hour * 24)),
				weeks: Math.floor((diffMs % (hour * 24 * 365)) / (hour * 24 * 7)),
				years: parseFloat(diffMs / (hour * 24 * 365))
			}
		};
	};

	/**
	 * @memberof External/Date
	 * @desc `Returns span between today and the passed date value`
	 * @example
	 * new Date().timeSpan('2015-04-15')
	 * // {
	 * // days:1101
	 * // hours:26438
	 * // millis:95176858121
	 * // minutes:1586280
	 * // weeks:157
	 * // years:3.018038372685185
	 * }
	 * @param {Date} dateStr could be a string
	 * @return {Object} time span object from {@link Date.span}
	 */
	Date.prototype.timeSpan = function(dateStr) {
		return Date.span(this, dateStr);
	};

	/**
	 * @memberof External/Date
	 * @desc `returns 'am' or 'pm'`
	 * @example
	 * new Date().getAmPm() // am
	 * new Date().getAmPm(true) PM
	 * @param {Boolean} upperCase self explanatory
	 * @param {Boolean} withHour - add number to ampm
	 * @param {Boolean} cutHour - return 1PM instead 01PM
	 * @return {String}
	 */
	Date.prototype.getAmPm = function(upperCase, withHour, cutHour) {
		let ampm = 'pm';
		const hours = this.getHours();
		if (hours < 12) ampm = 'am';
		if (upperCase) ampm = ampm.toUpperCase();
		this.ampm = ampm;
		return (withHour ? this.getFixedHour(true, cutHour) : '') + ampm;
	};

	/**
	 * returns 'st', 'nd', 'rd', 'th' IF typeof type === 'number'
	 * returns '1st', '2nd', '3rd', '11th' IF type === 'month' || type === 'day'
	 * @memberof External/Date
	 * @example
	 * new Date().getOrdinal(111); // th
	 * new Date().getOrdinal(); // 2nd
	 * new Date().getOrdinal('day'); // 3rd
	 * new Date().getOrdinal('month'); // 1st
	 * @param {Int|String} [type]='date' -  Just number or (day, month)
	 * @return {String}
	 */
	Date.prototype.getOrdinal = function(type) {
		const justNumber = typeof type === 'number';
		let number = justNumber ? type : this.getDate();
		const prefix = justNumber ? '' : number;

		if (type === 'day') number = this.getDay();
		if (type === 'month') number = this.getMonth() + 1;
		// eslint-disable-next-line operator-assignment
		if (number > 20) number = number % 10;
		if (number === 1) return prefix + 'st';
		if (number === 2) return prefix + 'nd';
		if (number === 3) return prefix + 'rd';
		return prefix + 'th';
	};

	/**
	 * @memberof External/Date
	 * @desc `returns 2-digit date`
	 * @example
	 * new Date.createDate('2014/01/01').getFixedDate() //01
	 * new Date.createDate('2014/01/21').getFixedDate() //21
	 * @return {String}
	 */
	Date.prototype.getFixedDate = function() {
		return ('0' + this.getDate()).slice(-2);
	};

	/**
	 * @memberof External/Date
	 * @desc `returns 2-digit month`
	 * @example
	 * new Date.createDate('2014/01/01').getFixedMonth() //01
	 * new Date.createDate('2014/12/21').getFixedMonth() //12
	 * @return {String}
	 */
	Date.prototype.getFixedMonth = function() {
		return ('0' + (this.getMonth() + 1)).slice(-2);
	};

	/**
	 * @memberof External/Date
	 * @desc `returns current hour`
	 * @example
	 * new Date.createDate('2014/01/01 21:30:00').getFixedHour() //21
	 * new Date.createDate('2014/01/01 01:30:00').getFixedHour() //01
	 * new Date.createDate('2014/01/01 21:30:00').getFixedHour(true) //09
	 * new Date.createDate('2014/01/01 21:30:00').getFixedHour(true, true) //9
	 * @param {Boolean} isTwelve 12-hour format
	 * @param {Boolean} trim trim single digit hours (false ads leading 0)
	 * @return {String}
	 */
	Date.prototype.getFixedHour = function(isTwelve, trim) {
		let x = this.getHours();
		if (isTwelve) {
			if (x > 12) x -= 12;
			else if (x === 0) x = 12;
		}
		if (trim) return x.toString();
		return ('0' + x.toString()).slice(-2);
	};

	/**
	 * @memberof External/Date
	 * @desc returns 2-digit minute
	 * @example
	 * new Date.createDate('2014/01/01 21:30:00').getFixedMinute() //30
	 * new Date.createDate('2014/01/01 21:02:00').getFixedMinute() //02
	 * @return {String}
	 */
	Date.prototype.getFixedMinute = function() {
		return ('0' + this.getMinutes()).slice(-2);
	};

	// ============================================================
	// These return new date objects, without affecting the current instance
	// ============================================================

	/**
	 * @memberof External/Date
	 * @desc `returns new date object, without affecting the current instance`
	 * @example
	 * Date.getDateAfterNDays(5) // Wed Apr 25 2018 14:51:48 GMT+0300 (FLE Daylight Time)
	 * Date.getDateAfterNDays(5, '2018-12-12') // Mon Dec 17 2018 00:00:00 GMT+0200 (FLE Standard Time)
	 *
	 * @param {Int} days - number of days, could be negative
	 * @param {Date} [date] could be a string
	 * @return {Date}
	 */
	Date.getDateAfterNDays = function(days, date) {
		const startDate = date ? (date instanceof Date ? date.getTime() : Date.createDate(date).getTime()) : Date.now();
		return new Date(startDate + (1000 * 60 * 60 * 24 * days));
	};

	/**
	 * @memberof External/Date
	 * @desc Date object for tomorrow; always uses current date
	 * @return {Date}
	 */
	Date.prototype.tomorrow = function() {
		return this.dateAfterNDays(1);
	};

	/**
	 * @memberof External/Date
	 * @see Date.getDateAfterNDays
	 * @desc Date object for chosen span; always uses current date; uses {@link Date.getDateAfterNDays}
	 * @param {Int} days number of days, could be negative
	 * @return {Date}
	 */
	Date.prototype.dateAfterNDays = function(days) {
		return Date.getDateAfterNDays(days, this);
	};

	/**
	 * @memberof External/Date
	 * @desc Returns Date object for Sunday of the current week
	 * @param {Boolean} monday returns the object for Monday instead
	 * @return {Date}
	 */
	Date.prototype.getFirstDayOfWeek = function(monday) {
		const dayOfWeek = this.getDay() * 24 * 60 * 60 * 1000;
		const offset = monday ? 24 * 60 * 60 * 1000 : 0;
		const diff = this.getTime() - (dayOfWeek + offset);
		return new Date(diff);
	};

	/** returns new Date object for midnight of the current day */
	Date.prototype.setMidnight = function() {
		const x = new Date(this);
		x.setHours(0);
		x.setMinutes(0);
		x.setSeconds(0);
		x.setMilliseconds(0);

		return x;
	};


	// ============================================================
	// String returns
	// ============================================================

	// STRING PARTS
	// =========================

	/**
	 * @memberof External/Date
	 * @desc Returns month name
	 * @param {String} format 'abbr' or 'full', defaults to 'abbr'
	 * @param {Int} letterCase 0 will convert to all lowercase, 1 will convert to all caps, defaults to title case
	 * @return {String}
	 */
	Date.prototype.getMonthString = function(format, letterCase) {
		const formats = ['abbr', 'full'];
		const f = !!format && formats.indexOf(format.toLowerCase()) !== -1 ? format.toLowerCase() : 'abbr';
		let m = Date.monthMapping[f][this.getMonth()];

		if (letterCase === 1) m = m.toUpperCase();
		else if (letterCase === 0) m = m.toLowerCase();
		return m;
	};

	/**
	 * @memberof External/Date
	 * @desc Returns day name
	 * @param {String} format 'abbr' or 'full', defaults to 'abbr'
	 * @param {Int} letterCase 0 will convert to all lowercase, 1 will convert to all caps, defaults to title case
	 * @return {String} month name
	 */
	Date.prototype.getDayString = function(format, letterCase) {
		const formats = ['abbr', 'full'];
		const f = !!format && formats.indexOf(format.toLowerCase()) !== -1 ? format.toLowerCase() : 'abbr';
		let d = Date.dayMapping[f][this.getDay()];

		if (letterCase === 1) d = d.toUpperCase();
		else if (letterCase === 0) d = d.toLowerCase();
		return d;
	};

	// FULL DATE STRINGS
	// ==========================

	/**
	 * @memberof External/Date
	 * @desc Returns American numerical date string
	 * @param {String} separator any character to separate the MM DD YYYY
	 * @return {String} '12-25-2015'
	 */
	Date.prototype.toUSAString = function(separator) {
		const sep = (separator !== null && separator !== false && separator !== undefined) ? separator : '-';
		const month = this.getFixedMonth();
		const dt = this.getFixedDate();
		return month + sep + dt + sep + this.getFullYear();
	};

	/**
	 * @memberof External/Date
	 * @desc Returns international numerical date string
	 * @param {String} separator any character to separate the YYYY MM DD, _can be empty string_
	 * @return {String}
	 * new Date().toIntlString() => '2015-12-25'
	 * new Date().toIntlString('') => '20151225'
	 */
	Date.prototype.toIntlString = function(separator) {
		const sep = (separator !== null && separator !== false && separator !== undefined) ? separator : '-';
		const month = this.getFixedMonth();
		const dt = this.getFixedDate();
		return [this.getFullYear(), month, dt].join(sep);
	};

	/**
	 * @memberof External/Date
	 * @param {String} format 'abbr' or 'full' will affect the month text, defaults to 'abbr'
	 * @param {Int} letterCase 0 will convert to all lowercase, 1 will convert to all caps, defaults to title case
	 * @param {Boolean} fixedDate if true, will get two-digit fixed date
	 * @return {String} 'Jan 8, 2016' or 'January 8, 2016'
	 */
	Date.prototype.toAltString = function(format, letterCase, fixedDate) {
		const date = fixedDate ? this.getFixedDate() : this.getDate();
		return this.getMonthString(format, letterCase) + ' ' + date + ', ' + this.getFullYear();
	};

	/**
	 * @memberof External/Date
	 * @param {Boolean} isShort - set to 7PM
	 * @param {Boolean} upperCase
	 * @return {String} 08:12 am ot 8AM
	 */
	Date.prototype.toTimeAmPm = function (isShort, upperCase) {
		const HH = this.getFixedHour(true, isShort);
		const MM = this.getFixedMinute();
		const AMPM = upperCase ? this.getAmPm().toUpperCase() : this.getAmPm();

		return (isShort ? [HH, AMPM] : [HH, ':', MM, ' ', AMPM]).join('');
	};

	/**
	 * @memberof External/Date
	 * @desc Same as {@link external:Date#toAltString toAltString}, plus Day
	 * @return {String} 'Fri, Jan 8, 2016' or 'Friday, January 8, 2016'
	 */
	Date.prototype.toAltDayString = function(format, letterCase, dayFormat) {
		return this.getDayString(dayFormat || format, letterCase) + ', ' +
			this.getMonthString(format, letterCase) + ' ' + this.getDate() + ', ' + this.getFullYear();
	};

	/**
	 * @memberof External/Date
	 * @desc ISO string adjusted for client side time zone, without millis
	 * @return {String} '2016-01-08T11:12:34'
	 */
	Date.prototype.toZonedISOString = function() {
		const offset = this.getTimezoneOffset() * 60000;
		const str = new Date(this.getTime() - offset).toISOString();
		return str.slice(0, str.length - 1);
	};

	/**
	 * @arguments start:Date, end:Date
	 * @memberof External/Date
	 * @desc returns number of months between two dates
	 * @return {number} 'x'
	 */
	Date.prototype.monthsBetween = function(startDate, endDate) {
		let x = (endDate.getFullYear() - startDate.getFullYear()) * 12;
		x -= startDate.getMonth() + 1;
		x += endDate.getMonth();
		return x <= 0 ? 0 : x;
	};
}());
