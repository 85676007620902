define('ember-cli-hot-loader/utils/clear-requirejs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.clearRequirejsCache = clearRequirejsCache;

  exports.default = function (component, componentName) {
    const owner = Ember.getOwner(component);
    const config = owner.resolveRegistration('config:environment');
    clearRequirejsCache(config, componentName);
  };

  /**
   * Unsee a requirejs module if it exists
   * @param {String} module The requirejs module name
   */
  function requireUnsee(module) {
    if (window.requirejs.has(module)) {
      window.requirejs.unsee(module);
    }
  }

  /**
   * Clears the requirejs cache for a component, checking for both "classic"
   * style components & "pod" style components
   *
   * @param {Object} config The applicaiton config
   * @param {String} componentName The component name being reloaded
   */
  function clearRequirejsCache(config, componentName) {
    const modulePrefix = Ember.get(config, 'modulePrefix');
    const podModulePrefix = Ember.get(config, 'podModulePrefix') || modulePrefix;

    // Invalidate regular module
    requireUnsee(`${modulePrefix}/components/${componentName}`);
    requireUnsee(`${modulePrefix}/templates/components/${componentName}`);

    // Invalidate pod modules
    requireUnsee(`${podModulePrefix}/components/${componentName}/component`);
    requireUnsee(`${podModulePrefix}/components/${componentName}/template`);
  }

  /**
   * Clears the requirejs cache for a component, checking for both "classic"
   * style components & "pod" style components
   *
   * @param {Object} component The component that's being reloaded
   * @param {String} componentName The component name being reloaded
   */
});