define("adt-wss-common/initializers/ember-responsive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  function initialize(application) {
    application.inject('controller', 'media', 'service:media');
    application.inject('component', 'media', 'service:media');
  }
  var _default = {
    initialize,
    name: 'responsive'
  };
  _exports.default = _default;
});