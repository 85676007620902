define("adt-wss-common/components/loading-spinner", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/templates/components/loading-spinner"], function (_exports, _withFsm, _loadingSpinner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module Components/LoadingSpinner
   * @prop {Boolean} loadingState - shows spinner
   * @prop {String} className - class names space separated - 'active striped'
   * @example
   * {@lang xml}
   * <!-- simple usage -->
   * {{loading-spinner}}
   * <!-- will render next -->
   * <div class="loading"></div>
   * @example
   * {@lang xml}
   * <!-- simple usage with condition-->
   * {{loading-spinner IS_LOADING}}
   * <!-- will render next if IS_LOADING -->
   * <div class="loading"></div>
   * @example
   * {@lang xml}
   * <!-- simple usage with class name -->
   * {{loading-spinner className='my-style-1 my-style-2'}}
   * <!-- will render next -->
   * <div class="my-style-1 my-style-2">
   *   <div class="loading"></div>
   * </div>
   * @example
   * {@lang xml}
   * <!-- block usage -->
   * {{#loading-spinner IS_LOADING className='my-style-1 my-style-2'}}
   *   <div>This contend will be hidden if IS_LOADING === true</div>
   * {{/loading-spinner}}
   * <!-- will render next if IS_LOADING -->
   * <div class="my-style-1 my-style-2">
   *   <div class="loading"></div>
   * </div>
   */
  var _default = Ember.Component.extend(_withFsm.default, {
    layout: _loadingSpinner.default,
    loadingState: true,
    className: '',
    classNameBindings: ['className', 'loadingState:loading-container']
  }).reopenClass({
    positionalParams: ['loadingState']
  });
  _exports.default = _default;
});