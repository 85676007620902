define("adt-wss-common/utils/common-fsm-state", ["exports", "adt-wss-common/utils/route-helpers", "adt-wss-common/utils/fp", "adt-wss-common/classes/status-message", "adt-wss-common/utils/ember-helpers"], function (_exports, _routeHelpers, _fp, _statusMessage, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fsmExtend = _exports.hashSettledDeliverDataAndDispatch = _exports.hashDeliverDataAndDispatch = _exports.deliverDataAndDispatch = _exports.commonDispatcher = _exports.commonMixin = _exports.fsmModel = _exports.UPDATE_DATA_ACTION = _exports.FAILURE_ACTION = _exports.SUCCESS_ACTION = _exports.FETCH_DATA_ACTION = _exports.ERROR_STATE = _exports.UPDATING_STATE = _exports.LOADING_STATE = _exports.IDLE_STATE = void 0;
  const IDLE_STATE = 'IDLE_STATE';
  _exports.IDLE_STATE = IDLE_STATE;
  const LOADING_STATE = 'LOADING_STATE';
  _exports.LOADING_STATE = LOADING_STATE;
  const UPDATING_STATE = 'UPDATING_STATE';
  _exports.UPDATING_STATE = UPDATING_STATE;
  const ERROR_STATE = 'ERROR_STATE';
  _exports.ERROR_STATE = ERROR_STATE;
  const FETCH_DATA_ACTION = 'FETCH_DATA_ACTION';
  _exports.FETCH_DATA_ACTION = FETCH_DATA_ACTION;
  const SUCCESS_ACTION = 'SUCCESS_ACTION';
  _exports.SUCCESS_ACTION = SUCCESS_ACTION;
  const FAILURE_ACTION = 'FAILURE_ACTION';
  _exports.FAILURE_ACTION = FAILURE_ACTION;
  const UPDATE_DATA_ACTION = 'UPDATE_DATA_ACTION';
  _exports.UPDATE_DATA_ACTION = UPDATE_DATA_ACTION;
  const fsmModel = {
    transitions: {
      [IDLE_STATE]: {
        [FETCH_DATA_ACTION]: LOADING_STATE
      },
      [ERROR_STATE]: {
        [FETCH_DATA_ACTION]: LOADING_STATE
      },
      [LOADING_STATE]: {
        [SUCCESS_ACTION]: IDLE_STATE,
        [FAILURE_ACTION]: ERROR_STATE
      }
    }
  };

  /**
   * @namespace CommonFSMState
   * @desc Has common Finite State Machine model and methods to help work with it
   * @example
   * export const IDLE_STATE = 'IDLE_STATE';
   * export const LOADING_STATE = 'LOADING_STATE';
   * export const ERROR_STATE = 'ERROR_STATE';
   *
   * export const FETCH_DATA_ACTION = 'FETCH_DATA_ACTION';
   * export const SUCCESS_ACTION = 'SUCCESS_ACTION';
   * export const FAILURE_ACTION = 'FAILURE_ACTION';
  
   * export const fsmModel = {
  	transitions: {
  		[IDLE_STATE]: {
  			[FETCH_DATA_ACTION]: LOADING_STATE
  		},
  		[ERROR_STATE]: {
  			[FETCH_DATA_ACTION]: LOADING_STATE
  		},
  		[LOADING_STATE]: {
  			[SUCCESS_ACTION]: IDLE_STATE,
  			[FAILURE_ACTION]: ERROR_STATE
  		}
  	}
  };
  
   */
  _exports.fsmModel = fsmModel;
  const commonMixin = {
    fsmModel,
    currentState: IDLE_STATE,
    initAction: FETCH_DATA_ACTION,
    IS_LOADING: (0, _emberHelpers.isState)(LOADING_STATE),
    IS_ERROR: (0, _emberHelpers.isState)(ERROR_STATE),
    showStatusMessage() {
      console.log('Please extend "commonMixin.showStatusMessage" method');
    },
    [FETCH_DATA_ACTION]() {
      console.log('Please extend "commonMixin.FETCH_DATA_ACTION" method');
    },
    [SUCCESS_ACTION]() {
      console.log('Please extend "commonMixin.SUCCESS_ACTION" method');
    },
    [FAILURE_ACTION](errorObj) {
      if (!errorObj) return true;
      const {
        errors
      } = errorObj;
      const e = Array.isArray(errors) ? errors.map(error => new _statusMessage.default(error, 'error')) : [new _statusMessage.default(errors, 'error')];
      this.showStatusMessage(e);
      return true;
    }
  };

  /**
   * dispatch common actions from promise
   * @memberof CommonFSMState
  * @func commonDispatcher
   * @param {Object} scope - context of component
   * @param {Function} callback - should return Promise
   * @param {Function} successAction - action name for success
   * @param {Function} failureAction -  action name for failure
   * @param {Boolean} scrollOnSuccess
   * @param {Boolean} scrollOnFail
   * @returns {Promise}
   * @example
   * {
   *	FETCH_DATA_ACTION(){
   * 		const accountData = this.get('accountData');
   *		return commonDispatcher(this, accountData => accountData.getContracts());
   * 	},
   *	SUCCESS_ACTION(contracts){
   *		this.set('contracts', contracts);
   *  },
   * 	FAILURE_ACTION(error) {
   *		console.error(error);
   *	}
   * }
   */
  _exports.commonMixin = commonMixin;
  const commonDispatcher = (scope, callback, successAction, failureAction, scrollOnSuccess, scrollOnFail) => callback.call(scope).then((0, _routeHelpers.fsmDispatch)(successAction || SUCCESS_ACTION, scrollOnSuccess)(scope)).catch((0, _routeHelpers.fsmDispatch)(failureAction || FAILURE_ACTION, scrollOnFail)(scope));

  /**
   * Delivery data and dispatch common actions
   * @memberof CommonFSMState
   * @func deliverDataAndDispatch
   * @param {Object} scope - context of component
   * @param {String} pathForData - path for data located in service
   * @param {String} successAction
   * @param {String} failureAction
   * @param {Boolean} scrollOnSuccess
   * @param {Boolean} scrollOnFail
   * @returns {Promise}
   * @example
   * {
   *	FETCH_DATA_ACTION(){
   *		return deliverDataAndDispatch(this, 'accountData.contracts', accountData => accountData.getContracts());
   * 	},
   *	SUCCESS_ACTION(contracts){
   *		this.set('contracts', contracts);
   *  },
   * 	FAILURE_ACTION(error) {
   *		console.error(error);
   *	}
   * }
   */
  _exports.commonDispatcher = commonDispatcher;
  const deliverDataAndDispatch = (scope, pathForData, callbackForRequest, successAction, failureAction, scrollOnSuccess, scrollOnFail, options) => commonDispatcher(scope, () => scope.deliverData(pathForData, callbackForRequest, options), successAction, failureAction, scrollOnSuccess, scrollOnFail);

  /**
   * Delivery data using hash and dispatch common actions
   * @memberof CommonFSMState
   * @func hashDeliverDataAndDispatch
   * @param {Object} scope - context of component
   * @param {Object} model - list of deliverData interface
   * @param {String} successAction
   * @param {String} failureAction
   * @param {Boolean} scrollOnSuccess
   * @param {Boolean} scrollOnFail
   * @returns {Promise}
   * @example
   * {
   *	FETCH_DATA_ACTION(){
   *		const model = { contracts: ['accountData.contracts', accountData => accountData.getContracts()] };
   *		return hashDeliverDataAndDispatch(this, model);
   * 	},
   *	SUCCESS_ACTION({ contracts } ){
   *		this.set('contracts', contracts);
   *  },
   * 	FAILURE_ACTION(error) {
   *		console.error(error);
   *	}
   * }
   */
  /* eslint-disable-next-line max-len */
  _exports.deliverDataAndDispatch = deliverDataAndDispatch;
  const hashDeliverDataAndDispatch = (scope, model, successAction, failureAction, scrollOnSuccess, scrollOnFail) => commonDispatcher(scope, () => scope.hashDeliverData(model), successAction, failureAction, scrollOnSuccess, scrollOnFail);

  /**
   * Delivery data using hashSettled and dispatch common actions
   * @memberof CommonFSMState
   * @func hashSettledDeliverDataAndDispatch
   * @param {Object} scope - context of component
   * @param {Object} model - list of deliverData interface
   * @param {Boolean} isResolvedOnly - say to use resolved data only
   * @param {String} successAction
   * @param {String} failureAction
   * @param {Boolean} scrollOnSuccess
   * @param {Boolean} scrollOnFail
   * @returns {Promise}
   * @example
   * {
   *	FETCH_DATA_ACTION(){
   *		const model = {
   *			contracts: ['accountData.contracts', accountData => accountData.getContracts()]
   *			failedPromise: ['accountData.fail', accountData => accountData.fail()]
   *		};
   *		return hashSettledDeliverDataAndDispatch(this, model);
   * 	},
   *	SUCCESS_ACTION({ resolved: { contracts }, rejected: { failedPromise } } ){
   *		this.set('contracts', contracts);
   *  },
   * 	FAILURE_ACTION(error) {
   *		console.error(error);
   *	}
   * }
   * @example
   * {
   *	FETCH_DATA_ACTION(){
   *		const model = {
   *			contracts: ['accountData.contracts', accountData => accountData.getContracts()]
   *			failedPromise: ['accountData.fail', accountData => accountData.fail()]
   *		};
   *		return hashSettledDeliverDataAndDispatch(this, model, true);
   * 	},
   *	SUCCESS_ACTION({ contracts }){
   *		this.set('contracts', contracts);
   *  },
   * 	FAILURE_ACTION(error) {
   *		console.error(error);
   *	}
   * }
   */
  /* eslint-disable-next-line max-len */
  _exports.hashDeliverDataAndDispatch = hashDeliverDataAndDispatch;
  const hashSettledDeliverDataAndDispatch = (scope, model, isResolvedOnly, successAction, failureAction, scrollOnSuccess, scrollOnFail) => commonDispatcher(scope, () => scope.hashSettledDeliverData(model, isResolvedOnly), successAction, failureAction, scrollOnSuccess, scrollOnFail);

  /**
   * Extend common fsmModel
   * @memberof CommonFSMState
   * @func fsmExtend
   * @param {String} state - name of state with need to extend for example (IDLE_STATE)
   * @param {Object} transition - action name and destination state
   * @return {Object} - extended fsmModel
   * @example
  const commonFsmModel = {
  	transitions: {
  		[IDLE_STATE]: {
  			[FETCH_DATA_ACTION]: LOADING_STATE
  		},
  		[ERROR_STATE]: {
  			[FETCH_DATA_ACTION]: LOADING_STATE
  		},
  		[LOADING_STATE]: {
  			[SUCCESS_ACTION]: IDLE_STATE,
  			[FAILURE_ACTION]: ERROR_STATE
  		}
  	}
  };
  
  const extendedCommonFsmModel = fsmExtend(IDLE_STATE, {
  	[OPEN_DOCUMENT]: LOADING_STATE
  });
  
  //the same
  const extendedCommonFsmModel = {
  	transitions: {
  		[IDLE_STATE]: {
  			[FETCH_DATA_ACTION]: LOADING_STATE,
  			[OPEN_DOCUMENT]: LOADING_STATE
  		},
  		[ERROR_STATE]: {
  			[FETCH_DATA_ACTION]: LOADING_STATE
  		},
  		[LOADING_STATE]: {
  			[SUCCESS_ACTION]: IDLE_STATE,
  			[FAILURE_ACTION]: ERROR_STATE
  		}
  	}
  }
   */
  _exports.hashSettledDeliverDataAndDispatch = hashSettledDeliverDataAndDispatch;
  const fsmExtend = (state, transition = {}) => {
    const extendedState = fsmModel.transitions[state];
    const newState = (0, _fp.omix)(extendedState || {}, transition);
    const transitions = (0, _fp.omix)(fsmModel.transitions, {
      [state]: newState
    });
    return {
      transitions
    };
  };
  _exports.fsmExtend = fsmExtend;
});