define("adt-wss-common/helpers/parse-int", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseIntHelper = parseIntHelper;
  _exports.default = void 0;
  /**
   * Parse value to integer
   * @name parse-int
   * @memberof TemplateHelpers
   * @param {any[]} params [`string`, `number`]
   * @return {Number}
   * @example {{parse-int '10'}} -> 10
   */
  function parseIntHelper(param = 0) {
    if (/[^0-9.]/.test(param)) return param;
    return parseInt(param, 10);
  }
  var _default = Ember.Helper.helper(parseIntHelper);
  _exports.default = _default;
});