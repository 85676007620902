define("adt-wss-common/services/app-dispatcher", ["exports", "adt-wss-common/classes/status-message"], function (_exports, _statusMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SET_SWEEPSTAKES_MODAL_DRAWING_TIME = _exports.HIDE_EASYPAY_PROMO_ALERT = _exports.HIDE_CONTRACT_RENEWAL_ALERT = _exports.SHOW_TIMEOUT_MODAL_WINDOW = _exports.PIC_INVALIDATED_EVENT = _exports.PIC_VALIDATED_EVENT = _exports.PIC_SUBMITTED_EVENT = _exports.CRE_COMPLETE_VALIDATION_EVENT = _exports.CRE_COMPLETE_PAYMENT_EVENT = _exports.CRE_ERROR_EVENT = _exports.CRE_VALIDATION_ERROR_EVENT = _exports.EASYPAY_UPDATE_EVENT = _exports.SHOW_EASYPAY_ENROLL_EVENT = _exports.HIDE_EXPRESS_PAY_EVENT = _exports.EXPRESS_PAY_EVENT = _exports.HIDE_ONE_TIME_PAYMENT_MESSAGE_EVENT = _exports.ONE_TIME_PAYMENT_EVENT = _exports.EASYPAY_ENROLL_EVENT = _exports.SHOW_STATUS_MESSAGE_EVENT = _exports.SUCCESS_PAYMENT_EVENT = _exports.UPDATE_APP_STATE_EVENT = _exports.SHOW_STATUS_MESSAGES_EVENT = void 0;
  const SHOW_STATUS_MESSAGES_EVENT = 'SHOW_STATUS_MESSAGES_EVENT';
  _exports.SHOW_STATUS_MESSAGES_EVENT = SHOW_STATUS_MESSAGES_EVENT;
  const UPDATE_APP_STATE_EVENT = 'UPDATE_APP_STATE_EVENT';
  _exports.UPDATE_APP_STATE_EVENT = UPDATE_APP_STATE_EVENT;
  const SUCCESS_PAYMENT_EVENT = 'SUCCESS_PAYMENT_EVENT';
  _exports.SUCCESS_PAYMENT_EVENT = SUCCESS_PAYMENT_EVENT;
  const SHOW_STATUS_MESSAGE_EVENT = 'SHOW_STATUS_MESSAGE_EVENT';
  _exports.SHOW_STATUS_MESSAGE_EVENT = SHOW_STATUS_MESSAGE_EVENT;
  const EASYPAY_ENROLL_EVENT = 'EASYPAY_ENROLL_EVENT';
  _exports.EASYPAY_ENROLL_EVENT = EASYPAY_ENROLL_EVENT;
  const ONE_TIME_PAYMENT_EVENT = 'ONE_TIME_PAYMENT_EVENT';
  _exports.ONE_TIME_PAYMENT_EVENT = ONE_TIME_PAYMENT_EVENT;
  const HIDE_ONE_TIME_PAYMENT_MESSAGE_EVENT = 'HIDE_ONE_TIME_PAYMENT_MESSAGE_EVENT';
  _exports.HIDE_ONE_TIME_PAYMENT_MESSAGE_EVENT = HIDE_ONE_TIME_PAYMENT_MESSAGE_EVENT;
  const EXPRESS_PAY_EVENT = 'EXPRESS_PAY_EVENT';
  _exports.EXPRESS_PAY_EVENT = EXPRESS_PAY_EVENT;
  const HIDE_EXPRESS_PAY_EVENT = 'HIDE_EXPRESS_PAY_EVENT';
  _exports.HIDE_EXPRESS_PAY_EVENT = HIDE_EXPRESS_PAY_EVENT;
  const SHOW_EASYPAY_ENROLL_EVENT = 'SHOW_EASYPAY_ENROLL_EVENT';
  _exports.SHOW_EASYPAY_ENROLL_EVENT = SHOW_EASYPAY_ENROLL_EVENT;
  const EASYPAY_UPDATE_EVENT = 'EASYPAY_UPDATE_EVENT';
  _exports.EASYPAY_UPDATE_EVENT = EASYPAY_UPDATE_EVENT;
  const CRE_VALIDATION_ERROR_EVENT = 'CRE_VALIDATION_ERROR_EVENT';
  _exports.CRE_VALIDATION_ERROR_EVENT = CRE_VALIDATION_ERROR_EVENT;
  const CRE_ERROR_EVENT = 'CRE_ERROR_EVENT';
  _exports.CRE_ERROR_EVENT = CRE_ERROR_EVENT;
  const CRE_COMPLETE_PAYMENT_EVENT = 'CRE_COMPLETE_PAYMENT_EVENT';
  _exports.CRE_COMPLETE_PAYMENT_EVENT = CRE_COMPLETE_PAYMENT_EVENT;
  const CRE_COMPLETE_VALIDATION_EVENT = 'CRE_COMPLETE_VALIDATION_EVENT';
  _exports.CRE_COMPLETE_VALIDATION_EVENT = CRE_COMPLETE_VALIDATION_EVENT;
  const PIC_SUBMITTED_EVENT = 'PIC_SUBMITTED_EVENT';
  _exports.PIC_SUBMITTED_EVENT = PIC_SUBMITTED_EVENT;
  const PIC_VALIDATED_EVENT = 'PIC_VALIDATED_EVENT';
  _exports.PIC_VALIDATED_EVENT = PIC_VALIDATED_EVENT;
  const PIC_INVALIDATED_EVENT = 'PIC_INVALIDATED_EVENT';
  _exports.PIC_INVALIDATED_EVENT = PIC_INVALIDATED_EVENT;
  const SHOW_TIMEOUT_MODAL_WINDOW = 'SHOW_TIMEOUT_MODAL_WINDOW';
  _exports.SHOW_TIMEOUT_MODAL_WINDOW = SHOW_TIMEOUT_MODAL_WINDOW;
  const HIDE_CONTRACT_RENEWAL_ALERT = 'HIDE_CONTRACT_RENEWAL_ALERT';
  _exports.HIDE_CONTRACT_RENEWAL_ALERT = HIDE_CONTRACT_RENEWAL_ALERT;
  const HIDE_EASYPAY_PROMO_ALERT = 'HIDE_EASYPAY_PROMO_ALERT';
  _exports.HIDE_EASYPAY_PROMO_ALERT = HIDE_EASYPAY_PROMO_ALERT;
  const SET_SWEEPSTAKES_MODAL_DRAWING_TIME = 'SET_SWEEPSTAKES_MODAL_DRAWING_TIME';

  /**
   * EventEmmiter. Implementation of pub/sub pattern
   *
   * @module Services/AppDispatcher
   */
  _exports.SET_SWEEPSTAKES_MODAL_DRAWING_TIME = SET_SWEEPSTAKES_MODAL_DRAWING_TIME;
  var _default = Ember.Service.extend(Ember.Evented, {
    appLogger: Ember.inject.service(),
    subscribe(event, context = {}, methodName) {
      this.get('appLogger').appLogSubscriber({
        action: event,
        initiator: context._namespace,
        method: String(context[methodName])
      });
      this.on(event, context, methodName);
    },
    unsubscribe(event, context = {}, methodName) {
      this.get('appLogger').appLogUnsubscriber({
        action: event,
        initiator: context._namespace,
        method: String(context[methodName])
      });
      this.off(event, context, methodName);
    },
    sendEvent(event, data, context = {}) {
      this.get('appLogger').appLogEvent({
        action: event,
        initiator: context._namespace,
        data
      });
      this.trigger(event, data);
    },
    /**
     * Send event to show status message which based on route name
     *
     * @param {*} context
     * @param {String|Classes/StatusMessage[]|Classes/StatusMessage} data - message. If string and has type then will be wrapped by StatusMessage instance
     * @param {String} type - messages type (error, success). If undefined than data will not be wrapped by StatusMessage instance
     * @param {String} target - name of Status Message Component rendered on the page, needed to show messages inside modal window
     */
    showMessage(context, data, type, target) {
      this.sendEvent(SHOW_STATUS_MESSAGE_EVENT, {
        msg: type ? new _statusMessage.default(data, type) : data,
        target,
        type
      }, context);
    }
  });
  _exports.default = _default;
});