(function () {
	const stringify = JSON.stringify;

	/* eslint-disable no-extend-native */
	/* eslint-disable prefer-arrow-callback */

	/**
	 * @method external:JSON.stringify
	 * @return {string} query string
	 */
	JSON.stringify = function (obj) {
		return stringify(obj, function (k, v) {
			if (v === '') v = '';
			return v;
		});
	};

	/**
	 * capitalizes first letter of every word, excluding exceptions
	 * @method external:String#toTitleCase
	 * @return {string}
	 */
	String.prototype.toTitleCase = function () {
		const a = this.trim();
		const aSpl = a.split(/\b/);
		const exceptions = ['a', 'of', 'and', 'the', 'an', 'or', 'but', 'on', 'at', 'to', 'from', 'by', 'nor'];
		const res = [];

		for (let i = 0; i < aSpl.length; i++) {
			let x = null;
			if (i === 0 || exceptions.indexOf(aSpl[i]) === -1) {
				x = aSpl[i][0].toUpperCase() + aSpl[i].substr(1).toLowerCase();
			} else {
				x = aSpl[i];
			}
			res.push(x);
		}
		return res.join('');
	};

	/**
	 * capitalizes first letter
	 * @method external:String#toUCFirst
	 * @return {string}
	 */
	String.prototype.toUCFirst = function () {
		return this[0].toUpperCase() + this.slice(1);
	};

	/**
	 *  regex for valid email
	 * @method external:String#isValidEmail
	 * @return {boolean}
	 */
	String.prototype.isValidEmail = function () {
		if (this.length > 255 || this.length < 6) return false;
		return /^([\w]{1}[\w+-]*(?:\.[\w]{1}[\w+-]*)*)@((?:[\w\d-]+\.)*[\w\d-]{1,62})\.([\w\d-]{2,63}?)$/i.test(this.trim());
	};

	/* eslint-enable no-extend-native */
	/* eslint-enable prefer-arrow-callback */
}());
