define('ember-user-activity/services/user-idle', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    userActivity: Ember.inject.service('ember-user-activity@user-activity'),

    _debouncedTimeout: null,

    activeEvents: ['userActive'],
    IDLE_TIMEOUT: 600000, // 10 minutes
    isIdle: false,

    _setupListeners(method) {
      let userActivity = this.get('userActivity');
      this.get('activeEvents').forEach(event => {
        userActivity[method](event, this, this.resetTimeout);
      });
    },

    init() {
      this._super(...arguments);

      if (Ember.testing) {
        // Shorter debounce in testing mode
        this.set('IDLE_TIMEOUT', 10);
      }
      this._setupListeners('on');
      this.resetTimeout();
    },

    willDestroy() {
      this._setupListeners('off');
      if (this._debouncedTimeout) {
        Ember.run.cancel(this._debouncedTimeout);
      }

      this._super(...arguments);
    },

    resetTimeout() {
      let oldIdle = this.get('isIdle');
      this.set('isIdle', false);
      if (oldIdle) {
        this.trigger('idleChanged', false);
      }
      this._debouncedTimeout = Ember.run.debounce(this, this.setIdle, this.get('IDLE_TIMEOUT'));
    },

    setIdle() {
      if (this.isDestroyed) {
        return;
      }
      this.set('isIdle', true);
      this.trigger('idleChanged', true);
    }
  });
});