define("adt-wss-common/utils/ember-helpers", ["exports", "adt-wss-common/utils/fp"], function (_exports, _fp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.eq = _exports.isState = _exports.computedIfElse = _exports.initializeObjects = _exports.computedSome = _exports.computedApplyFunction = _exports.computedFindBy = void 0;
  /**
   * @namespace EmberHelpers
   * @desc Helpers to extend Ember functionality
   */

  /**
   * Find object in array by criteria using computed property
   * @memberof EmberHelpers
   * @example
   * EmberObject.extend({
   * 	myArray: [{id: 1, status: true}, {id: 2, status: false}],
   *  secondItem: computedFindBy('myArray', 'status', false), // {id: 2, status: false}
   * });
   * @param {String} emberPropName - name of property in context
   * @param {String} key - key of criteria
   * @param {*} value - value of criteria
   * @return {ComputedProperty} Found Object from Array
   */
  const computedFindBy = (emberPropName, key, value) => Ember.computed(emberPropName, function () {
    return Ember.A(this.get(emberPropName) || []).findBy(key, value);
  });

  /**
   * Call function with params from properties
   * @memberof EmberHelpers
   * @example
   * EmberObject.extend({
   * 	a: 3,
   *  b: 2,
   *  multiply(a, b) {
   *  	return a * b;
   *  },
   *  result: computedApplyFunction((a, b) => a + b, 'a', 'b') // 5
   *  result2: computedApplyFunction('multiply', 'a', 'b') // 6
   * });
   * @param  {Function|String} func - could be function or name of function from context
   * @param  {Array} args - es6 rest array of names of properties
   * @return {ComputedProperty}
   */
  _exports.computedFindBy = computedFindBy;
  const computedApplyFunction = (func, ...args) => {
    const handler = function () {
      const callback = func instanceof Function ? func : this.get(func);
      const props = this.getProperties(...args);
      const values = args.map(key => props[key]);
      return callback.apply(this, values);
    };
    const x = args.concat([handler]);
    return Ember.computed(...x);
  };

  /**
   * Check equality of property with provided criteria
   * @memberof EmberHelpers
   * @example
   * const stateA = 'A';
   * const stateB = 'B';
   * const currentState = 'A';
   * computedSome('currentState', stateA, stateB),
   *
   * @param  {String} propName
   * @param  {Array} criteria
   * @return {Object}
   */
  _exports.computedApplyFunction = computedApplyFunction;
  const computedSome = (propName, ...criteria) => Ember.computed(propName, function () {
    const prop = this.get(propName);
    return criteria.some(c => c === prop);
  });

  /**
   * Create initial values for objects
   * @memberof EmberHelpers
   * @description
   * https://dockyard.com/blog/2015/09/18/ember-best-practices-avoid-leaking-state-into-factories
   * 2019-10-29: no longer creates `computed` properties; overriding `computed` values is deprecated
   * in Ember 3.12
   *
   * @example
   * initializeObjects({ items: [] });
   *
   * @param  {Object} hash
   * @return {Object}
   */
  _exports.computedSome = computedSome;
  const initializeObjects = hash => Object.entries(hash).reduce((acc, [k, v]) => (0, _fp.omix)(acc, {
    [k]: v || null
  }), {});

  /**
   * Check contition and return one of two results
   * @memberof EmberHelpers
   * @example
   * const stateA = 'A';
   * const stateB = 'B';
   * Service.extend({
   * 	stateForSmartphone: computedIfElse('media.isSmartphone', stateA, stateB), // will be A
   * });
   * @example
   * Service.extend({
   * 	stateA: 'AAA',
   * 	stateB: 'BBBB',
   * 	stateForSmartphone: computedIfElse('media.isSmartphone', ['stateA', 'stateB']), // will be AAA
   * });
   * @param  {String} condition - property from context which needs to check
   * @param  {Array|*} maybeArray - if array -then contein 2 strings from context, for true or falsy state. if not array then this param used for true state.
   * @param  {*} maybeFalse - if "maybeArray" is not array then this param used as param for falsy state
   * @return {Object}
   */
  _exports.initializeObjects = initializeObjects;
  const computedIfElse = (condition, maybeArray, maybeFalse) => Ember.computed(condition, function () {
    const conditionProp = this.get(condition);
    if (maybeArray instanceof Array) {
      const [ok, notOk] = maybeArray;
      const trueProp = this.get(ok);
      const falseProp = this.get(notOk);
      return conditionProp ? trueProp : falseProp;
    }
    return conditionProp ? maybeArray : maybeFalse;
  });
  /**
   * @memberof EmberHelpers
   * @example
   * {
   * 	currentState: IDLE_STATE,
   * 	IS_LOADING: isState(LOADING_STATE)
   * }
   * @param  {String} state - name of state
   * @return {Boolean}
   */
  _exports.computedIfElse = computedIfElse;
  const isState = state => Ember.computed.equal('currentState', state);
  _exports.isState = isState;
  const eq = (a, b) => Ember.computed(a, b, function () {
    const props = this.getProperties(a, b);
    return props[a] === props[b];
  });
  _exports.eq = eq;
});