window.myADT = (function(root) {
	/**
	 * Polyfill for `window.location.origin`
	 * @memberof MyADTHelpers
	 */
	root.getOrigin = function () {
		let origin = window.location.origin;
		if (!origin) {
			const port = window.location.port ? ':' + window.location.port : '';
			origin = window.location.protocol + '//' + window.location.hostname + port;
		}
		return origin;
	};

	/**
	 * Detect for PopUpBlocker
	 * @memberof MyADTHelpers
	 * @param {Window} poppedWindow
	 * @param {Function} callback
	 */
	root.hasPopupBlocker = function(poppedWindow, callback) {
		let result = false;
		/* eslint-disable prefer-arrow-callback */
		setTimeout(function() {
			try {
				if (typeof poppedWindow === 'undefined') {
					result = true;
				} else if (poppedWindow && poppedWindow.closed) {
					result = false;
				} else if (poppedWindow && poppedWindow.test) {
					result = false;
				} else if (poppedWindow.outerHeight === 0) {
					result = true;
				}
			} catch (ex) {
				console.error(ex);
			}

			if (result) {
				if (typeof callback === 'undefined') {
					alert('Please disable your popup blocker.');
				} else {
					callback.call();
				}
			}
		}, 2000);
		/* eslint-enable prefer-arrow-callback */
	};

	root.getBrowserDetails = function() {
		const userAgent = navigator.userAgent;
		const UNKNOWN = 'unknown';
		let M = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
		let tem;

		if (/trident/i.test(M[1])) {
			tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [UNKNOWN];

			return { name: 'IE', version: tem[1] };
		}
		if (M[1] === 'Chrome') {
			tem = userAgent.match(/\bOPR|Edge\/(\d+)/);

			if (tem != null) { return { name: 'Opera', version: tem[1] }; }
		}

		M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
		tem = userAgent.match(/version\/(\d+)/i);

		if (tem != null) { M.splice(1, 1, tem[1]); }

		return {
			name: M[0],
			version: M[1]
		};
	};

	return root;
}(window.myADT || {}));
