define("adt-wss-common/mixins/scroll-route-to-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    beforeModel(...args) {
      this._super(...args);
      window.scrollTo(0, 0);
    }
  };
  _exports.default = _default;
});