define("adt-wss-common/components/widget/custom-select", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss-common/templates/components/widget/custom-select"], function (_exports, _withFsm, _customSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _temp;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * @module Widget/CustomSelect
   * @prop {Object[]} items list of items for the drop down
   * @prop {String} optionKey if items are objects, this is the name of the param that will be the option text
   * @prop {*} optValue mutable object from parent component/controller
   * @prop {Function} [changeMethod] method to run on `change` event
   * @prop {Boolean} [rounded] turns on 'rounded' class
   * @prop {String} label
   * @prop {Boolean} required
   * @example
   * 	{{widget/custom-select yellowArrow=true rounded=true items=dateRanges optValue=(mut dateRange) optionKey="text"}}
   */
  let CustomSelect = (_class = (_temp = class CustomSelect extends Ember.Component.extend(_withFsm.default) {
    constructor(...args) {
      super(...args);
      this.layout = _customSelect.default;
      this.classNames = ['customselect'];
      this.classNameBindings = ['rounded', 'flat', 'hasLabel:labeled', 'yellowArrow:yellow-arrow', 'switchNone:switch-none'];
      this.hasLabel = Ember.computed.bool('label');
      _initializerDefineProperty(this, "optionKey", _descriptor, this);
      _initializerDefineProperty(this, "optValue", _descriptor2, this);
      _initializerDefineProperty(this, "disabled", _descriptor3, this);
      this.ariaPrefix = '';
      this.actions = {
        update(i) {
          const items = this.items || [];
          this.set('optValue', items[i]);
          if (typeof this.changeMethod === 'function') {
            this.changeMethod(items[i]);
          }
        }
      };
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (!this.optValue) {
        this.actions.update.call(this, '0');
      }
    }
  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "optionKey", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "optValue", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "disabled", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
  _exports.default = CustomSelect;
});