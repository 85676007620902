define("adt-wss-common/mixins/with-fsm", ["exports", "adt-wss-common/utils/route-helpers", "adt-wss-common/utils/fp", "adt-wss-common/utils/common-fsm-state", "adt-wss-common/classes/state-machine"], function (_exports, _routeHelpers, _fp, _commonFsmState, _stateMachine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Auto inject Finiti State Machine to Controller or Component
   * @prop {Object} fsmModel - structure of FSM
   * @prop {String} currentState - initial state of FSM
   * @prop {String} initAction - name of Action which need to call i=on init
   * @prop {*} paramsForInitAction - parameters for init action
   * @mixin withFSM
   */
  var _default = {
    /**
     * Returns the name of current entity
     * @return {String} - Name of current entity (component or controller)
     */
    getCurrentEntityName() {
      // Returns a string with full name of entity, like: <ProjectName@component:path/to/component-name::ember793>
      const entityName = this.toString();
      const regex = entityName.match(/@(.*?)::/);
      return regex ? regex[regex.length - 1] : null;
    },
    getMethod(methodName) {
      const method = this.get(methodName);
      if (!(method instanceof Function)) {
        console.error("".concat(this._namespace, " does not have ").concat(methodName, " method!"));
        return () => undefined;
      }
      return method;
    },
    router: Ember.inject.service(),
    init() {
      this._super(...arguments);
      const entityName = this.getCurrentEntityName();
      if (!window.myadtcomponents) {
        window.myadtcomponents = [];
      }
      if (window.myadtcomponents.indexOf(entityName) === -1) {
        window.myadtcomponents.push(entityName);
      }
      this._namespace = entityName;
      this._messageTarget = this.get('router.currentRouteName');
      const fsmModel = this.get('fsmModel');
      const currentState = this.get('currentState');
      if (fsmModel) {
        if (!currentState) {
          this.set('currentState', 'IDLE_STATE');
        }
        this.fsm = _stateMachine.default.create((0, _fp.omix)(fsmModel, {
          context: this
        }));
      }
      this._callFSMInitAction();
    },
    /**
     * return data from service cache or call method of service to get it from server
     * @memberof EmberHelpers.Route
     * @param  {String} path  path for data
     * @param  {Function} handler    method which will be called if data absent in cache
     * @param  {Boolean} options.isNativeData - return data from from response or service cache?
     * @return {Promise}
     * @example
     * // built-in component usage
     * const safetyTipsInfo = this.deliverData('safetyTips.tipsList', safetyTips => safetyTips.getSafetyTips());
     *	safetyTipsInfo.then(tips => {
     *		this.set('tips', tips);
     *	});
     *
     * @example
     * // built-in component usage
     *  const safetyTipsInfo = this.deliverData('safetyTips.tipsList', safetyTips => safetyTips.getSafetyTips(), {isNativeData : true});
     *  safetyTipsInfo.then(response => console.log(response)) // { data: {tips: []}, erros:[] }
     */
    deliverData() {
      return (0, _routeHelpers.makeDeliverData2)(this.getCurrentEntityName(), this)(...arguments);
    },
    /**
     * Show status message for some route or target rendered in application
     * @param {String|Classes/StatusMessage|Classes/StatusMessage[]} data - message or instance os statusMessage
     * @param {String} [type] - type of message
     * @param {String} [target] - name of status message component rendered on the page. By default based on the route name
     */
    showStatusMessage(data, type, target) {
      return this.get('appDispatcher').showMessage(this, data, type, target || this._messageTarget || 'root');
    },
    /**
     * Allows to bulk deliver data using non failure behaviour
     * @param  {Object} model - list of data which need to fetch
     * @param  {String} model.{}.key - name of resolved data
     * @param  {Array} model.{}.value - interface of deliverData arguments
     * @param  {String} model.{}.value.[].zeroIndex - path to service and data
     * @param  {Function} [model.{}.value.[].oneIndex] - callback to fetch data
     * @param {Boolean} isResolvedOnly
     * @example
     *  hashDeliverData({
     *  	appointments: ['appointmentData.appointmentList', fetchAppointmentList],
    			customerEmail: ['appointmentData.customerEmail']
     *  }, true).then(console.log); // { appointments: [], customerEmail: 'abc@mail.com' }
     * @example
     *  hashDeliverData({
     *  	appointments: ['appointmentData.appointmentList', fetchAppointmentList],
    			customerEmail: ['appointmentData.customerEmail']
    			someFailed: ['appointmentData.someFailed', () => Promise.reject('error')]
     *  })
     *  .then(console.log)
     *  .catch(console.log); // error
     * @return {Promise}
     */
    hashDeliverData(model = {}) {
      return Ember.RSVP.hash((0, _fp.omap)((value = []) => this.deliverData(...value))(model));
    },
    hashDeliverDataAndDispatch(model = {}) {
      return (0, _commonFsmState.hashDeliverDataAndDispatch)(this, model);
    },
    /**
     * Allows to bulk deliver data using non failure behaviour
     * @param  {Object} model - list of data which need to fetch
     * @param  {String} model.{}.key - name of resolved data
     * @param  {Array} model.{}.value - interface of deliverData arguments
     * @param  {String} model.{}.value.[].zeroIndex - path to service and data
     * @param  {Function} [model.{}.value.[].oneIndex] - callback to fetch data
     * @param {Boolean} isResolvedOnly
     * @example
     *  hashSettledDeliverData({
     *  	appointments: ['appointmentData.appointmentList', fetchAppointmentList],
    			customerEmail: ['appointmentData.customerEmail']
    			someFailed: ['appointmentData.someFailed', () => Promise.reject('error')]
     *  }, true).then(console.log); // { appointments: [], customerEmail: 'abc@mail.com' }
     * @example
     *  hashSettledDeliverData({
     *  	appointments: ['appointmentData.appointmentList', fetchAppointmentList],
    			customerEmail: ['appointmentData.customerEmail']
    			someFailed: ['appointmentData.someFailed', () => Promise.reject('error')]
     *  }).then(console.log); // { resolved: { appointments: [], customerEmail: 'abc@mail.com' }, rejected: { someFailed: 'error'}}
     * @return {Promise}
     */
    hashSettledDeliverData(model = {}, isResolvedOnly) {
      return Ember.RSVP.hashSettled((0, _fp.omap)(model, (value = []) => this.deliverData(...value))).then(isResolvedOnly ? _routeHelpers.useResolvedOnly : (0, _routeHelpers.promiseResolver)((resolved, rejected) => ({
        resolved,
        rejected
      })));
    },
    hashSettledDeliverDataAndDispatch(model = {}, isResolvedOnly) {
      return (0, _commonFsmState.hashSettledDeliverDataAndDispatch)(this, model, isResolvedOnly);
    },
    /**
     * Gets data from service or call method of service id data is not exist and automatically dispatch default actions
     *
     * @param {String} path - path to data located in the service. `session.data.isAuthenticated`
     * @param {Function} callback - callback will be called id data is falsy. Callback argument is service instance described in the path. `session`. Callback function must return Promise
     * @param {String} success - Action which will be dispatcher on success
     * @param {String} failure -  Action which will be dispatcher on fail
     * @param {Boolean} [scrollOnSuccess=true] - scroll to the top of page on success
     * @param {Boolean} [scrollOnFail=true] - scroll to the top of page on success
     * @param {Object} [options={}] - additional options
     * @returns Promise
     */
    deliverDataAndDispatch(path, callback, success, failure, scrollOnSuccess = true, scrollOnFail = true, options = {}) {
      return (0, _commonFsmState.deliverDataAndDispatch)(this, path, callback, success, failure, scrollOnSuccess, scrollOnFail, options);
    },
    /**
     * call function automatically dispatch default actions
     *
     * @param {Function} callback - Callback function must return Promise
     * @param {String} success - Action which will be dispatcher on success
     * @param {String} failure -  Action which will be dispatcher on fail
     * @param {boolean} [scrollOnSuccess=true]
     * @param {boolean} [scrollOnFail=true]
     * @returnsPromise
     */
    commonDispatcher(callback, success, failure, scrollOnSuccess = true, scrollOnFail = true) {
      return (0, _commonFsmState.commonDispatcher)(this, callback, success, failure, scrollOnSuccess, scrollOnFail);
    },
    /**
     * Dispatch FSM action for build-in FSM in components or controllers
     * @return {*} Result of action function
     */
    _callFSMInitAction() {
      const {
        initAction,
        fsm,
        paramsForInitAction
      } = this.getProperties('initAction', 'fsm', 'paramsForInitAction');
      if (initAction && fsm) {
        fsm.dispatch(initAction, paramsForInitAction);
      }
    },
    /**
     * Inject fake FSM for testing goals
     */
    _injectTestFSM() {
      const fsmForTest = this.get('fsmForTest');
      const fsm = this.get('fsm');
      if (fsmForTest) {
        fsmForTest.context = fsm.get('context');
        this.set('fsm', (0, _fp.omix)(fsm, {
          dispatch(...args) {
            fsmForTest.dispatch(...args);
            return fsm.dispatch(...args);
          }
        }));
      }
    },
    didReceiveAttrs() {
      this._super(...arguments);
      this._injectTestFSM();
    },
    actions: {
      dispatch(...args) {
        return this.get('fsm').dispatch(...args);
      }
    }
  };
  _exports.default = _default;
});