define("adt-wss-common/classes/memoize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
  * Memoize prevents HTTP request duplication
  * -------
  *
  * @class
  * @name Classes/Memoize
  * @param {Number} timeout - time in milliseconds to keep requests in cache
  * @prop {Number} timeout - default: 2000 | time in milliseconds to keep requests in cache
  * @prop {Object} _memory - private request store (cache)
  *
  * @example
  	const memoize = new Memoize(8000); // milliseconds
  	if (memoize.check(options)) {
  		return memoize.fromCache(options);
  	}
  	const promise =  new Promise((resolve, reject) => {
  		$.ajax(ajaxRequest).done((response) => {
  			const results = this.handleAjaxResponse(response, ajaxRequest, true);
  
  			if (results.success)
  				run(null, resolve, results);
  			else
  				run(null, reject, results);
  
  		}).fail((xhr) => {
  			const results = this.handleAjaxResponse(xhr, ajaxRequest, false);
  
  			if (ajaxRequest.checkResponseHeaders)
  				this.checkResponseHeaders(xhr.status, ajaxRequest);
  			run(null, reject, results);
  		});
  	});
  	memoize.save(options, promise);
  	return promise;
  */

  class Memoize {
    constructor(timeout = 2000) {
      this.timeout = timeout;
      this._memory = {};
    }

    /**
     * Saves data in cache
     * @param {Object} options - http options for request
     * @param {Object} value - data saved in cache
     * @param {Promise} value.promise - saved request
     * @param {Date} value.timestamp - timestamp when data was saved
     * @memberof Classes/Memoize
     */
    memorySet(options, value) {
      this._memory[JSON.stringify(options)] = value;
    }

    /**
     * Gets data from cache
     * @param {Object} options - http options for request
     * @returns {Object} - data saved in cache
     * @memberof Classes/Memoize
     */
    memoryGet(options) {
      return this._memory[JSON.stringify(options)];
    }

    /**
     * Delete data from cache
     * @param {Object} options - http options for request
     * @memberof Classes/Memoize
     */
    memoryDelete(options) {
      return delete this._memory[JSON.stringify(options)];
    }

    /**
     * Check if time is expired
     * @param {Date} time
     * @returns {Boolean}
     * @memberof Classes/Memoize
     */
    isExpired(time) {
      return new Date().timeSpan(time).millis > this.timeout;
    }

    /**
     * Check if request already in cache and removes it if expired
     * @param {Object} options - http options for request
     * @returns {Boolean}
     * @memberof Classes/Memoize
     */
    check(options) {
      const {
        timestamp
      } = this.memoryGet(options) || {};
      if (timestamp) {
        if (this.isExpired(timestamp)) {
          this.memoryDelete(options);
          return false;
        }
        return true;
      }
      return false;
    }

    /**
     * Created timestamp and saves request in cache
     * @param {Object} options - http options for request
     * @param {Promise} promise - http request promise object
     * @memberof Classes/Memoize
     */
    save(options, promise) {
      this.memorySet(options, {
        promise,
        timestamp: new Date()
      });
    }

    /**
     * Gets saved request from cache
     * @param {Object} options - http options for request
     * @returns {Promise}
     * @memberof Classes/Memoize
     */
    fromCache(options) {
      const {
        promise
      } = this.memoryGet(options);
      return promise;
    }

    /**
     * Clears item from cache
     * @param {Object} options - http options for request
     * @returns {Promise}
     * @memberof Classes/Memoize
     */
    clear(options) {
      return this.memoryDelete(options);
    }
  }
  _exports.default = Memoize;
});