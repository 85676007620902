define("adt-wss-common/utils/route-helpers", ["exports", "adt-wss-common/utils/fp"], function (_exports, _fp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeDeliverData2 = _exports.makeDeliverData = _exports.useResolvedOnly = _exports.promiseResolver = _exports.fsmDispatch = _exports.extractPromisesHash = _exports.emptyPromise = void 0;
  /**
   * Return data wrapped to Promise
   * @memberof EmberHelpers.Route
   * @param  {*} data
   * @return {Promise}
   */
  const emptyPromise = data => Ember.RSVP.Promise.resolve(data);

  /**
   * @namespace EmberHelpers.Route
   * @desc Helpers for manipulating data in route models
   */

  /**
   * Extract data from hashSettled response
   * @memberof EmberHelpers.Route
   * @param  {Object} depthMap - Object with meta information
   * @param  {Object} depthMap.{key} - name where need to save data
   * @param  {Object} depthMap.{value} - path for value which need to get from response
   * @param  {Boolean} silent - Hide error for failed responces
   * @param  {*} response - Server response
   * @method
   * @example
   * const billingModel = this.deliverData('billingData.billingModel');
   * const currentLocation = this.deliverData('contactData.currentLocation');
   * hashSettled({currentLocation, billingModel})
   * 	.then(extractPromisesHash({
   * 		balanceValue: 'billingModel.billInfo.balance',
   * 		accountNumber: 'currentLocation.profileInfo.accountId',
   * 		nextPaymentDate: 'billingModel.billInfo.balanceDueDateObj',
   * 		enrolledInEasypay: 'billingModel.billingOptions.enrolledInEasyPay',
   * 		hasPermission: 'currentLocation.permissions.billing'
   * 	}))
   * 	.then(data => console.log(data)) // { balanceValue: 111, accountNumber: 22, hasPermission: true, etc... }
   *
   * @return {Object}  pure data
   */
  _exports.emptyPromise = emptyPromise;
  const extractPromisesHash = (aliasMap, silent) => response => {
    const clearResponse = {};
    const extractedValues = {
      $$$Errors__: {}
    };
    Object.keys(response, key => {
      const data = response[key];
      clearResponse[key] = data.value;
      extractedValues.$$$Errors__[key] = data.reason;
      if (data.reason) {
        if (!silent) {
          console.error("Promise \"".concat(key, "\" is rejected:"), data.reason);
        }
      }
    });
    Object.keys(aliasMap, key => {
      const path = aliasMap[key];
      try {
        extractedValues[key] = (0, _fp.get)(path)(clearResponse);
      } catch (error) {
        console.error(error);
      }
    });
    return aliasMap ? extractedValues : clearResponse;
  };

  /**
   * Dispatch FSM action from promise handler
   * @memberof EmberHelpers.Route
   * @param  {String} action
   * @param  {Object} scope
   * @param  {*} props
   * @example
   * Promise.resolve([1, 2, 3]).then(fsmDispatch(SHOW_NUMBERS_ACTION)(this))
   */
  _exports.extractPromisesHash = extractPromisesHash;
  const fsmDispatch = (action, scroll) => scope => props => scope.get('fsm').dispatch(action, props, scroll);

  /**
   * Devide rejected and resolved promise results and extrach data form them
   * @memberof RouteHelpers.Route
   * @param  {Function} callback handler of result
   * @param  {Boolean}   silent - disable error messages of rejected promises
   * @return {Function} promise then handler
   * @example
   * RSVP.hashSettled({a,b, auth: Promise.reject({expired: true})})
   *   .then(promiseResolver((resolved, rejected) => {
   *     if (rejected.auth.expired) {
   *       throw new Error('Please login');
   *     }
   *     return resolved; //{a:1, b:2}
   * }))
   */
  _exports.fsmDispatch = fsmDispatch;
  const promiseResolver = (callback, silent) => response => {
    const resolved = {};
    const rejected = {};
    Object.keys(response).forEach(promiseName => {
      const data = response[promiseName];
      if (data.state === 'fulfilled') resolved[promiseName] = data.value;
      if (data.state === 'rejected') {
        rejected[promiseName] = data.reason;
        if (!silent) {
          console.error("Promise \"".concat(promiseName, "\" is rejected:"), data.reason);
        }
      }
    });
    return Ember.RSVP.Promise.resolve(callback(resolved, rejected));
  };

  /**
   * simple wrapper on promiseResolver to do not care of rejected promises
   * @memberof EmberHelpers.Route
   * @method
   * @param  {Object} response - object of resolved or rejected promise results
   * @return {Function} promise then handler
   * @example
   * RSVP.hashSettled({a, b, c: Promise.reject()}).then(useResolvedOnly); // //{a:1, b:2}
   */
  _exports.promiseResolver = promiseResolver;
  const useResolvedOnly = response => promiseResolver(d => d)(response);

  /**
   * Fetch data if they don't exist
   * @memberof EmberHelpers.Route
   * @method
   * @deprecated - use makeDeliverData2
   * @param {Object} context
   * @param {String} serviceName
   * @param {String} key - path for data
   * @param {Function} loader - method which will be called if data absent in cache
   * @param {Boolean} options.isNativeData - return data from from response or service cache?
   * @example
   * makeDeliverData(this, 'contentData')('emergencyContacts', () => )) // returns Promise
   * @return {Function}
   */
  _exports.useResolvedOnly = useResolvedOnly;
  const makeDeliverData = (context, serviceName) => (key, loader = emptyPromise, options = {}) => {
    const {
      isNativeData,
      forceRequest = false
    } = options;
    const service = context.get(serviceName);
    const data = service.get(key);
    if ((Ember.isPresent(data) || typeof data === 'object') && data !== null && Object.keys(data).length > 0 && !forceRequest) {
      return Ember.RSVP.Promise.resolve(data);
    }
    return loader(service).then(r => isNativeData ? r : service.get(key));
  };

  /**
   * Curried function return data from service cache or call method of service to get it from server
   * @memberof EmberHelpers.Route
   * @param  {String} initiator Component name or other service name
   * @param  {Object} ctx     scope
   * @param  {String} path  path for data
   * @param  {Function} handler    method which will be called if data absent in cache
   * @param  {Boolean} options.isNativeData - return data from from response or service cache?
   * @return {Promise}
   * @example
   * // Independent usage
   * const delivery = makeDeliverData('my super cool service', this);
   * const superUsersPromise = delivery('mySuperCool.users', mySuperCool => mySuperCool.getSuperUsers())
   * superUsersPromise.then(users => console.log(user));
   *
   * @example
   * // built-in component usage
   * const safetyTipsInfo = this.deliverData('safetyTips.tipsList', safetyTips => safetyTips.getSafetyTips());
   *	safetyTipsInfo.then(tips => {
   *		this.set('tips', tips);
   *	});
   *
   * @example
   * // built-in component usage
   *  const safetyTipsInfo = this.deliverData('safetyTips.tipsList', safetyTips => safetyTips.getSafetyTips(), {isNativeData : true});
   *  safetyTipsInfo.then(response => console.log(response)) // { data: {tips: []}, erros:[] }
   */
  _exports.makeDeliverData = makeDeliverData;
  const makeDeliverData2 = (initiator, ctx) => (path = '', handler = emptyPromise, options = {}) => {
    const {
      isNativeData,
      forceRequest = false,
      __getOwner = Ember.getOwner
    } = options;
    const [serviceName, ...rest] = path.split('.');
    const otherPath = rest.join('.');
    const service = __getOwner(ctx).lookup("service:".concat(serviceName));
    const appLogger = __getOwner(ctx).lookup('service:app-logger');
    const data = service.get(otherPath);
    const method = String(handler);
    const isData = Ember.isPresent(data);
    const isFillObj = typeof data === 'object' && data !== null && Object.keys(data).length > 0;
    const isFillArray = data instanceof Array && data.length > 0;
    if ((isData || isFillObj || isFillArray) && !forceRequest) {
      appLogger.logDeliveryData({
        initiator,
        path: otherPath,
        serviceName
      });
      return Ember.RSVP.Promise.resolve(data);
    }
    appLogger.logDeliveryData({
      initiator,
      path: otherPath,
      serviceName,
      method
    });
    return handler(service).then(r => isNativeData ? r : service.get(otherPath));
  };
  _exports.makeDeliverData2 = makeDeliverData2;
});