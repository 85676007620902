define("adt-wss-common/helpers/ellipsis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ellipsis = ellipsis;
  _exports.default = void 0;
  /**
   * Trims a string that's too long and adds an ellipsis.
   * @name ellipsis
   * @memberof TemplateHelpers
   * @param {String|Int[]} params [`string`, `length`]
   * @return {String}
   * @example {{ellipsis 'The quick brown fox jumped over you.' 12}} -> 'The quick br...'
   */
  function ellipsis(params) {
    if (typeof params[1] !== 'number') {
      return params[0];
    }
    if (params[0].length > params[1]) {
      return params[0].substr(0, params[1]) + '...';
    }
    return params[0];
  }
  var _default = Ember.Helper.helper(ellipsis);
  _exports.default = _default;
});