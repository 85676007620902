define("ember-power-calendar/templates/components/power-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hjlwitgq",
    "block": "{\"symbols\":[\"calendar\",\"&default\",\"&attrs\"],\"statements\":[[4,\"with\",[[28,\"assign\",[[23,0,[\"publicAPI\"]],[28,\"hash\",null,[[\"Nav\",\"Days\"],[[28,\"component\",[[23,0,[\"navComponent\"]]],[[\"calendar\"],[[28,\"readonly\",[[23,0,[\"publicAPI\"]]],null]]]],[28,\"component\",[[23,0,[\"daysComponent\"]]],[[\"calendar\"],[[28,\"readonly\",[[23,0,[\"publicAPI\"]]],null]]]]]]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",\"ember-power-calendar\"],[13,3],[12,\"id\",[23,1,[\"uniqueId\"]]],[8],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"      \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,[23,1,[\"Nav\"]],[],[[],[]]],[0,\"\\n      \"],[6,[23,1,[\"Days\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-calendar/templates/components/power-calendar.hbs"
    }
  });

  _exports.default = _default;
});