define("adt-wss-common/utils/state-comparator", ["exports", "adt-wss-common/utils/fp"], function (_exports, _fp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSchema = _exports.compareStatesEquality = _exports.generateStateSchemaMiddleware = _exports.STATE_SCHEMA_KEY = void 0;
  const STATE_SCHEMA_KEY = 'initialStateSchema';

  /**
   * Adds initial state schema to the object
   * @param {Object|Array} data
   * @return {Object|Array}
   *
   * @example
   *  generateStateSchemaMiddleware({ x: 1, y: 2 }); //=> { x: 1, y: 2, initialStateSchema: "{"x":1,"y":2}" }
   */
  _exports.STATE_SCHEMA_KEY = STATE_SCHEMA_KEY;
  const generateStateSchemaMiddleware = data => Object.assign(data, {
    [STATE_SCHEMA_KEY]: JSON.stringify(data)
  });

  /**
   * Compares previous state and current state of the object
   * Calls callback if states are different
   * @param {Object|Array} data
   * @param {Function} callback
   * @param {String} schema
   * @return {Promise}
   *
   * @example
   *  compareStatesEquality({ x: 1, y: 2, initialStateSchema: '"{"x":1,"y":2}"' });
   */
  _exports.generateStateSchemaMiddleware = generateStateSchemaMiddleware;
  const compareStatesEquality = (data, callback = v => Promise.resolve(v), schema = null) => {
    const pureHashTable = Array.isArray(data) ? data : (0, _fp.omit)(data, [STATE_SCHEMA_KEY]);
    const statesAreEqual = (schema || data[STATE_SCHEMA_KEY]) === JSON.stringify(pureHashTable);
    return statesAreEqual ? Promise.resolve({
      stub: true
    }) : callback(pureHashTable);
  };

  /**
   * Gets state schema from the object
   * @param {Object|Array} data
   * @return {String}
   *
   * @example
   *  getSchema({ x: 1, y: 2, initialStateSchema: '"{"x":1,"y":2}"' }); // => "{"x":1,"y":2}"
   */
  _exports.compareStatesEquality = compareStatesEquality;
  const getSchema = data => data[STATE_SCHEMA_KEY];
  _exports.getSchema = getSchema;
});