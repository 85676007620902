define("adt-wss-common/templates/components/widget/status-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3D2PFb4Y",
    "block": "{\"symbols\":[\"message\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"messages\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",false],[12,\"class\",[29,[\"status-message \",[28,\"if\",[[23,1,[\"button\"]],\"has-button\"],null],\" \",[23,1,[\"type\"]],[28,\"if\",[[23,1,[\"static\"]],\" static\"],null]]]],[3,\"action\",[[23,0,[]],\"close\",[23,1,[]]]],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"indicator\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[8],[0,\"\\n\\t\\t\\t\"],[4,\"if\",[[23,1,[\"head\"]]],null,{\"statements\":[[7,\"h5\",true],[8],[1,[23,1,[\"head\"]],true],[9]],\"parameters\":[]},null],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"message-text\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[23,1,[\"text\"]],true],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"button\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"button-blue button status-message-btn\",[23,1,[\"button\",\"route\"]]]],{\"statements\":[[1,[23,1,[\"button\",\"text\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[14,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss-common/templates/components/widget/status-messages.hbs"
    }
  });

  _exports.default = _default;
});