define("adt-wss-common/helpers/string-proto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stringProto = stringProto;
  _exports.default = void 0;
  /**
   * Formats date using has for value, method, and arguments
   * @name stringProto
   * @memberof TemplateHelpers
   * @param {String[]} params [`string`]
   * @param {Object} hash
   * @param {String} hash.method {@link external:String String} prototype function
   * @param {String} hash.arg1 first argument for method
   * @param {String} hash.arg2 second argument for method
   *
   * @return {String}
   * @example
   * {{string-proto 'busted' method='substr' -3}} - 'ted'
   */
  function stringProto(params, hash) {
    return params[0][hash.method](hash.arg1 || undefined, hash.arg2 || undefined);
  }
  var _default = Ember.Helper.helper(stringProto);
  _exports.default = _default;
});