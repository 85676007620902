define("adt-wss-common/services/appointment-data", ["exports", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/classes/service-appointment", "adt-wss-common/utils/localization", "adt-wss-common/utils/ember-helpers"], function (_exports, _myadtHelpers, _serviceAppointment, _localization, _emberHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.makeRescheduleTime = _exports.filterActiveAppointments = void 0;
  /**
   * filter appointments by status Open, Scheduled, Assigned
   * @param  {Array}  appointments
   * @return {Array}
   */
  const filterActiveAppointments = appointments => {
    const actApps = (appointments || []).filter(({
      jobStatusId
    }) => ['O', 'S', 'A'].indexOf(jobStatusId) !== -1);
    return actApps.length ? actApps : null;
  };
  _exports.filterActiveAppointments = filterActiveAppointments;
  const makeRescheduleTime = time => {
    const t = Date.createDate(time);
    return t.getFixedHour() + t.getFixedMinute();
  };
  _exports.makeRescheduleTime = makeRescheduleTime;
  const clearCacheArgs = {
    customerEmail: null,
    appointmentList: null,
    apptWebServiceStatus: 'up',
    rescheduleTimeblocks: {},
    scheduleTimeblocks: null,
    jobsList: null,
    appointmentsInit: true
  };
  /**
   * @module Services/AppointmentData
   * @prop {String} apptWebServiceStatus = 'up'
   * @prop {String} customerEmail
   * @prop {ServiceAppointmentNS.Appointment[]} appointmentList
   * @prop {Int[]} jobsList
   */
  var _default = Ember.Service.extend((0, _myadtHelpers.clearCache)(clearCacheArgs), {
    apiService: Ember.inject.service(),
    apptWebServiceStatus: 'up',
    customerEmail: null,
    appointmentList: null,
    scheduleTimeblocks: null,
    jobsList: null,
    appointmentsInit: true,
    showAppointmentsTab: Ember.computed.or('appointmentsInit', 'activeAppointments.length'),
    activeAppointments: (0, _emberHelpers.computedApplyFunction)(filterActiveAppointments, 'appointmentList'),
    processApptList(response) {
      const {
        appointmentList,
        customerEmail
      } = response.data;
      const jobs = (appointmentList || []).map(x => parseInt(x.jobNo, 10));
      const appts = (appointmentList || []).map(x => new _serviceAppointment.Appointment(x, jobs));
      return Ember.RSVP.Promise.resolve(this.setProperties({
        appointmentList: appts,
        jobsList: jobs,
        appointmentsInit: false,
        customerEmail
      }));
    },
    /**
     * Gets time options for rescheduling an existing appointment
     * @param {Object} data
     * @param {Number} data.jobNo
     * @param {Number} data.siteNo
     * @param {Date} data.startDate
     * @param {Date} data.endDate
    	 * @param {String} data.monthAbbr
    	 */
    getRescheduleTimeBlocks(data) {
      const {
        jobNo,
        siteNo,
        startDate,
        endDate,
        monthAbbr
      } = data;
      return this.get('apiService').myADT.getRescheduleTimeBlocks({
        jobNo,
        siteNo,
        startDate,
        endDate
      }).then(timeblocks => {
        this.set("rescheduleTimeblocks.".concat(monthAbbr), timeblocks.data);
      });
    },
    /**
     * Gets time options for scheduling a new appointment
     * if job was created in Chudly or MMB
     * @param {Object} data
     * @param {String} data.apptStartDate ISO date string
     * @param {String} data.apptEndDate ISO date string
     * @param {String} data.days Seven characters, each indicating a day (Sun-Sat) ex: 'NYYYYYN'
     * @param {String} data.customerSiteType
     * @param {Int} data.jobNo
     * @param {Int} data.siteNo
     * @param {String} data.csNo
     * @param {String} data.timeBand 'SMB' or 'RS' (small business or residential)
    */
    getScheduleTimeBlocks(data) {
      return this.get('apiService').myADT.getScheduleTimeBlocks(data).then(timeblocks => this.set('scheduleTimeblocks', timeblocks.data));
    },
    /**
     * Gets time options for scheduling a new appointment
     * if job was created in Chudly or MMB
     * @param {Object} params
     * @param {String} params.apptStartDate ISO date string
     * @param {String} params.apptEndDate ISO date string
     * @param {Int} params.siteNo
     * @param {String} params.csNo
    */
    createProjectJobAndGetApptOffers(params) {
      return this.get('apiService').myADT.createProjectJobAndGetApptOffers(params).then(projectJob => this.set('scheduleTimeblocks', projectJob.data));
    },
    /**
     * @param {Object} params
     * @param {Int|String} params.jobNo
     * @param {Boolean} params.requestThruChudly
     */
    cancelJobWithoutAppointment(params) {
      return this.get('apiService').myADT.cancelJobWithoutAppointment(params).then(results => results.data);
    },
    /**
     * @param {Object} data
     * @param {Int|String} data.jobNo
     * @param {String} data.apptDate YYYY-MM-DD
     * @param {String} data.optimizerPromStartTime ISO date string
     * @param {String} data.optimizerPromEndTime ISO date string
     * @param {String} data.optimizerPromBand
     * @param {Int} data.optimizerOfferSeqno
     * @param {String} data.email
     * @param {Boolean} data.requestThruChudly
     * @param {String} data.siteNo
     * @param {String} data.siteAddressLine1
     * @param {Boolean} data.escalatedWithoutAppt
     * @param {Boolean} data.escalatedWithAppt
     * @param {String} data.escalationComments
     * @param {Boolean} data.allowEarlyArrival
     *
     */
    saveScheduledAppointment(data) {
      return this.get('apiService').myADT.saveScheduledAppointment(data).then(() => this.getAppointmentList());
    },
    /**
     * @param {Object} data
     * @param {Int|String} data.jobNo
     * @param {String} data.apptDate YYYY-MM-DD
     * @param {String} data.optimizerPromStartTime ISO date string
     * @param {String} data.optimizerPromEndTime ISO date string
     * @param {String} data.optimizerPromBand
     * @param {String} data.email
     */
    saveRescheduledAppointment({
      selectedDate,
      selectedTime,
      appointmentObj
    }) {
      const {
        start,
        end,
        offerToken,
        promBand
      } = selectedTime;
      const {
        appointment: {
          jobNo,
          siteNo
        },
        customerEmail: email
      } = appointmentObj;
      const requestData = {
        jobNo,
        email,
        siteNo,
        apptDate: selectedDate.toIntlString('-'),
        optimizerPromStartTime: makeRescheduleTime(start),
        optimizerPromEndTime: makeRescheduleTime(end),
        optimizerOfferToken: offerToken,
        optimizerPromBand: promBand
      };
      const daysToAppointment = selectedDate.timeSpan().days;
      return this.get('apiService').myADT.saveRescheduledAppointment(requestData).then(() => new Ember.RSVP.Promise(resolve => setTimeout(resolve, 1000))).then(() => {
        const eventTagInfo = {
          eventTypeId: 'RSCHSAPT',
          subEventTypeId: 'RESCHD'
        };
        this.sendEventTag(eventTagInfo);
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Service Appointments', 'Reschedule appointment', "".concat(daysToAppointment, " days")]);
        return this.getAppointmentList();
      });
    },
    /**
     * @param {Object} data
     * @param {Int|String} data.jobNo
     * @param {String} data.apptDate YYYY-MM-DD
     * @param {String} data.optimizerPromStartTime ISO date string
     * @param {String} data.optimizerPromEndTime ISO date string
     * @param {String} data.email
     */
    cancelScheduledAppointment(data) {
      return this.get('apiService').myADT.cancelScheduledAppointment(data).then(() => {
        const eventTagInfo = {
          eventTypeId: 'CANSAPPT',
          subEventTypeId: 'CANCEL'
        };
        this.sendEventTag(eventTagInfo);
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Service Appointments', 'Cancel Service Appointment', 'Cancel Appointment']);
        return this.getAppointmentList();
      });
    },
    /**
     * Populates `appointmentsList` and `jobsList`
     *
     */
    getAppointmentList(jobNo) {
      return this.get('apiService').myADT.getServiceSchedule().then(response => this.processApptList(response)).catch(results => {
        if (results.errors) {
          let {
            errors
          } = results;
          _myadtHelpers.default.serviceUnavailableMessage(errors);
          if (!Array.isArray(errors)) errors = [errors];
          // eslint-disable-next-line no-template-curly-in-string
          const errorMessage = errors.map(error => (0, _localization.toExpandedString)(error).replace('${jobNo}', jobNo || '(unavailable)')).join(',');
          (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Error Messages', 'serviceAppointments', errorMessage]);
        }
        this.set('appointmentList', null);
        return results;
      });
    },
    sendEventTag() {
      /* eslint-disable no-console */
      console.warn('Method `sendEventTag` can be overloaded');
      /* eslint-enable no-console */
    },
    init() {
      this._super(...arguments);
      this.rescheduleTimeblocks = {};
    }
  });
  _exports.default = _default;
});