define("adt-wss-common/helpers/format-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDate = formatDate;
  _exports.default = void 0;
  /**
   * Formats date using hash for value, method, and arguments
   * @name formatDate
   * @memberof TemplateHelpers
   * @param {*} params void
   * @param {Object} hash
   * @param {Date} hash.date Instance of {@link external:Date Date}
   * @param {String} hash.method {@link external:Date Date} prototype function
   * @param {String} hash.arg1 first argument for method
   * @param {String} hash.arg2 second argument for method
   *
   * @return {String}
   * @example {{format-date date=item.date method='toAltString' arg1='full' arg2=1}}
   */
  function formatDate(params, hash) {
    const date = typeof hash.date === 'string' ? Date.createDate(hash.date) : hash.date;
    if (!hash.method || !date || typeof date == 'string' && /invalid/i.test(date)) {
      return date;
    }
    const out = date[hash.method](hash.arg1 || undefined, hash.arg2 || undefined);
    if (typeof out === 'string' && hash.arg1 !== 'full') {
      return out.replace(/jun/ig, 'June').replace(/jul/ig, 'July');
    }
    return out;
  }
  var _default = Ember.Helper.helper(formatDate);
  _exports.default = _default;
});