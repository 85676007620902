define("adt-wss-common/components/widget/status-messages", ["exports", "adt-wss-common/classes/status-message", "adt-wss-common/services/app-dispatcher", "adt-wss-common/templates/components/widget/status-messages"], function (_exports, _statusMessage, _appDispatcher, _statusMessages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Universal display for warning and API messages
   * @module Widget/StatusMessage
   * @tutorial Status-Messages
   * @prop {Classes/StatusMessage[]} messages
   * @prop {String} targetName
   * @example {{widget/status-messages messages=statusMessages targetName="dashboard/alarm-system/appointments"}}
   */
  var _default = Ember.Component.extend({
    layout: _statusMessages.default,
    classNames: ['status-messages'],
    router: Ember.inject.service(),
    _namespace: 'component:widget/status-messages',
    /* eslint-disable ember/no-observers */
    setClose: Ember.observer('messages', function () {
      let messages = this.get('messages');
      if (messages instanceof _statusMessage.default) {
        messages = [messages];
        this.set('messages', messages);
      }
      (messages || []).forEach(m => {
        if (typeof m.timeout === 'number') {
          setTimeout(() => {
            if (this.get('messages') && this.get('messages').removeObject) {
              this.get('messages').removeObject(m);
            }
          }, m.timeout);
        }
        if (typeof m.textToCopy === 'number') {
          this.set('textToCopy', m.textToCopy);
        }
        if (typeof m.targetName === 'string') {
          this.set('targetName', m.targetName);
        }
      });
    }),
    /* eslint-enable ember/no-observers */
    copyTextToClipboard(text) {
      // We can initiate text copying from textarea field only. Add and remove it immediately.
      const textArea = document.createElement('textarea');
      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;
      // Ensure it has a small width and height. Setting to 1px / 1em
      // doesn't work as this gives a negative w/h on some browsers.
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = 0;
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        // This will copy selected text to user's clipboard
        const successful = document.execCommand('copy');
        const msg = successful ? 'successfully' : 'unsuccessfully';
        this.setMessage({
          msg: new _statusMessage.default("Text '".concat(text, "' was copied ").concat(msg), 'success'),
          target: this.get('targetName')
        });
      } catch (err) {
        this.setMessage({
          msg: new _statusMessage.default('Unable to copy', 'error'),
          target: this.get('targetName')
        });
      }
      document.body.removeChild(textArea);
    },
    click() {
      const textToCopy = this.get('textToCopy');
      if (textToCopy) {
        this.copyTextToClipboard(textToCopy);
      }
    },
    actions: {
      close(m) {
        const onClose = this.get('onClose');
        if (m.static) {
          return false;
        }
        if (onClose) {
          onClose(m);
        }
        return this.get('messages').removeObject(m);
      }
    },
    setMessage({
      msg,
      target
    }) {
      const targetName = this.get('targetName') || this.get('router.currentRouteName');
      if (targetName === target) {
        this.set('messages', msg);
      }
    },
    init() {
      this._super(...arguments);
      this.get('appDispatcher').subscribe(_appDispatcher.SHOW_STATUS_MESSAGE_EVENT, this, 'setMessage');
    },
    willDestroyElement() {
      this._super(...arguments);
      this.get('appDispatcher').unsubscribe(_appDispatcher.SHOW_STATUS_MESSAGE_EVENT, this, 'setMessage');
    }
  });
  _exports.default = _default;
});