define("adt-wss-common/helpers/detect-pmoc-email-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.detectPmocEmailState = detectPmocEmailState;
  _exports.default = void 0;
  const states = {
    D: email => "Pending deletion: ".concat(email),
    A: email => "Pending addition: ".concat(email)
  };

  /**
   * Detect state of pending for PMOC emails
   * @name detectPmocEmailstate
   * @memberof TemplateHelpers
   * @param {Any[]}
   * @return {String}
   * @example
   * {{detect-pmoc-email-state null 'test@test.com'}} -> 'test@test.com'
   * {{detect-pmoc-email-state 'A' 'test@test.com'}} -> 'Pending addition: test@test.com'
   * {{detect-pmoc-email-state 'D' 'test@test.com'}} -> 'Pending deletion: test@test.com'
   */
  function detectPmocEmailState([state, email]) {
    const possibleStates = Object.keys(states);
    if (!possibleStates.includes(state)) {
      return email;
    }
    return states[state](email);
  }
  var _default = Ember.Helper.helper(detectPmocEmailState);
  _exports.default = _default;
});