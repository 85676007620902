define("adt-wss-common/classes/service-appointment", ["exports", "adt-wss-common/utils/date-formatter"], function (_exports, _dateFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Week = _exports.Offer = _exports.Appointment = void 0;
  /**
   * @class
   * @memberof ServiceAppointmentNS
   * @param {ApptObj|JobObj} apptObj
   * @param {Int[]|String[]} jobNumbers
   *
   * @extends ApptObj|JobObj
   * @prop {String} apptStart `apptObj.apptDateString`
   * @prop {Sring} apptEnd `apptObj.apptEndDateTime`
   * @prop {Date} apptStartDateObj `Date.createDate(apptObj.apptDateString)`
   * @prop {Date} apptEndDateObj `Date.createDate(apptObj.apptEndDateTime)`
   * @prop {Boolean} duplicateJobNo
   * @prop {String} apptDateStringFormatted {@link DateFormatter.apptDateString}
   */
  class Appointment {
    constructor(apptObj, jobNumbers) {
      const appt = this;
      Object.keys(apptObj).forEach(k => {
        appt[k] = apptObj[k];
      });
      appt.apptStart = apptObj.apptDateString || apptObj.commitmentStartDateTime;
      appt.apptEnd = apptObj.apptEndDateTime || apptObj.commitmentEndDateTime;
      appt.apptStartDateObj = Date.createDate(appt.apptStart);
      appt.apptEndDateObj = Date.createDate(appt.apptEnd);
      appt.appointmentExists = appt.apptDateString != null;
      appt.duplicateJobNo = false;
      // if the first occurrence of the job number is not the same position as the last occurrence, it is a duplicate
      if (jobNumbers && jobNumbers.filter(j => j.jobNo === appt.jobNo).length > 1) {
        appt.duplicateJobNo = true;
      }

      // check if unscheduled and eligible for scheduling
      if (appt.eligibleForSchedule) {
        appt.apptDateStringFormatted = 'Service appointment not scheduled';
      } else {
        appt.apptDate = appt.apptStartDateObj.toAltString('abbr');
        appt.apptDateStringFormatted = _dateFormatter.default.apptDateString(appt.apptStartDateObj, appt.apptEndDateObj);
      }
    }
  }

  /**
   * method to make a new offer object to populate and push into {@link myADT.ServiceAppointment.weeks}
   * @class
   * @memberof ServiceAppointmentNS
   * @param {Date} offerObj from API
   * @param {Boolean} isSchedule Not sure what this is for...
   */
  _exports.Appointment = Appointment;
  class Offer {
    constructor(offerObj, isSchedule) {
      const offer = this;
      const start = isSchedule ? offerObj.date : offerObj.start;
      const getEnd = function () {
        const spl = offerObj.endTime.split(':');
        if (spl[0] < 12 && /pm/i.test(offerObj.endTime)) {
          spl[0] = Number(spl[0]) + 12;
        } else {
          spl[0] = ('0' + spl[0]).slice(-2);
        }
        if (spl[0] === '12' && /am/i.test(offerObj.endTime)) {
          spl[0] = '00';
        }
        return "".concat(start.split('T')[0], "T").concat(spl.join(':').split(' ')[0], ":00");
      };
      const end = offerObj.end || getEnd();
      Object.keys(offerObj).forEach(k => {
        offer[k] = offerObj[k];
      });
      offer.startDateTime = start;
      offer.endDateTime = end;
      offer.displayTimeRange = _dateFormatter.default.apptTimeString(start, end);

      // create a class name to be utilized by various time slots (2hr slots, 4hr slots, etc.)
      // was `offer.hourSpan`
      offer.bucket = "scheduleDayTimeSlot".concat(Date.span(start, end).hours, "hourSpan");
      return offer;
    }
  }

  /**
   * method to make a new week object to populate and push into {@link myADT.ServiceAppointment.weeks}
   * @class
   * @memberof ServiceAppointmentNS
   * @param {Date} startDate First day of the week
   */
  _exports.Offer = Offer;
  class Week {
    constructor(startDate) {
      const weekObj = this;
      weekObj.valid = false;
      if (startDate instanceof Date) {
        weekObj.valid = true;
        ['mon', 'tue', 'wed', 'thu', 'fri', 'sat'].forEach(day => {
          weekObj[day] = {
            date: startDate.toIntlString(),
            offers: []
          };
          startDate = startDate.tomorrow();
        });
      }
    }
  }
  _exports.Week = Week;
  var _default = {
    Appointment,
    Offer,
    Week
  };
  _exports.default = _default;
});