define("adt-wss-common/components/widget/paginated-list", ["exports", "adt-wss-common/utils/ember-helpers", "adt-wss-common/templates/components/widget/paginated-list"], function (_exports, _emberHelpers, _paginatedList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const pageSizes = [10, 20, 'All'];
  const filterVisible = (items, pageSize, currentPage) => {
    if (typeof pageSize === 'string' && /all/i.test(pageSize)) {
      return items;
    }
    const end = pageSize * currentPage;
    const start = end - pageSize;
    return items.slice(start, end);
  };

  /**
   * @module Widget/PaginatedList
   * @prop {Object[]} items
   * @prop {Int} [pageSize] either lowest available from `pageSizes` or set initially from parent
   * @prop {Int[]} [pageSizes] def: [10, 20, 'all']
   * @prop {String[]} [keyList] list of keys to include as table columns
   * @prop {Object[]} [keys] generated from keyList
   * @prop {String} keys.{[key.raw]}
   * @prop {String} keys.{[key.dashed]} dasherized key for column class name
   * @prop {String} keys.{[key.capped]} capitalized key for column heading
   */
  var _default = Ember.Component.extend({
    layout: _paginatedList.default,
    classNames: ['paginated-list'],
    currentPage: 1,
    pageSize: pageSizes[0],
    pageSizes,
    keys: null,
    totalPages: (0, _emberHelpers.computedApplyFunction)((x, y) => Math.ceil(x.length / y), 'items', 'pageSize'),
    visibleItems: (0, _emberHelpers.computedApplyFunction)(filterVisible, 'items', 'pageSize', 'currentPage'),
    setKeys(keys) {
      const out = keys.map(k => {
        const dashed = k.dasherize();
        const spaced = dashed.replace('-', ' ');
        const capped = spaced.capitalize();
        return {
          dashed,
          capped,
          raw: k
        };
      });
      return this.set('keys', out);
    },
    didReceiveAttrs() {
      const {
        keyList,
        pageSizes: sizes,
        items
      } = this.getProperties('keyList', 'pageSizes', 'items');
      this.set('pageSize', sizes[0]);
      if (Array.isArray(keyList)) {
        this.setKeys(keyList);
      } else {
        this.setKeys(Object.keys(items[0]));
      }
    },
    changePage(change) {
      const {
        currentPage,
        totalPages
      } = this.getProperties('currentPage', 'totalPages');
      if (change > 0 && currentPage < totalPages) {
        this.incrementProperty('currentPage');
      } else if (change < 0 && currentPage > 1) {
        this.decrementProperty('currentPage');
      }
    },
    actions: {
      setPageSize(size) {
        this.set('pageSize', size);
      },
      nextPage() {
        this.changePage(1);
      },
      previousPage() {
        this.changePage(-1);
      }
    }
  });
  _exports.default = _default;
});