define("adt-wss-common/initializers/app-dispatcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  function initialize(application) {
    application.inject('route', 'appDispatcher', 'service:appDispatcher');
    application.inject('controller', 'appDispatcher', 'service:appDispatcher');
    application.inject('component', 'appDispatcher', 'service:appDispatcher');
    Ember.Service.reopen({
      appDispatcher: Ember.inject.service()
    });
  }
  var _default = {
    initialize,
    name: 'appDispatcher'
  };
  _exports.default = _default;
});