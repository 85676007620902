define("adt-wss-common/utils/http-resources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.combined = _exports.myADT = _exports.chudly = void 0;
  const devAuth = 1;
  const GET = 'GET';
  const POST = 'POST';

  /**
   * @typedef httpResource
   * @prop {String} url
   * @prop {String} type 'GET', 'POST', 'PUT', 'DELETE'
   * @prop {String} [contract] contract can be generated from url
   * @prop {String[]} [params] array of names of dynamic URL segments
   */

  /* eslint-disable max-len */
  /**
   * Resources for use with {@link Services/ApiService}
   * @namespace HttpResources
   * @prop {Object} chudly named {@link httpResource httpResources}
   * @prop {Object} myADT named {@link httpResource httpResources}
   * @prop {Object} combined named {@link httpResource httpResources}; mostly for impersonation and incident creation
   */
  /* eslint-enabled max-len */
  const chudly = {
    emailSelfServiceBattery: {
      url: 'csr/systemTestMgmt/emailSelfServiceBattery',
      contract: 'csr.systemTestMgmt.emailSelfServiceBattery.json',
      method: POST
    },
    addPrimaryEmail: {
      url: 'auth/pmoc/addPrimaryEmail',
      contract: 'auth.pmoc.addPrimaryEmail.json',
      method: POST
    },
    isSystemIdentified: {
      url: 'csr/systemTestMgmt/isSystemIdentified',
      contract: 'csr.systemTestMgmt.isSystemIdentified.json',
      method: POST
    },
    fraudPacketActionEligibility: {
      url: 'csr/action/fraudPacketActionEligibility',
      contract: 'csr.action.fraudPacketActionEligibility.json',
      method: POST
    },
    createAction: {
      url: 'csr/action/createAction',
      contract: 'csr.action.createAction.json',
      method: POST
    },
    customerStatementEmail: {
      url: 'csr/customerStatement/email',
      contract: 'csr.customerStatement.email.json',
      method: POST
    },
    createIncidentOrIssue: {
      url: 'auth/mmbIncident/createIncidentOrIssue',
      contract: 'auth.mmbIncident.createIncidentOrIssue.json',
      method: POST
    },
    getCsrMessages: {
      url: 'csr/getCsrMessages',
      contract: 'csr.getCsrMessages.json',
      method: GET
    },
    getJobHistory: {
      url: 'csr/jobs/getJobHistory',
      contract: 'csr.jobs.getJobHistory.json',
      method: POST
    },
    storeCurrentTime: {
      url: 'auth/mmbIncident/storeCurrentTime',
      contract: 'auth.mmbIncident.storeCurrentTime.json',
      method: POST
    },
    appointmentCancel: {
      url: 'auth/appointment/cancel',
      contract: 'auth.appointment.cancel.json',
      method: POST
    },
    cancelJobWithoutAppointment: {
      url: 'csr/jobs/cancelJobWithoutAppointment',
      contract: 'csr.jobs.cancelJobWithoutAppointment.json',
      method: POST
    },
    getJobBillableDetails: {
      url: 'auth/appointment/getJobBillableDetails/:jobNo',
      contract: 'auth.appointment.getJobBillableDetails.json',
      params: ['jobNo'],
      method: GET
    },
    getServicesAndZonesInfo: {
      url: 'csr/systemTestMgmt/getServicesAndZonesInfo',
      contract: 'csr.systemTestMgmt.getServicesAndZonesInfo.json',
      method: POST
    },
    getAdditionalJobInfo: {
      url: 'csr/jobs/getAdditionalJobInfo/:jobNo',
      contract: 'csr.jobs.getAdditionalJobInfo.json',
      params: ['jobNo'],
      method: GET
    },
    addJobComment: {
      url: 'csr/jobs/addJobComment',
      contract: 'csr.jobs.addJobComment.json',
      method: POST
    }
  };
  _exports.chudly = chudly;
  const myADT = {
    /* ===== Auth ===== */
    login: {
      url: 'login',
      method: POST
    },
    psLogin: {
      url: 'login/login_ps',
      method: POST
    },
    psLoginPic: {
      url: 'psLoginSession/verifyLocation',
      method: POST
    },
    getAuthInfo: {
      url: 'login/isAuthenticated',
      contract: devAuth ? 'login.isAuthenticated.json' : 'login.isAuthenticatedNo.json',
      // force 404 in contract mode if localStorage.devAuth is not set
      method: GET
    },
    getPSAuthToken: {
      url: 'auth/account/getPSAuthToken',
      method: GET
    },
    getIsSsoEnabled: {
      url: 'login/isSsoEnabled',
      method: GET
    },
    getSecurityQuestions: {
      url: 'securityquestions',
      method: GET
    },
    getCustomerInfo: {
      url: 'login/customerInfo',
      method: POST
    },
    invalidateSession: {
      url: 'login/invalidateSession',
      method: POST
    },
    logout: {
      url: 'login/logout',
      method: POST
    },
    passwordResetVerifyEmail: {
      url: 'login/isBroadview',
      method: POST
    },
    passwordResetGetSecurityQuestion: {
      url: 'password/securityQuestion',
      method: POST
    },
    passwordResetSendEmailLink: {
      url: 'password/emailResetLink',
      method: POST
    },
    passwordResetWithSecurityQuestion: {
      url: 'password/resetWithSecurityQuestion',
      method: POST
    },
    passwordResetExpiredPassword: {
      url: 'password/changeExpiredPassword',
      method: POST
    },
    passwordResetWithLink: {
      url: 'password/resetWithLink',
      method: POST
    },
    validateSecurityAnswer: {
      url: 'password/validateSecurityAnswer',
      method: POST
    },
    /* ===== Registration ===== */
    registrationCheckAffiliations: {
      url: 'registration/checkAffiliations',
      method: POST
    },
    registerUser: {
      url: 'registration/register',
      method: POST
    },
    registerAddUser: {
      url: 'registration/addUser',
      method: POST
    },
    addLocation: {
      url: 'registration/addLocation',
      method: POST
    },
    /* ===== System ===== */
    getServicesAndZonesInfo: {
      url: 'auth/alarmSystem/getZonesAndServicesInfo',
      method: POST
    },
    getJobBillableDetails: {
      url: 'auth/appointment/getJobBillableDetails/:jobNo',
      contract: 'auth.appointment.getJobBillableDetails.json',
      params: ['jobNo'],
      method: GET
    },
    /* ===== Account ===== */
    getAccountInfo: {
      url: 'auth/account/info',
      method: GET
    },
    getAccountActivity: {
      url: 'auth/myAccountActivityLog',
      method: GET
    },
    getAccountDocuments: {
      url: 'auth/cms/documents/getDocuments',
      method: POST
    },
    exportAccountActivity: {
      url: 'auth/myAccountActivityLog/export',
      method: POST
    },
    getCancellationInfo: {
      url: 'auth/account/getCancellationInfo',
      method: GET
    },
    getSystemPlanDetails: {
      url: 'auth/account/getSystemPlanDetails',
      contract: 'auth.account.getSystemPlanDetails.json',
      method: POST
    },
    /* ===== Promotions ===== */

    getPromotionInfo: {
      url: 'auth/promotion/userinfo',
      method: GET
    },
    getContractRenewalAlertContent: {
      url: 'auth/contract/getContractRenewalAlertContent',
      method: POST
    },
    getRenewalOffers: {
      url: 'auth/contract/getRenewalOffers/:promotionType',
      params: ['promotionType'],
      contract: 'auth.contract.getRenewalOffers.{promotionType}.json',
      method: GET
    },
    getSweepstakeContent: {
      url: 'auth/sweepstake/getSweepstakeContent',
      method: GET
    },
    getSweepstakeFormInfo: {
      url: 'auth/sweepstake/getSweepstakeFormInfo',
      method: GET
    },
    submitSweepstakeForm: {
      url: 'auth/sweepstake/submitSweepstakeEntry',
      method: POST
    },
    incrementDispositionCounter: {
      url: 'auth/promotion/incrementDispositionCounter/:promotionType',
      contract: 'auth.promotion.incrementDispositionCounter.json',
      params: ['promotionType'],
      method: POST
    },
    /* ===== Alarm ===== */
    getAlarmHistory: {
      url: 'auth/history',
      method: POST
    },
    exportAlarmHistory: {
      url: 'auth/history/report',
      method: POST
    },
    getActivityReports: {
      url: 'auth/history/report/email/all',
      method: GET
    },
    getInactivityAlerts: {
      url: 'auth/history/inactivity',
      method: GET
    },
    addInactivityAlert: {
      url: 'auth/history/inactivity',
      method: POST
    },
    saveActivityReport: {
      url: 'auth/history/report/email/add',
      method: POST
    },
    updateActivityReport: {
      url: 'auth/history/report/email/update',
      method: POST
    },
    removeActivityReport: {
      url: 'auth/history/report/email/delete/:reportId',
      method: POST,
      params: ['reportId'],
      contract: 'auth.history.report.email.delete.json'
    },
    getCellUpgradeEligibilityDetails: {
      url: 'auth/alarmSystem/cellUpgradeEligibilityDetails',
      method: GET
    },
    /* ===== Yard sings and Decals ====== */
    getOrderInfo: {
      url: 'order/order-info',
      method: GET
    },
    postYardsigns: {
      url: 'order/yardsigns/:amount',
      method: POST,
      params: ['amount']
    },
    postDecals: {
      url: 'order/decals/:amount',
      method: POST,
      params: ['amount']
    },
    enrollBusinessReporting: {
      url: 'order/dsEnroll',
      method: POST
    },
    getOrderHistory: {
      url: 'auth/order/orderHistory',
      method: GET
    },
    getNewProductPage: {
      url: 'content/getNewProductPage',
      method: GET
    },
    /* ===== Battery ===== */
    getBatteryList: {
      url: 'battery/getBatteriesList',
      method: GET
    },
    getBatteryOrderList: {
      url: 'auth/battery/getBatteriesList',
      method: GET
    },
    canOrderBattery: {
      url: 'auth/battery/canOrderBattery',
      method: GET
    },
    getBatteryOrderPricing: {
      url: 'auth/battery/batteryOrderPricing',
      method: POST
    },
    getBatteryOrderTaxes: {
      url: 'auth/battery/calculateTaxes',
      method: POST
    },
    postBatteryOrderPlacement: {
      url: 'auth/battery/batteryOrderPlacement',
      method: POST
    },
    /* ===== Adhesive Kits ===== */
    getAdhesiveKitsOrderTaxes: {
      url: 'auth/adhesiveKits/calculateADSPricing',
      method: POST
    },
    getAdhesiveKitsOrderPricing: {
      url: 'auth/adhesiveKits/validateADSEligibility',
      method: GET
    },
    postAdhesiveKitsOrderPlacement: {
      url: 'auth/adhesiveKits/submitAdhesiveDropShipOrder',
      method: POST
    },
    /* ===== Credit Enquiry Pypestream ===== */
    credCheckForm: {
      url: 'piiCustCreditCheck/credCheckForm',
      method: POST
    },
    /* ===== Billing ===== */
    getFlexFiFinancierDetails: {
      url: 'auth/bill/getFlexFiFinancierDetails',
      method: GET
    },
    getFlexFiInstallmentDetails: {
      url: 'auth/bill/getFlexFiInstallmentDetails',
      contract: 'auth.bill.getFlexFiInstallmentDetails.json',
      method: GET
    },
    getBillingInfo: {
      url: 'auth/dashboardWidget/billingWidget',
      method: GET
    },
    getBillingOnPreinstallData: {
      url: 'auth/bill/getBillingOnInstallDayForNewCustomer',
      contract: 'auth.bill.getBillingOnInstallDayForNewCustomer.json',
      method: POST
    },
    promotionEvent: {
      url: 'auth/bill/promotionEvent',
      contract: 'auth.bill.promotionEvent.json',
      method: POST
    },
    getBill: {
      url: 'auth/bill',
      method: GET
    },
    getBillingOptions: {
      url: 'auth/bill/options',
      method: GET
    },
    getBillingStatementURL: {
      url: 'auth/bill/statement',
      method: GET
    },
    getBillingStatements: {
      url: 'auth/bill/statements_v2',
      method: GET
    },
    emailBillingStatement: {
      url: 'auth/bill/statement_v2/email/:docId',
      contract: 'auth.bill.statement_v2.email.json',
      params: ['docId'],
      method: POST
    },
    checkBillingSystemDown: {
      url: 'auth/bill/systemStatus',
      method: GET
    },
    getSavedAccounts: {
      url: 'auth/savedAccounts/list',
      method: GET
    },
    getBillingAddress: {
      url: 'auth/bill/billingAddress/getCustomerBillingAddress',
      method: GET
    },
    getBillingHistory: {
      url: 'auth/bill/viewHistory',
      method: GET
    },
    payBill: {
      url: 'auth/bill/payment_v2',
      method: POST
    },
    checkHardDecline: {
      url: 'auth/paymentDeclines/easyPayHardDeclines',
      method: GET
    },
    updateBillingAddress: {
      url: 'auth/bill/billingAddress/updateCustomerBillingAddress',
      method: POST
    },
    updateSavedAccount: {
      url: 'auth/savedAccounts/update',
      method: POST
    },
    deleteSavedAccount: {
      url: 'auth/savedAccounts/delete',
      method: POST
    },
    initIvrPayment: {
      url: 'bill/ivrwfh/initiateIVRForPayment',
      method: POST
    },
    getIvrCardData: {
      url: 'bill/ivrwfh/creditCardProfile',
      method: GET
    },
    getPayConfDetails_v2: {
      url: 'bill/getPayConfDetails/',
      contract: 'bill.getPayConfDetails_v2.json',
      method: POST
    },
    /* ===== EasyPay ===== */
    validateEasypay: {
      url: 'easypay/validate',
      method: POST
    },
    enrollEasypay: {
      url: 'easypay/enroll_v2',
      method: POST
    },
    validateEasypayExpDate: {
      url: 'auth/manageEasypay/validateCCExpDate',
      method: POST
    },
    updateEasypayExpDate: {
      url: 'auth/manageEasypay/updateCCExpDate',
      method: POST
    },
    switchEasypayCard: {
      url: 'auth/manageEasypay/switchCC_v2',
      method: POST
    },
    validateSwitchEasypayBank: {
      url: 'auth/manageEasypay/validateACHDetails',
      method: POST
    },
    switchEasypayBank: {
      url: 'auth/manageEasypay/switchACH',
      method: POST
    },
    getEasyPayDetails: {
      url: 'auth/manageEasypay/details',
      method: GET,
      contract: 'auth.manageEasypay.details.json'
    },
    /* ===== ExpressPay ===== */
    expressPayInfo: {
      url: 'quickpay/info/:accountId/:zipCode',
      contract: 'quickpay.info.{accountID}.{zip}.json',
      method: GET,
      params: ['accountId', 'zipCode']
    },
    expressPayFlexFiInfo: {
      url: 'quickpay/getFlexFiInstallmentDetails',
      method: POST
    },
    psLoginAcc: {
      url: 'quickpay/info/:accountId/:zipCode?chatId:chatId',
      contract: 'ps.quickpay.info.{accountID}.{zip}.json',
      method: GET,
      params: ['accountId', 'zipCode', 'chatId']
    },
    expressPayPayment: {
      url: 'quickpay/payment_v2/:accountId/:zipCode',
      contract: 'quickpay.{accountID}.{zip}.json',
      method: POST,
      params: ['accountId', 'zipCode']
    },
    psExpressPayPayment: {
      url: 'quickpay/payment_v2_ps',
      contract: 'quickpay.{accountID}.{zip}.json',
      method: POST
    },
    oepActive: {
      url: 'quickpay/oep/paymentOEPEnabled',
      method: GET
    },
    oepAuthInfo: {
      url: 'quickpay/oep/verifyValidity',
      contract: 'quickpay.info.oep.json',
      method: POST
    },
    oepAuth: {
      url: 'quickpay/oep/payment_v2',
      contract: 'quickpay.info.oep.submitToken.json',
      method: POST
    },
    /* ===== Paymentech ===== */
    getHpfPaymentConfig: {
      url: 'bill/paymentConfig_v2/web/:flowId',
      contract: 'bill.paymentConfig_v2.json',
      method: GET,
      params: ['flowId']
    },
    getPSHpfPaymentConfig: {
      url: 'bill/paymentConfig_v2/PYPESTREAMWEB/:flowId',
      contract: 'bill.paymentConfig_v2.json',
      method: GET,
      params: ['flowId']
    },
    getPayConfDetails: {
      url: 'bill/getPayConfDetails/web/:flowId/:uID',
      contract: 'bill.getPayConfDetails.json',
      method: GET,
      params: ['flowId', 'uID']
    },
    getPSPayConfDetails: {
      url: 'bill/getPayConfDetails/PYPESTREAMWEB/:flowId/:uID',
      contract: 'auth.bill.payment.json',
      method: GET,
      params: ['flowId', 'uID']
    },
    /* ===== Common Passwords ===== */
    getCommonPasswordData: {
      url: 'auth/site/passwords',
      method: GET
    },
    removeCommonPassword: {
      url: 'auth/site/passwords/delete',
      method: POST
    },
    updateCommonPassword: {
      url: 'auth/site/passwords/update',
      method: POST
    },
    /* ===== Contract ===== */
    generateContractRenewalDocDetails: {
      url: 'auth/contract/generateContractRenewalDocDetails',
      method: POST
    },
    contractRenewalWithOffers: {
      url: 'auth/contract/contractRenewalWithOffers',
      method: POST
    },
    /* ===== Contacts (PMOC & Phone numbers) ===== */
    getContacts: {
      url: 'auth/contacts',
      method: GET
    },
    getSiteNumbers: {
      url: 'auth/site/numbers',
      method: GET
    },
    getPmocData: {
      url: 'auth/pmoc',
      method: GET
    },
    getMonitoringInfoConsent: {
      url: 'auth/pmoc/getMonitoringInfoConsent',
      method: GET
    },
    getTravelReminders: {
      url: 'auth/vacation/getAllVacations',
      method: GET
    },
    getCustomerPhoneConsents: {
      url: 'auth/pmoc/getCustomerPhoneConsents',
      method: GET
    },
    updatePhoneNumbers: {
      url: 'auth/pmoc/updatePhoneNumbers',
      method: POST
    },
    updateMonitoringInfoConsentData: {
      url: 'auth/pmoc/updateMonitoringInfoConsent',
      method: POST
    },
    updateBillingConsents: {
      url: 'auth/pmoc/updateBillingConsents',
      method: POST
    },
    updateServiceAppointmentConsents: {
      url: 'auth/pmoc/updateServiceAppointmentConsents',
      method: POST
    },
    updateProductsAndOffersConsents: {
      url: 'auth/pmoc/updateProductsAndOffersConsents',
      method: POST
    },
    savePmocData: {
      url: 'auth/pmoc',
      method: POST
    },
    addTravelReminder: {
      url: 'auth/vacation/saveVacation',
      method: POST
    },
    deleteTravelReminder: {
      url: 'auth/vacation/deleteVacation',
      method: POST
    },
    updateSitePhoneNumbers: {
      url: 'auth/site/numbers/update',
      method: POST
    },
    updateContact: {
      url: 'auth/contacts/update',
      method: POST
    },
    reorderContacts: {
      url: 'auth/contacts/reorder',
      method: POST
    },
    addContact: {
      url: 'auth/contacts/add',
      method: POST
    },
    removeContact: {
      url: 'auth/contacts/delete',
      method: POST
    },
    printContactData: {
      url: 'auth/contacts/print',
      method: GET
    },
    exportContactData: {
      url: 'auth/contacts/export',
      method: POST
    },
    /* ===== Current Location ===== */
    getAddresses: {
      url: 'auth/account/addressList',
      method: GET
    },
    modifyCurrentAddress: {
      url: 'auth/account/modifyCurrentAddress',
      method: POST
    },
    /* ===== Security ===== */
    submitPicChallenge: {
      url: 'auth/securityPasswords/submit',
      method: POST
    },
    /* ===== System Info ===== */
    getStandardSystemList: {
      url: 'alarmSystem/systemIdentification/getSystemIdentificationList',
      contract: 'alarmSystem.systemIdentification.getSystemIdentificationList.json',
      method: GET
    },
    getLowBatterySignals: {
      url: 'auth/battery/lowBatterySignalCheckForSite',
      method: GET
    },
    getSystemInfoList: {
      url: 'auth/alarmSystem/allCsListForCustSite',
      method: GET
    },
    getSystemTestData: {
      url: 'auth/alarmSystem/allSystemTestList',
      method: GET
    },
    exportSystemInfo: {
      url: 'auth/reports/systeminforeport',
      method: GET
    },
    setSystem: {
      url: 'auth/alarmSystem/systemIdentification/setSystem',
      method: POST
    },
    /* ===== System Test ============= */
    updateSystemTest: {
      url: 'auth/alarmSystem/siteSystemTest/modifyTest',
      method: POST
    },
    clearSystemTest: {
      url: 'auth/alarmSystem/clearTest',
      method: POST
    },
    putSystemOnTest: {
      url: 'auth/alarmSystem/putOnTest',
      method: POST
    },
    /* ===== Service Appointment ===== */
    getServiceSchedule: {
      url: 'auth/appointment/serviceJobsForSite',
      method: GET
    },
    getRescheduleTimeBlocks: {
      url: 'auth/appointment/offers_v2',
      method: POST
    },
    getScheduleTimeBlocks: {
      url: 'auth/appointment/searchOffers',
      method: POST
    },
    saveScheduledAppointment: {
      url: 'auth/appointment/scheduleAppointment',
      method: POST
    },
    saveRescheduledAppointment: {
      url: 'auth/appointment/reschedule',
      method: POST
    },
    cancelScheduledAppointment: {
      url: 'auth/appointment/cancel',
      method: POST
    },
    createProjectJobAndGetApptOffers: {
      url: 'auth/appointment/createProjectJobAndGetApptOffers',
      method: POST
    },
    cancelJobWithoutAppointment: {
      url: 'auth/appointment/cancelJobWithoutAppointment',
      method: POST
    },
    /* ===== User Profile ===== */
    updateUserName: {
      url: 'auth/userProfile/changeUserName',
      method: POST
    },
    updateName: {
      url: 'auth/userProfile/updateName',
      method: POST
    },
    updateEmail: {
      url: 'auth/userProfile/changeEmail',
      method: POST
    },
    updatePassword: {
      url: 'auth/userProfile/changePassword',
      method: POST
    },
    updateQuestionAnswer: {
      url: 'auth/userProfile/changeQuestionAnswer',
      method: POST
    },
    updatePremiumProfileInfo: {
      url: 'auth/webusers/profileData',
      method: POST
    },
    getUserProfileData: {
      url: 'auth/userProfile/data',
      method: GET
    },
    getWebUsersProfileData: {
      url: 'auth/webusers/profileData',
      method: GET
    },
    /* ===== Manage Users ===== */
    getAllWebUsers: {
      url: 'auth/webusers',
      method: GET
    },
    addWebUser: {
      url: 'auth/webusers',
      method: 'POST'
    },
    updateWebUser: {
      url: 'auth/webusers/:dataId?pending=:pending',
      contract: 'auth.webusers.{id}.json',
      method: POST,
      params: ['dataId', 'pending']
    },
    deleteWebUser: {
      url: 'auth/webusers/delete/:dataId?pending=:pending',
      contract: 'auth.webusers.delete.{id}.json',
      method: POST,
      params: ['dataId', 'pending']
    },
    /* ===== Manage Account ===== */
    getLocations: {
      url: 'auth/manageAccount/list',
      method: GET
    },
    getLocationGroups: {
      url: 'auth/manageAccount/locationsgroups',
      method: GET
    },
    deleteSite: {
      url: 'auth/manageAccount/delete/:webAddressId',
      contract: 'auth.manageAccount.delete.{webAddressId}.json',
      method: POST,
      params: ['webAddressId'],
      keepData: [] // webAddressId will be removed from the body of the request if it is not in this array
    },
    saveSite: {
      url: 'auth/manageAccount/site',
      method: POST
    },
    editLocationName: {
      url: 'auth/manageAccount/editLocation/:webAddressId/:locationName',
      contract: 'auth.manageAccount.editLocation.{webAddressId}.{locationName}.json',
      method: POST,
      params: ['webAddressId', 'locationName'],
      keepData: [] // by default each parameter will be removed from the body
    },
    getAllVacations: {
      url: 'auth/vacation/getAllVacations',
      method: GET
    },
    /* ===== Safety Tips ===== */
    getSafetyTips: {
      url: 'content/getSafetyTips',
      method: GET,
      contract: 'content.getSafetyTips.json'
    },
    /* ===== COI ===== */
    getCOICertificate: {
      url: '/api/v2/auth/coi/getCOICertificate',
      method: GET
    },
    populateCertificate: {
      url: 'auth/coi/populateCertificate/csno/:id',
      contract: 'auth.coi.populateCertificate.csno.{id}.json',
      method: GET,
      params: ['id']
    },
    getAllSystemsForSite: {
      url: 'auth/coi/allSystemsForSite',
      contract: 'auth.coi.allSystemsForSite.json',
      method: GET
    },
    /* ===== Event Tagging ===== */
    sendEventTag: {
      url: 'auth/customerEvent/tagCustomerEvent',
      method: POST
    },
    /* ===== Pypestream Session ===== */
    sessionIdForPSChatId: {
      url: 'login/sessionIdForPSChatId/:chatId',
      contract: 'ps.login.sessionIdForPSChatId.json',
      method: GET,
      params: ['chatId']
    }
  };
  _exports.myADT = myADT;
  const combined = {
    createIncidentOrIssue: {
      url: 'auth/mmbIncident/createIncidentOrIssue',
      method: POST
    },
    createMultipleIncidentOrIssue: {
      url: 'auth/mmbIncident/createMultipleIncidentOrIssue',
      method: POST
    },
    redirectToLandingPage: {
      url: 'login/landingPage',
      method: GET
    },
    /* ===== CSR Info ===== */
    getCurrentCSR: {
      url: 'csr/getCurrentCsr',
      method: GET
    },
    getCsrHeader: {
      url: 'csr/populateCsrHeader',
      method: GET
    },
    getPicList: {
      url: 'csr/picList',
      method: GET
    },
    hasDeclinedRegistration: {
      url: 'csr/hasDeclinedRegistration',
      method: GET
    },
    getAssociatedEmails: {
      url: 'csr/getAssociatedEmails',
      method: GET
    },
    removeWebUser: {
      url: 'csr/deleteUser',
      method: GET
    },
    endImpersonation: {
      url: 'csr/endImpersonation',
      method: POST
    },
    impersonateByEmail: {
      url: 'csr/impersonateByEmail',
      method: POST
    },
    declineRegistration: {
      url: 'csr/declineRegistration',
      method: POST
    },
    addAccountAccessedByAgent: {
      url: 'csr/addAccountAccessedByAgent',
      method: POST
    },
    registerAndEnroll: {
      url: 'csr/processRegistrationAndPreferences',
      contract: 'csr.processPendingPreRegistration.json',
      method: POST
    },
    heartbeat: {
      url: 'heartbeat',
      method: GET
    }
  };
  _exports.combined = combined;
  var _default = {
    chudly,
    myADT,
    combined
  };
  _exports.default = _default;
});