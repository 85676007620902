define("adt-wss-common/helpers/currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currency = currency;
  _exports.default = void 0;
  /**
   * Convert numbers or number-like strings into dollars
   * and cents.
   * NOTE: Does not round decimals, simply trims to two places
   * @name currency
   * @memberof TemplateHelpers
   * @param {String|Number[]} params number or string; expected to be a variable
   * @param {Object} hash
   * @param {Boolean} hash.intPart boolean indicator for integer amount
   * @return {String}
   * @example
   * {{currency '34.2'}} -> '$34.20'
   * {{currency '26'}} -> '$26.00'
   * {{currency '9.23634'}} -> '$9.23'
   * {{currency '150' inptPart=true}} -> '$150'
   */
  function currency(params, hash) {
    if (params[0] === '' || params[0] === null || params[0] === undefined || params[0] === false) return '';
    const neg = params[0] < 0 ? '-' : '';
    let num = params[0].toString().replace(/\$/g, '');
    num = Math.abs(num);
    if (Number.isNaN(num)) return params[0];
    let str = num.toString();
    str = str.indexOf('.') > -1 ? str.split('.') : [str, ''];
    str[1] += '00';
    str[1] = str[1].substr(0, 2);
    return hash.intPart ? "".concat(neg, "$").concat(str[0]) : "".concat(neg, "$").concat(str.join('.'));
  }
  var _default = Ember.Helper.helper(currency);
  _exports.default = _default;
});