define("adt-wss-common/services/mmb-incidents", ["exports", "adt-wss-common/utils/myadt-helpers"], function (_exports, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const clearCacheArgs = {
    incidentNo: null,
    incidentTimestamp: null,
    customerNo: 0,
    siteNo: 0
  };
  /**
   * Creates issues in MMB. The naming is ...
   * incidental
   * @module Services/MMBIncidents
   *
   * @prop {Int|String} customerNo
   * @prop {Int|String} siteNo
   * @prop {Timestamp} incidentTimestamp
   * @prop {Int} incidentNumber
   * @prop {String} credential computes from `customerNo`, `siteNo`
   * @prop {Object} successFns probably deprecated in favor of events
   */
  var _default = Ember.Service.extend(Ember.Evented, (0, _myadtHelpers.clearCache)(clearCacheArgs), {
    apiService: Ember.inject.service(),
    incidentNo: null,
    incidentTimestamp: null,
    customerNo: 0,
    siteNo: 0,
    credential: Ember.computed('customerNo', 'siteNo', function () {
      const customerNo = this.get('customerNo');
      const siteNo = this.get('siteNo');
      return "".concat(customerNo, "_").concat(siteNo);
    }),
    /**
     * Sets {@link MmbIncidentService.incidentTimestamp incidentTimestamp} to current time
     * and saves it to sessionStorage
     */
    setIncidentTimestamp() {
      const time = Date.now();
      const credential = this.get('credential');
      this.set('incidentTimestamp', time);
      sessionStorage.setItem("incidentNumber_".concat(credential), time);
    },
    /**
     * Gets {@link MmbIncidentService.incidentTimestamp incidentTimestamp} from sessionStorage
     */
    getIncidentTimestamp() {
      const credential = this.get('credential');
      const timestamp = sessionStorage.getItem("incidentNumber_".concat(credential));
      this.set('incidentTimestamp', parseInt(timestamp, 10) || null);
    },
    /**
     * Gets {@link MmbIncidentService.incidentNumber incidentNumber} from sessionStorage
     */
    getIncidentNumber() {
      const credential = this.get('credential');
      this.set('incidentNumber', parseInt(sessionStorage.getItem("incidentNumber_".concat(credential)), 10) || null);
    },
    /**
     * Sets the customer and site numbers, the start time, and clears
     * any existing incident number
     * TODO: invoke at login
     * @param {Int} custNo
     * @param {Int} siteNo
     */
    startIncident(custNo, siteNo) {
      this.setProperties({
        customerNo: custNo,
        siteNo,
        incidentNo: null
      });
      this.setIncidentTimestamp();
    },
    /**
     * Retreives active incident after new page load
     * TODO: invoke if logged in at page load
     * @param {Int} custNo
     * @param {Int} siteNo
     */
    openIncident(custNo, siteNo) {
      this.setProperties({
        customerNo: custNo,
        siteNo,
        incidentNo: null
      });
      this.getIncidentTimestamp();
    },
    /**
     * @instance
     * @type {ServiceCall}
     * @param {Object} options
     * @param {Boolean} impersonation
     * @return {ApiResponse}
     */
    createIncidentOrIssue(options, impersonation) {
      const issueData = this.prepIssueData(options);
      return this.get('apiService').combined.createIncidentOrIssue(issueData).then(({
        data
      }) => {
        this.set('incidentNo', data.incidentNo);
        sessionStorage.setItem("incidentNo_".concat(this.get('credential')), data.incidentNo);
        return data;
      }).catch(results => {
        const category = impersonation ? 'CSR IMPERSONATION' : 'CSR Dashboard';
        this.addIncidentReportingAnalyticEvent(category, 'Failed Incident Error Message');
        return results;
      }).finally(() => this.trigger('mmbIncidentComplete'));
    },
    /**
     * @instance
     * @param {String} category
     * @param {String} message
     */
    addIncidentReportingAnalyticEvent(category, message) {
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', category, 'Incident Reporting', message]);
    },
    /**
     * @instance
     * @type {ServiceCall}
     * @param {Object[]} list
     * @param {Boolean} impersonation
     * @return {ApiResponse}
     */
    createMultipleIncidentOrIssue(list, impersonation) {
      if (!list || !list.length) return Ember.RSVP.reject({
        success: false,
        errors: ['No data submitted.']
      });
      const issueData = list.map(options => this.prepIssueData(options));
      return this.get('apiService').combined.createMultipleIncidentOrIssue(issueData).then(({
        data
      }) => {
        (data || []).forEach(inc => {
          if (inc.success) {
            this.set('incidentNo', inc.incidentNo);
            sessionStorage.setItem("incidentNo_".concat(this.get('credential')), inc.incidentNo);
          }
        });
        return data;
      }).catch(results => {
        const category = impersonation ? 'CSR IMPERSONATION' : 'CSR Dashboard';
        this.addIncidentReportingAnalyticEvent(category, 'Failed Incident Error Message');
        return results;
      }).finally(() => this.trigger('mmbIncidentComplete'));
    },
    prepIssueData(options) {
      const custNo = parseInt(options.customerNo, 10) || this.get('customerNo');
      const siteNo = parseInt(options.siteNo, 10) || this.get('siteNo');
      this.set('customerNo', custNo);
      this.set('siteNo', siteNo);
      if (options.createIncident) this.setIncidentTimestamp();
      return {
        jobNumber: options.jobNumber || 0,
        jobComments: options.jobComments,
        transactionType: options.transactionType,
        customerNo: custNo,
        siteNo,
        createIncident: false,
        // options.createIncident || false
        issueDuration: options.duration || null,
        incidentDuration: Date.now() - this.get('incidentTimestamp'),
        billingSystem: options.billingSystem || null,
        afterCallTask: !!options.afterCallTask,
        customerFollowUp: !!options.customerFollowUp,
        phone: options.phone || null,
        email: options.email || null
      };
    },
    init() {
      this._super(...arguments);
      this.set('incidentTimestamp', Date.now());
    }
  });
  _exports.default = _default;
});