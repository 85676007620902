define("adt-wss-common/classes/status-message", ["exports", "adt-wss-common/utils/localization"], function (_exports, _localization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Class for status messages to be used with
   * {@link Widget/StatusMessage}
   * @class
   * @name Classes/StatusMessage
   * @param {String|Object} text will process message expansion
   * @param {String} [text.head] header for status message
   * @param {String} [text.text] text for status message when header is present
   * @param {Object} [type] default if String: info; success | error | warn
   * @param {Object} [type.type] default if String: info; success | error | warn
   * @param {Object} [type.static] default if String: info; success | error | warn
   * @param {String} [textToCopy] text to copy to clipboard on click
   * @param {String} targetName target compponent to show status message after copying
   * @param {Int|Bool} [timeout] sets timeout for message to close in ms; if truthy sets to 5000ms
   *
   * @prop {String} text
   * @prop {String} head
   * @prop {Boolean} static if `true`, message can't be closed
   * @prop {String} textToCopy
   * @prop {String} targetName
   */
  class StatusMessage {
    constructor(text, type, timeout, textToCopy, targetName) {
      if (!type) {
        this.type = 'info';
      } else {
        this.type = typeof type === 'string' ? type || 'info' : type.type || 'info';
      }
      this.text = typeof text === 'string' ? (0, _localization.toExpandedString)(text) : (0, _localization.toExpandedString)(text.text);
      this.head = text.head;
      this.static = type ? !!type.static : false;
      this.textToCopy = textToCopy;
      this.targetName = targetName;
      this.button = null;
      this.timeout = timeout && typeof timeout !== 'number' ? 5000 : timeout;
    }

    /**
     * Adds optional button
     *
     * @instance
     * @function
     * @param {Object} button
     * @param {String} button.route - application route name
     * @param {String} button.text - text of a button
     * @return {Object} instance of class
     */
    addButton(button) {
      this.button = button;
      return this;
    }
  }
  var _default = StatusMessage;
  _exports.default = _default;
});