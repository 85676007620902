define("adt-wss-common/helpers/plus-one", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.plusOne = plusOne;
  _exports.default = void 0;
  /**
   * Parse value to integer
   * @name parse-int
   * @memberof TemplateHelpers
   * @param {any[]} params [`string`, `number`]
   * @return {Number}
   * @example {{plus-one '10'}} -> 11
   */
  function plusOne(param = 0) {
    if (/[^0-9.]/.test(param)) return param;
    return parseInt(param, 10) + 1;
  }
  var _default = Ember.Helper.helper(plusOne);
  _exports.default = _default;
});