define("adt-wss-common/utils/date-formatter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module DateFormatter
   */
  const DateFormatter = {};

  /**
   * method to return a time block range (see example below)
   * formerly `dateStringFormat_HHampmToHHampm`
   * @param {String|Date} dateStr1
   * @param {String|Date} dateStr2
   * @return {String} 8am-12pm
   */
  DateFormatter.apptTimeString = function apptTimeString(dateStr1, dateStr2) {
    const dateObj1 = dateStr1 instanceof Date ? dateStr1 : Date.createDate(dateStr1);
    const dateObj2 = dateStr2 instanceof Date ? dateStr2 : Date.createDate(dateStr2);
    return "".concat(dateObj1.getFixedHour(true, true) + dateObj1.getAmPm(), "-").concat(dateObj2.getFixedHour(true, true) + dateObj2.getAmPm());
  };

  /**
   * method to return a date string in a specific (see example below)
   * formerly `dateStringFormat_Month_DD`
   * @param {Date|String|Timestamp} dateStr
   * @return {String} January 1
   */
  DateFormatter.monthDd = function monthDd(dateStr) {
    const dateObj = dateStr instanceof Date ? dateStr : Date.createDate(dateStr);
    return "".concat(dateObj.getMonthString('full'), " ").concat(dateObj.getDate());
  };

  /**
   * formerly `dateStringFormat_Day_Comma_MonthAbbr_Date`
   * @param {Date|String|Timestamp} dateStr
   * @return {String} Fri, Jan 9
   */
  DateFormatter.dayStringDate = function dayStringDate(dateStr) {
    const dateObj = dateStr instanceof Date ? dateStr : Date.createDate(dateStr);
    return "".concat(dateObj.getDayString('full'), ", ").concat(dateObj.getMonthString('full'), " ").concat(dateObj.getDate());
  };

  /**
   * method to return the date range display for each service
   * appointment in the page list (not modal/overlay)
   * formerly `formatDateString`
   * @param {Date|String|Timestamp} dateStr1
   * @param {Date|String|Timestamp} dateStr2
   * @return {String} Mon, Feb 2, 2015, 8am-12pm
   */
  DateFormatter.apptDateString = function apptDateString(dateStr1, dateStr2) {
    const dateObj1 = dateStr1 instanceof Date ? dateStr1 : Date.createDate(dateStr1);
    return "".concat(dateObj1.toAltDayString('full'), ", ").concat(DateFormatter.apptTimeString(dateStr1, dateStr2));
  };
  var _default = DateFormatter;
  _exports.default = _default;
});