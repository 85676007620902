define("adt-wss-common/utils/fp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isObject = isObject;
  _exports.default = _exports.filterAndMerge = _exports.mergeObjectsFromArraysByKeys = _exports.mergeObjectFromArrays = _exports.mergeObjectByKey = _exports.expandObject = _exports.itemEquals = _exports.mergeDeep = _exports.isPlainValue = _exports.plainEq = _exports.omap = _exports.eq = _exports.convertArrayToNumericProps = _exports.pipe = _exports.join = _exports.compact = _exports.omix = _exports.uniq = _exports.omit = _exports.identity = _exports.get = void 0;
  /**
   * Inspired by:
   * - [Lodash Functional Programming ](https://github.com/lodash/lodash/wiki/FP-Guide)
   * - [RamdaJS](https://ramdajs.com/)
   * @namespace EmberHelpers.FP
   */

  /**
   * Gets value from object by provided path
   * - [Lodash.get](https://lodash.com/docs/4.17.10#get)
   * - [Ramda.path](https://ramdajs.com/docs/#path)
   * @memberof EmberHelpers.FP
   * @example
   * fp.get('a.b.c.d')(myObj);
   * // Convinient for Promises
   * return callHttp.then(fp.get('billInfo'));
   * @param  {String} path - Path in-depht of myObj (a.b.c.d)
   * @param  {Object} object - Object (myObj)
   * @return {Function}      [description]
   */
  const get = path => object => Ember.get(object, path);
  _exports.get = get;
  const identity = y => y;

  /**
   * Removes property from object
   * - [_.omit](https://lodash.com/docs/4.17.10#omit)
   * - [R.omit](https://ramdajs.com/docs/#omit)
   * @memberof EmberHelpers.FP
   * @example
   * const el = {x: 1, y:2, z: 3 };
   * const someObj = omit(el , 'y', 'z'); //{x: 1}
   *
   * const el2 = {x: 1, y:2, z: 3 };
   * const someObj2 = omit(el , ['y', 'z']); //{x: 1}
   * @param  {Object} obj
   * @param  {String} criteria
   * @return {Object}
   */
  _exports.identity = identity;
  const omit = (obj = {}, criteria, ...rest) => {
    const clean = {};
    const criteriaList = Array.isArray(criteria) ? criteria : [criteria].concat(rest);
    Object.keys(obj).forEach(key => {
      if (criteriaList.indexOf(key) === -1) {
        clean[key] = obj[key];
      }
    });
    return clean;
  };

  /**
   * Returns a new list containing only one copy of each element in the original list
   * - [R.uniq](https://ramdajs.com/docs/#uniq)
   * @memberof EmberHelpers.FP
   * @example
   *  R.uniq([1, 1, 2, 1]); //=> [1, 2]
   * @return {Array}
   */
  _exports.omit = omit;
  const uniq = array => array.reduce((acc, el) => !acc.includes(el) ? acc.concat(el) : acc, []);

  /**
   * Object Mix (omix) - Copy and extend object without mutation
   * - [_.assign](https://lodash.com/docs/4.17.10#assign)
   * - [R.merge](https://ramdajs.com/docs/#merge)
   * @memberof EmberHelpers.FP
   * @example
   *  const x = {a: 1};
   *  const y = omix(x, {b: 2});
   *  console.log(x); // {a: 1}
   *  console.log(y); // {a: 1, b: 2}
   * @return {Object} new object
   */
  _exports.uniq = uniq;
  const omix = (...args) => Object.assign(...[{}, ...args]);

  /**
   * Creates an array with all falsey values removed
   * - [_.compact](https://lodash.com/docs/4.17.10#compact)
   * @memberof EmberHelpers.FP
   * @example
   *  compact([0, 1, false, 2, '', 3]) // [1,2,3]
   * @return {Array}
   */
  _exports.omix = omix;
  const compact = array => (array || []).filter(Boolean);

  /**
   * Returns a string made by inserting the separator between each element and concatenating all the elements into a single string.
   * - [R.join](https://ramdajs.com/docs/#join)
   * @memberof EmberHelpers.FP
   * @example
   *  pipe('|')([1,2,3]); // 1|2|3
   * @return {String}
   */
  _exports.compact = compact;
  const join = criteria => array => (array || []).join(criteria);

  /**
   * Performs left-to-right function composition
   * - [R.pipe](https://ramdajs.com/docs/#pipe)
   * @memberof EmberHelpers.FP
   * @example
   *  pipe(x => x+3, y => y*10)(1); // 40
   *  pipe(x => x*3, y => y+10)(2); // 16
   * @return {*}
   */
  _exports.join = join;
  const pipe = (headFunc, ...funcs) => (...args) => funcs.reduce((val, method) => method(val), headFunc(...args));

  /**
   * Convert array to object with numeric props
   * @memberof FPHelpers
   * @example
   *  const x = ['John', 'Doe'];
   *  const y = convertArrayToNumericProps({}, 'name', x);
   *  console.log(x); // { name1: 'John', name2: 'Doe' }
   * @return {Object} new object
   */
  _exports.pipe = pipe;
  const convertArrayToNumericProps = (state, name, data) => data.reduce((acc, current, index) => {
    acc[name + (index + 1)] = current;
    return acc;
  }, omix(state));
  /**
   * Carried function which compare property in 2 objects
   * @memberof FPHelpers
   * @example
   *  const someUser = { profile: { name: 'Alex' } };
   *  consy users: [{ profile: { name: 'Alex' }, isAdmin: true }, { profile: { name: 'Steve' }, isAdmin: false }];
   *  const sameUser = users.find(eq('profile.name')(someUser));
   *  console.log(users); // { profile: { name: 'Alex' }, isAdmin: true }
   * @return {Object} new object
   */
  _exports.convertArrayToNumericProps = convertArrayToNumericProps;
  const eq = pathOrPropName => obj1 => obj2 => get(pathOrPropName)(obj1) === get(pathOrPropName)(obj2);
  /**
   * Carried function which allow to transform object
   * @memberof FPHelpers
   * @arg {Function} callback - iterated function
   * @arg {Object} obj
   * @example
   * omap(function(value, key, srcObject) {
   * 	return 'any value which needs to set for current iterated key';
   * 	})({ someKey: 'Some value which will be changed by callback function'});
   * @example
   * Promise.resolve({ name: 'Alex', age: 14, someProp: 'someValue' })
   * 	.then(omap((value, key) => value + ' ha-ha'))
   * 	.then(console.log); // { name: 'Alex ha-ha', age: '14 ha-ha', someProp: 'someValue ha-ha' }
   * @example
   *	hashDeliverData(model = {}) {
   *		const transformed = omap(model, (value = [], key) => this.deliverData(...value));
   *		return hash(transformed);
   *	},
   * @return {Object} new object
   */
  _exports.eq = eq;
  const omap = (callback = () => ({})) => (obj = {}) => Object.keys(obj).reduce((iterObj = {}, key) => omix(iterObj, {
    [key]: callback(obj[key], key, obj)
  }), {});

  /**
   * Compares 2 plain values
   * @param {Number|String|Boolean|Null|Undefined} a
   * @param {Number|String|Boolean|Null|Undefined} b
   * @example
   *   plainEq(1)(1) // true
   */
  _exports.omap = omap;
  const plainEq = a => b => a === b;

  /**
   * Detect plain (not Object or Array) value
   * @param {*} val
   * @example
   *   isPlainValue({}) // false
   *   isPlainValue([]) // false
   *   isPlainValue(false) // true
   *   isPlainValue('') // true
   */
  _exports.plainEq = plainEq;
  const isPlainValue = val => val === null || ['string', 'number', 'boolean'].some(plainEq(typeof val));

  /**
   * Simple object check.
   * @param item
   * @returns {boolean}
   */
  _exports.isPlainValue = isPlainValue;
  function isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  /**
   * Recursive function which merge mixin object to source object in depth
   * @example
   * 	const a = { b: { c: 10, g: 5 }, l: 1 };
   *  const z = { b: { c: 12 }, x: 14 };
   *  const n = mergeDeep(a, z);
   *  n ===  { b: { c: 12, g: 5 }, x: 14, l: 1 };
   * @param {Object} src - source object
   * @param {Object} mix - mixin object
   */
  const mergeDeep = (target, ...sources) => {
    if (!sources.length) return target;
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
      Object.keys(source).forEach(key => {
        if (isObject(source[key])) {
          if (!target[key]) Object.assign(target, {
            [key]: {}
          });
          mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, {
            [key]: source[key]
          });
        }
      });
    }
    return mergeDeep(target, ...sources);
  };

  /**
   * @param  {Object} newItem - src object
   * @param  {String} key - key to compare objects
   * @return {Function} iterator
   * @example
   * const a = { id: 1 name: 'a'}
   * const b = { id: 1 name2: 'b'}
   * const result = itemEquals(a, 'id')(b); // true
   */
  _exports.mergeDeep = mergeDeep;
  const itemEquals = (newItem, key = '') => currItem => {
    const a = currItem[key];
    const b = newItem[key];
    return [a, b].every(item => item !== null) && a === b;
  };

  /**
   * Expands system
   * @param  {Object} source
   * @param  {Object} Dest
   * @return {Object}
   * @example
   * const alex = {id: 1, name: 'Alex', hairColor: null};
   * const jasmine = {id: 2, name: 'Jasmine', hairColor: 'red'}
   * const result = expandObject(alex, jasmine);
   * // result - {id: 1, name: 'Alex', hairColor: 'red'};
   */
  _exports.itemEquals = itemEquals;
  const expandObject = (source = {}, dest = {}) => Object.entries(dest).filter(([key]) => source[key] == null).reduce((acc, [key, value]) => omix(acc, {
    [key]: value
  }), source);

  /**
   * searching object in array and merging it with source object
   * @param  {String} key of object
   * @return {Function}
   * @example
   * const user = {id: 1, name: 'Alex', hairColor: 'red'};
   * const types = [{hairColor: 'red', age: 20}, {hairColor: 'black', age: 18}, {hairColor: 'white', age: 30}];
   * const nationality = [{id: 1, nationality: 'American'}, {id: 2, nationality: 'Ukrainian'}];
   * const result = mergeObjectByKey('id')(user, nationality);
   * // result - {id: 1, name: 'Alex', hairColor: 'red', nationality: 'American'};
   */
  _exports.expandObject = expandObject;
  const mergeObjectByKey = key => (obj, arrayToMerge) => expandObject(obj, arrayToMerge.find(itemEquals(obj, key)));

  /**
   * @param  {Object[]} arrays
   * @return {Function} - iterator
   * @example
   * const user = {id: 1, name: 'Alex', hairColor: 'red'};
   * const types = [{hairColor: 'red', age: 20}, {hairColor: 'black', age: 18}, {hairColor: 'white', age: 30}];
   * const nationality = [{id: 1, nationality: 'American'}, {id: 2, nationality: 'Ukrainian'}];
   * const result = mergeObjectFromArrays([types, nationality], ['id', 'hairColor'])(user , 'id');
   * // result - {id: 1, name: 'Alex', hairColor: 'red', nationality: 'American'};
   */
  _exports.mergeObjectByKey = mergeObjectByKey;
  const mergeObjectFromArrays = arrays => (obj, key) => arrays.filter(Boolean).reduce(mergeObjectByKey(key), obj);

  /**
   * @param  {Object[]} arrays
   * @param  {String[]} keys
   * @return {Function} - iterator
   * @example
   * const user = {id: 1, name: 'Alex', hairColor: 'red'};
   * const types = [{hairColor: 'red', age: 20}, {hairColor: 'black', age: 18}, {hairColor: 'white', age: 30}];
   * const nationality = [{id: 1, nationality: 'American'}, {id: 2, nationality: 'Ukrainian'}];
   * const result = mergeObjectsFromArraysByKeys([types, nationality], ['id', 'hairColor'])(user);
   * // result - {id: 1, name: 'Alex', hairColor: 'red', age: 20, nationality: 'American'};
   */
  _exports.mergeObjectFromArrays = mergeObjectFromArrays;
  const mergeObjectsFromArraysByKeys = (arrays, keys) => srcObj => keys.reduce(mergeObjectFromArrays(arrays), srcObj);

  /**
   * Merge source array with other arrays by list of keys
   * @param  {Object[]} srcArray - base array which should have merged objects from other arrays
   * @param  {Array} otherArrays=[] - array of arrays which need to merge with base array
   * @param  {String[]} keys - keys of object which will be as search criteria for array
   * @return {Object[]} - array of merged objects
   * @example
   * const users = [{id: 1, name: 'Alex', hairColor: 'red'}];
   * const types = [{hairColor: 'red', age: 20}, {hairColor: 'black', age: 18}, {hairColor: 'white', age: 30}];
   * const nationality = [{id: 1, nationality: 'American'}, {id: 2, nationality: 'Ukrainian'}];
   * const result = filterAndMerge(users, [types, nationality], ['id', 'hairColor']);
   * // result - [{id: 1, name: 'Alex', hairColor: 'red', age: 20, nationality: 'American'}];
   */
  _exports.mergeObjectsFromArraysByKeys = mergeObjectsFromArraysByKeys;
  const filterAndMerge = (srcArray, otherArrays = [], keys = []) => (srcArray || []).map(mergeObjectsFromArraysByKeys(otherArrays, keys));
  _exports.filterAndMerge = filterAndMerge;
  var _default = {
    get,
    omit,
    omix,
    mergeDeep,
    compact,
    pipe,
    join,
    eq,
    itemEquals,
    expandObject,
    mergeObjectByKey,
    mergeObjectFromArrays,
    mergeObjectsFromArraysByKeys,
    filterAndMerge
  };
  _exports.default = _default;
});