define('ember-user-activity/utils/get-scroll', ['exports', 'ember-user-activity/utils/window'], function (exports, _window) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getScroll;
  function getScroll(elem, direction) {
    if (elem === null || elem === undefined) {
      return;
    }

    if (elem.jquery) {
      // Convert to DOM element if jQuery was used
      elem = elem[0];
    }

    if (elem === null || elem === undefined) {
      return;
    }

    // `window`/`document` do not have scrollLeft attributes
    // Instead, we look at `pageXOffset`, which is an alias for `scrollX`,
    // But has better browser support (namely IE)
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/pageXOffset
    let windowObj = (0, _window.default)(elem);
    if (windowObj) {
      if (direction === 'left') {
        return windowObj.pageXOffset;
      }
      return windowObj.pageYOffset;
    }

    if (direction === 'left') {
      return elem.scrollLeft;
    }

    return elem.scrollTop;
  }
});