define("adt-wss-common/helpers/hide-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hideText = hideText;
  _exports.default = void 0;
  /**
   * Hide a text content by replacing characters to asterisks
   * @name hide-text
   * @memberof TemplateHelpers
   * @param {any[]} params [`string`, `boolean`]
   * @return {String}
   * @example {{hide-text 'Test'}} -> '****'
   */
  function hideText([content = '', disabled]) {
    const SYMBOL = '*';
    if (disabled) {
      return content;
    }
    return SYMBOL.repeat(content.length);
  }
  var _default = Ember.Helper.helper(hideText);
  _exports.default = _default;
});