define("adt-wss-common/utils/myadt-helpers", ["exports", "adt-wss-common/services/app-dispatcher", "adt-wss-common/utils/localization"], function (_exports, _appDispatcher, _localization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.stateMapping = _exports.validateCustomerNumber = _exports.validatePhoneNumber = _exports.addPhoneMask = _exports.clearCache = _exports.removePhoneMask = _exports.showModalErrors = _exports.genericErrorHandler = _exports.sendAnalyticEvent = _exports.consoleError = _exports.consoleWarn = _exports.consoleLog = _exports.windowRedirect = _exports.getOrigin = void 0;
  const {
    getOrigin
  } = window.myADT;

  /**
   * Abstracting `window.location.assign` to be testable
   * @param {String} url
   */
  _exports.getOrigin = getOrigin;
  const windowRedirect = url => window.location.assign(url);

  /**
   * Abstracting `console.log` to be testable
   * @param {String} url
   */
  _exports.windowRedirect = windowRedirect;
  const consoleLog = msg => console.log(msg);

  /**
   * Abstracting `console.warn` to be testable
   * @param {String} url
   */
  _exports.consoleLog = consoleLog;
  const consoleWarn = msg => console.warn(msg);

  /**
   * Abstracting `console.error` to be testable
   * @param {String} url
   */
  _exports.consoleWarn = consoleWarn;
  const consoleError = msg => console.error(msg);

  /**
   * Sends Google Analytics Event and logs to the console
   * @memberof MyADTHelpers
   * @param {String[]} analyticEvent
   * @return {Promise}
   */
  _exports.consoleError = consoleError;
  const sendAnalyticEvent = analyticEvent => {
    const _gaq = window._gaq || [];
    console.log("Pushing to Google Analytics: ".concat(analyticEvent));
    return _gaq.push(analyticEvent);
  };

  /**
   * Takes failed API response and extracts errors,
   * inserting a 'Generic.error' if no key/text is returned.
   * @memberof MyADTHelpers
   * @param {ApiResponse} results
   * @param {Boolean} results.[isHpf] indicates if errors came from Paymentech
   * @return {String[]}
   */
  _exports.sendAnalyticEvent = sendAnalyticEvent;
  const genericErrorHandler = results => {
    let errors = null;
    if (Array.isArray(results) || typeof results === 'string') {
      results = {
        errors: results
      };
    }
    if (!results.errors || Array.isArray(results.errors) && !results.errors.length) {
      errors = [(0, _localization.toExpandedString)('Generic.error')];
    } else if (Array.isArray(results.errors)) {
      errors = results.errors.map(error => {
        if (results.isHpf) error = "Paymentech.Errors.".concat(error);
        return (0, _localization.toExpandedString)(error);
      });
    } else if (typeof results.errors === 'string') {
      if (results.isHpf) results.errors = "Paymentech.Errors.".concat(results.errors);
      errors = [(0, _localization.toExpandedString)(results.errors)];
    } else {
      errors = [(0, _localization.toExpandedString)('Generic.error')];
    }
    return errors;
  };

  /**
   * Show errors in a modal
   * @memberof MyADTHelpers
   * @param {Classes/StateMachine} fsm
   * @param {Services/ModalWindowDispatcher} mwd
   */
  _exports.genericErrorHandler = genericErrorHandler;
  const showModalErrors = (fsm, mwd) => (results, action) => {
    const errors = genericErrorHandler(results);
    errors.forEach(error => mwd.showError(error, true));
    if (action) {
      return fsm.dispatch(action, results);
    }
    return errors;
  };
  _exports.showModalErrors = showModalErrors;
  const defaultPhoneFormat = '?999.999.9999';
  const altPhoneFormat = '?999-999-9999';

  /**
   * Removes dashes, dots, spaces, and parens from
   * any number string.
   * Returns empty string if there's any non-digit
   * characters remaining.
   * @memberof MyADTHelpers
   * @param {String} number
   * @return {String}
   * @example
   * '(917)555-0123' => 9155550123
   * '213.456' => 213456
   * '987-987a' => ''
   */
  const removePhoneMask = number => {
    let cleanNumber;
    if (number) {
      number = number.toString();
      cleanNumber = number.replace(/[()\s-_.]*/g, '');
      if (/^\d+$/.test(cleanNumber)) return cleanNumber;
    }
    return '';
  };

  /**
   * Set properties of services to it's default value
   * @param propertiesToClear - object where key is property name and value is the default value of it
   * @returns {void}
   */
  _exports.removePhoneMask = removePhoneMask;
  const clearCache = propertiesToClear => ({
    init() {
      this._super(...arguments);
      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'clearProperties');
    },
    willDestroy() {
      this._super(...arguments);
      this.get('appDispatcher').unsubscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, 'clearProperties');
    },
    clearProperties() {
      Object.keys(propertiesToClear).forEach(k => this.set(k, propertiesToClear[k]));
    }
  });

  /**
   * @desc Formats a phone number with the chosen separator
   * @memberof MyADTHelpers
   * @param {Int} number
   * @param {String} [sep] separator
   * @return {String}
   * @example
   * Helpers.addPhoneMask(9991234567, '.'); // '999.123.4567'
   * Helpers.addPhoneMask(99912345678901, '.'); // '999.123.4567 x8901'
   */
  _exports.clearCache = clearCache;
  const addPhoneMask = (number, sep) => {
    if (number) {
      let cleanNumber = removePhoneMask(number);
      if (cleanNumber) {
        cleanNumber = cleanNumber.split('');
        if (cleanNumber.length > 10) cleanNumber.splice(10, 0, ' x');
        if (sep) {
          cleanNumber.splice(6, 0, sep);
          cleanNumber.splice(3, 0, sep);
          return cleanNumber.join('');
        }
        cleanNumber.splice(6, 0, '-');
        cleanNumber.splice(3, 0, ') ');
        cleanNumber.unshift('(');
        return cleanNumber.join('');
      }
      return number;
    }
    return '';
  };

  /**
   * Test phone number for structural validity
   * does not validate against any API
   * Cannot start with 0 or 1, must be 10 digits, 7-10 digits cannot be same number
   * Cannot have have area codes 911, 800, 866, 877, 888, 900
   * Cannot have prefix (second three digits) 555
   * Cannot be sequential
   * @memberof MyADTHelpers
   * @param {String|Int} phone
   * @return {Boolean}
   */
  _exports.addPhoneMask = addPhoneMask;
  const validatePhoneNumber = p => {
    const phone = removePhoneMask(p);
    const sequence = '012345678901234567898765432109876543210';
    const areaCode = phone.substring(0, 3);
    const invalidAreaCodes = ['800', '866', '877', '888', '900', '911'];
    const prefix = phone.substring(3, 6);
    const lastSeven = phone.substring(3, 10);
    const matchPattern = /^(\d)(?!\1+$)\d{9}$/;
    const matchSmallPattern = /^(\d)(?!\1+$)\d{6}$/;
    if (!phone) return false;
    if (phone.length !== 10) return false;
    if (/^[01]/.test(phone)) return false;
    if (/^[01]/.test(lastSeven)) return false;
    if (invalidAreaCodes.includes(areaCode)) return false;
    if (prefix == '555') return false;
    if (sequence.indexOf(phone) !== -1) return false;
    if (sequence.indexOf(lastSeven) !== -1) return false;
    if (!matchPattern.test(phone)) return false;
    if (!matchSmallPattern.test(lastSeven)) return false;
    return true;
  };

  /**
   * Test customer number for structural validity
   * does not validate against any API
   * Must be not more then 9 digits
   * @memberof MyADTHelpers
   * @param {String|Int} customerNumber
   * @return {Boolean}
   */
  _exports.validatePhoneNumber = validatePhoneNumber;
  const validateCustomerNumber = customerNumber => {
    customerNumber = removePhoneMask(customerNumber);
    return !!customerNumber && customerNumber.length <= 9;
  };

  /**
   * @memberof MyADTHelpers
   */
  _exports.validateCustomerNumber = validateCustomerNumber;
  const stateMapping = [{
    code: 'AK',
    full: 'Alaska'
  }, {
    code: 'AL',
    full: 'Alabama'
  }, {
    code: 'AR',
    full: 'Arkansas'
  }, {
    code: 'AZ',
    full: 'Arizona'
  }, {
    code: 'CA',
    full: 'California'
  }, {
    code: 'CO',
    full: 'Colorado'
  }, {
    code: 'CT',
    full: 'Connecticut'
  }, {
    code: 'DE',
    full: 'Delaware'
  }, {
    code: 'FL',
    full: 'Florida'
  }, {
    code: 'GA',
    full: 'Georgia'
  }, {
    code: 'HI',
    full: 'Hawaii'
  }, {
    code: 'IA',
    full: 'Iowa'
  }, {
    code: 'ID',
    full: 'Idaho'
  }, {
    code: 'IL',
    full: 'Illinois'
  }, {
    code: 'IN',
    full: 'Indiana'
  }, {
    code: 'KS',
    full: 'Kansas'
  }, {
    code: 'KY',
    full: 'Kentucky'
  }, {
    code: 'LA',
    full: 'Louisiana'
  }, {
    code: 'MA',
    full: 'Massachussetts'
  }, {
    code: 'MD',
    full: 'Maryland'
  }, {
    code: 'ME',
    full: 'Maine'
  }, {
    code: 'MI',
    full: 'Michigan'
  }, {
    code: 'MN',
    full: 'Minnesota'
  }, {
    code: 'MO',
    full: 'Missouri'
  }, {
    code: 'MS',
    full: 'Mississippi'
  }, {
    code: 'MT',
    full: 'Montana'
  }, {
    code: 'NC',
    full: 'North Carolina'
  }, {
    code: 'ND',
    full: 'North Dakota'
  }, {
    code: 'NE',
    full: 'Nebraska'
  }, {
    code: 'NH',
    full: 'New Hampshire'
  }, {
    code: 'NJ',
    full: 'New Jersey'
  }, {
    code: 'NM',
    full: 'New Mexico'
  }, {
    code: 'NV',
    full: 'Nevada'
  }, {
    code: 'NY',
    full: 'New York'
  }, {
    code: 'OH',
    full: 'Ohio'
  }, {
    code: 'OK',
    full: 'Oklahoma'
  }, {
    code: 'OR',
    full: 'Oregon'
  }, {
    code: 'PA',
    full: 'Pennsylvania'
  }, {
    code: 'RI',
    full: 'Rhode Island'
  }, {
    code: 'SC',
    full: 'South Carolina'
  }, {
    code: 'SD',
    full: 'South Dakota'
  }, {
    code: 'TN',
    full: 'Tennessee'
  }, {
    code: 'TX',
    full: 'Texas'
  }, {
    code: 'UT',
    full: 'Utah'
  }, {
    code: 'VA',
    full: 'Virginia'
  }, {
    code: 'VT',
    full: 'Vermont'
  }, {
    code: 'WA',
    full: 'Washington'
  }, {
    code: 'WI',
    full: 'Wisconsin'
  }, {
    code: 'WV',
    full: 'West Virginia'
  }, {
    code: 'WY',
    full: 'Wyoming'
  }, {
    code: 'DC',
    full: 'Washington, DC'
  }, {
    code: 'PR',
    full: 'Puerto Rico'
  }];

  /**
   * MyADT Helper Util
   * @namespace MyADTHelpers
   * @prop {String} defaultPhoneFormat defaults to '?999.999.9999'
   * @prop {String} altPhoneFormat defaults to '?999-999-9999'
   * @prop {Computed} shippingStatesList `stateMapping` without DC and PR
   */
  _exports.stateMapping = stateMapping;
  const Helpers = Ember.Object.extend({
    defaultPhoneFormat,
    altPhoneFormat,
    stateMapping,
    shippingStatesList: Ember.computed('stateMapping', function () {
      const states = this.get('stateMapping');
      return states.slice(0, 50);
    }),
    windowRedirect,
    consoleError,
    consoleLog,
    consoleWarn,
    getOrigin,
    genericErrorHandler,
    sendAnalyticEvent,
    validatePhoneNumber,
    validateCustomerNumber,
    addPhoneMask,
    removePhoneMask,
    /**
     * Replace spaces with dashes
     * @memberof MyADTHelpers
     * @param {String} title
     * @example 'This Is the Title' => 'this-is-the-title'
     */
    hashFriendlyTitle(title) {
      return title.toLowerCase().replace(/\?/g, '').replace(/[\s+.()'";,/]/g, '-');
    },
    /**
     * @desc Pass through to {@link myadtHelpers.addPhoneMask}
     * @memberof MyADTHelpers
     * @param {Int} number
     * @param {String} [sep] separator
     * @return {String}
     */
    formatPhone(number, sep) {
      return this.addPhoneMask(number, sep);
    },
    /**
     * @memberof MyADTHelpers
     * @param {Date|String} date could also be standard date string
     * @return {String} '12:00 pm'
     */
    getTimeWithAmPm(date) {
      const dateObj = date instanceof Date ? date : Date.createDate(date);
      return dateObj.getFixedHour(true, true) + ':' + dateObj.getFixedMinute() + dateObj.getAmPm();
    },
    /**
     * Reformats weird date format string
     * @memberof MyADTHelpers
     * @param {String} dateStr format: 'JAN-28-2018'
     */
    formatDate(dateStr) {
      let dtStr = '';
      if (dateStr) {
        if (typeof dateStr === 'string' && /[A-Za-z]{3}-[0-9]{2}-[0-9]{4}/.test(dateStr)) {
          const dtArr = dateStr.split('-');
          dtStr = "".concat(dtArr[0].charAt(0).toUpperCase()).concat(dtArr[0].slice(1).toLowerCase(), " ").concat(parseInt(dtArr[1], 10), ", ").concat(dtArr[2]);
        } else {
          const dt = Date.createDate(dateStr);
          dtStr = dt.toAltString();
        }
      }
      return dtStr;
    },
    /**
     * Gets date of day relative to `date` param
     * @memberof MyADTHelpers
     * @param {String} dayName 3-letter abbreviation for day
     * @param {Int} weekInterval number of weeks to adjust; negative numbers allowed
     * @param {String} [time] time of day (HH:MM:SS AM), default is midnight
     * @param {Date|String} [date] will convert to date if string, defaults to today
     * @example
     * getRelativeDate('Mon', 2, undefined, '06/14/2018') => new Date('2018-06-25 12:00:00')
     */
    getRelativeDate(dayName, weekInterval, time, date) {
      weekInterval = weekInterval || 0;
      const anchorTime = time || '12:00:00 AM';
      const anchorDate = date ? date instanceof Date ? date : Date.createDate(date) : new Date();
      let searchDay = 0;
      const dayNumber = anchorDate.getDay();

      // eslint-disable-next-line default-case
      switch (dayName.toLowerCase()) {
        case 'sun':
          searchDay = 0;
          break;
        case 'mon':
          searchDay = 1;
          break;
        case 'tue':
          searchDay = 2;
          break;
        case 'wed':
          searchDay = 3;
          break;
        case 'thu':
          searchDay = 4;
          break;
        case 'fri':
          searchDay = 5;
          break;
        case 'sat':
          searchDay = 6;
          break;
      }
      let offset = searchDay - dayNumber;
      offset = weekInterval * 7 + offset;
      return new Date("".concat(anchorDate.dateAfterNDays(offset).toUSAString('/'), " ").concat(anchorTime));
    },
    /**
     * Tenure Object
     * @memberof MyADTHelpers
     * @param {Date|String} date - start date of the tenure, any valid Date object, date string, MM/DD/YYYY, or MM-DD-YYYY
     * @param {Boolean} fullText - boolean to indicate using full month names instead of abbreviations
     * @param {Date|String} [endDate] - if included, tenure will be calculated from @date to @endDate, else it will be from @date to now
     */
    tenure(date, fullText, endDate) {
      const start = date instanceof Date ? date : Date.createDate(date);
      const end = endDate ? endDate instanceof Date ? endDate : Date.createDate(endDate) : new Date();
      const yrText = fullText ? 'year' : 'yr';
      const moText = fullText ? 'month' : 'mo';
      const nMo = end.getMonth();
      const sMo = start.getMonth();
      const nYr = end.getYear();
      const sYr = start.getYear();
      const nDt = end.getDate();
      const sDt = start.getDate();
      let str = '';
      let months = nMo - sMo;
      let years = nYr - sYr;
      const days = Math.floor((end.getTime() - start.getTime()) / 86400000);
      if (months < 0) {
        years--;
        months += 12;
      }
      if (sDt > nDt) {
        if (--months < 0) {
          years--;
          months += 12;
        }
      }
      if (years === 0 && months === 0) {
        str = 'Less than 1 month';
      } else {
        if (years === 1) str += "1 ".concat(yrText);else if (years > 1) str += "".concat(years, " ").concat(yrText, "s");
        if (years > 0 && months > 0) str += ', ';
        if (months === 1) str += "1 ".concat(moText);else if (months > 1) {
          if (fullText) str += "".concat(months, " ").concat(moText, "s");else str += "".concat(months, " ").concat(moText);
        }
      }
      return {
        tenureStr: str,
        years,
        weeks: Math.floor(days / 7),
        months: years * 12 + months,
        days
      };
    },
    /**
     * @deprecated
     * @memberof MyADTHelpers
     * @param {Int} num
     * @param {Boolean} cap
     */
    digitToWord(num, cap) {
      const a = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
      const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
      const c = ['', 'million', 'thousand'];
      let words = '';
      let str;
      num = num.toString();

      // Number is larger than what function can deal with
      if (num.length > 9) return 'one billion or more';

      // Make number into a predictiable nine character string
      num = "000000000".concat(num).substr(-9);

      // Split string into chuncks of three numbers then reverse order of returned array
      num = num.match(/.{3}/g);
      num.forEach((n, i) => {
        str = '';
        if (words !== '') str += " ".concat(c[i], ", ");
        if (parseInt(n[0], 10) !== 0) str += "".concat(a[parseInt(n[0], 10)], " hundred ");
        const x = n.substr(1);
        if (parseInt(x, 10) !== 0) {
          if (a[x]) str += a[x];else str += "".concat(b[x[0]], " ").concat(a[x[1]]);
        }
        words += str;
      });
      return cap ? words.trim().toTitleCase() : words.trim();
    },
    /**
     * Rounds a number to the passed number of decimal places
     * Negative numbers result in rounding to lower precision
     * (tens, hundreds, etc...)
     * @memberof MyADTHelpers
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
     * @param {Number} number
     * @param {Int} precision
     * @return {Number}
     * @example
     * precisionRound(5.2622, 1) => 5.3
     * precisionRound(5.2622, 2) => 5.26
     */
    precisionRound(number, precision) {
      const factor = 10 ** precision;
      return Math.round(number * factor) / factor;
    },
    /**
     * @deprecated
     * @memberof MyADTHelpers
     * probably can use JSON.stringify
     * @param {Object} obj to be stringified to a query string
     */
    toQueryString(obj, _sep) {
      const sep = _sep || '&';
      const str = Object.keys(obj).map(key => "".concat(key, "=").concat(encodeURIComponent(obj[key])).concat(sep));
      return str.join('').slice(0, -1);
    },
    /**
     * Gets particular item from query string object
     * @memberof MyADTHelpers
     * @param {String} param name of query param
     * @param {*} [q] query string
     */
    getQueryString(param, q) {
      return this.getQueryStringObject(q)[param];
    },
    /**
     * @deprecated
     * @memberof MyADTHelpers
     * Can use JSON.parse
     * @param {String} [q] query string
     */
    getQueryStringObject(q) {
      const params = {};
      const s = (q || window.location.search).replace('/^?/', '');
      const queries = s.substring(1).split('&');
      queries.forEach(query => {
        const data = query.split('=');
        if (data[1]) {
          params[data[0]] = decodeURIComponent(data[1].replace(/\+/g, '%20'));
        }
      });
      return params;
    },
    /**
     * @memberof MyADTHelpers
     * create cookie string
     * @param {String} obj to be stringified to a cookie string
     */
    toCookie(obj) {
      const str = Object.keys(obj).map(key => "".concat(key, "=").concat(encodeURIComponent(obj[key]), "; "));
      return str.join('').slice(0, -1);
    },
    /**
     * @memberof MyADTHelpers
     * parse a cookie string
     * @param {String} [c] cookie string
     */
    parseCookie(c) {
      const params = {};
      const s = c;
      const queries = s.substring(1).split('; ');
      queries.forEach(cookie => {
        const data = cookie.split('=');
        if (data[1]) {
          params[data[0]] = data[1];
        }
      });
      return params;
    },
    /**
     * Returns a message from localization.json for unavailable services. parameter can be a string or an array.
     * Assumes that the error code exists in localization.json
     * @memberof MyADTHelpers
     * @param {String|String[]}
     */
    serviceUnavailableMessage(errors) {
      if (typeof errors === 'string') errors = [errors];
      if (Array.isArray(errors) && errors.length) {
        const errorArray = errors.filter(error => typeof error === 'string' && /.service.notAvailable/.test(error));
        errorArray.forEach(error => {
          if (error.indexOf('SystemTestMgmt') === 0) {
            sendAnalyticEvent(['_trackEvent', 'Service Not Available', 'Message shown: System Test/Info down']);
          }
          if (error.indexOf('AppointmentStatus') === 0) {
            sendAnalyticEvent(['_trackEvent', 'Service Not Available', 'Message shown: Rescheduling service down']);
          }
        });
        if (errorArray.length) {
          return errorArray.map(e => (0, _localization.toExpandedString)(e));
        }
      }
      return false;
    },
    /**
     * Create random unique identifier
     * @memberof MyADTHelpers
     */
    createGUID() {
      const s4 = function () {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      };
      return "".concat(s4()).concat(s4(), "-").concat(s4(), "-").concat(s4(), "-").concat(s4(), "-").concat(s4()).concat(s4()).concat(s4());
    },
    /**
     * Replace parameters in localized string
     * @memberof MyADTHelpers
     * @param {Array} arr array with parameters
     * @param {String} s localized string
     */
    localizeStringWithParams(arr, s) {
      if (!Array.isArray(arr)) {
        console.warn('Provided parameter "arr" is not an array');
        return s;
      }
      return arr.reduce((acc, attr) => acc.replace("${".concat(attr.fieldName, "}"), attr.fieldValue), (0, _localization.toExpandedString)(s || ''));
    }
  });
  var _default = Helpers.create();
  _exports.default = _default;
});