define("adt-wss-common/helpers/doc-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.docType = docType;
  _exports.default = void 0;
  const TYPES = {
    CONTRACT: 'contract',
    CONTRACTAMENDMENT: 'contract amendment',
    INSPECTIONREPORT: 'inspection report'
  };

  /**
   * Formats type of document according to constant
   * @memberof TemplateHelpers
   * @param {String} type
   *
   * @return {String}
   * @example
   * {{doc-type 'CONTRACT'}} - 'contract'
   */
  function docType(type) {
    if (!TYPES.hasOwnProperty(type)) return type;
    return TYPES[type];
  }
  var _default = Ember.Helper.helper(docType);
  _exports.default = _default;
});